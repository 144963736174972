import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  PhaseApproverType,
  PipelinePhaseType,
  PipelineType,
} from "../types/PipelinesResponseType";
import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import FilemanagerIcon from "../../../../_metronic/assets/icons/filemanager.svg";
import clsx from "clsx";
import {
  SortPhasesTabs,
  SortPipelinePhases,
  addPhaseRoles,
  addPhaseTabs,
  deletePhaseTabs,
  deletePipelinePhase,
  getPipelineByID,
  importTabsFromPipeline,
  needApproval,
  updateApprovers,
  updateDisplayInMobile,
  updatePhase,
  updatePipelineStatus,
  updatePipelines,
} from "../redux/PipelinesAPI";
import AddPipelinePhaseModal from "./AddPipelinePhaseModal";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/toasts/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { TabType } from "../../projectMasterData/types/TabsResponseType";
import { TabSideBar } from "../../projectMasterData/components/EditTabs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TabDropzone from "../../projectMasterData/components/TabDropzone";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import { sortGroupsParams } from "../types/request";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { UserType } from "../../userManagement/types/getUsersListResponseType";
import "../PipelinePage.scss";
import {
  TabsModal,
  TabsModalProps,
} from "../../projectMasterData/components/ProjectTabs";
import { getTabsList } from "../../projectMasterData/redux/TabsAPI";
import { useDispatch } from "react-redux";
import { actions } from "../../projectMasterData/redux/ContentMasterRedux";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import withScrolling from "react-dnd-scrolling";
import SelectedUsersModal from "../../teams/components/SelectUsersModal";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";

const EditPipeline = () => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const PIPELINE_TRANSITION_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_TRANSITION_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];
  const PIPELINE_STATUS_TYPE_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "PIPELINE_STATUS_TYPE_OPTIONS" })
  ) as {
    id: number;
    name: string;
    title: string;
  }[];
  const rolesList = useSelector<RootState>(
    //   @ts-ignore
    ({ rolesAndPermissionsManagement }) => rolesAndPermissionsManagement?.roles
  ) as RoleType[];
  const tabsList = useSelector<RootState>(
    // @ts-ignore
    ({ contentMaster }) => contentMaster.tabsList
  ) as TabType[];
  const singlePipeline = useSelector<RootState>(
    // @ts-ignore
    ({ pipelines }) => pipelines.pipelineDetails
  ) as PipelineType;

  // @ts-ignore
  const pipeline: PipelineType = state.pipelineDetails || singlePipeline || {};
  const userData = useSelector<RootState>(({ auth }) => auth.user) as UserType;
  const dispatch = useDispatch();

  //   state
  const [activePhase, setActivePhase] = useState<PipelinePhaseType>(
    pipeline.phases?.[0]
  );
  const [currPipeline, setCurrPipeline] = useState<PipelineType>(pipeline);
  const [showPhaseModal, setShowPhaseModal] = useState(false);
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();
  const [selectedRole, setSelectedRole] = useState<RoleType[]>(
    activePhase?.roles || []
  );
  const [phaseHeading, setPhaseHeading] = useState(activePhase?.name || "");
  const [pipelineHeading, setPipelineHeading] = useState(pipeline.name || "");
  const [phaseIsRequired, setPhaseIsRequired] = useState(
    activePhase?.is_required
  );
  const [phaseIsEndPhase, setPhaseIsEndPhase] = useState(
    activePhase?.condition === "end" && activePhase?.display_order !== null
      ? 1
      : 0
  );
  const [loading, setLoading] = useState(false);
  const [phaseTabActiveIndex, setPhaseTabActiveIndex] = useState(0);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [fieldData, setFieldData] = useState<TabType[]>(tabsList);
  const [pipelineTransition, setPipelineTransition] = useState(
    pipeline?.transition_type || ""
  );
  const [pipelineStatus, setPipelineStatus] = useState(
    pipeline?.status || ""
  );
  const [addTabModal, setAddTabModal] = useState<TabsModalProps>({
    show: false,
    type: "new",
  });
  const [openUserModal, setOpenUserModal] = useState<{
    show: boolean;
    type: string;
    approvalValue: number;
  }>({
    show: false,
    type: "",
    approvalValue: 0,
  });

  const ScrollingComponent = withScrolling("div");
  const getOtherPhases = (pipeline: any, activePhase: any) => {
    return pipeline?.phases?.filter(
      (phase: any) => (phase?.id !== activePhase?.id) && (phase?.tabs?.length > 0)
    );
  };
  const otherPhases = getOtherPhases(pipeline, activePhase);

  useEffect(() => {
    if (pipeline && pipeline.phases && pipeline.phases.length > 0) {
      setSelectedRole(activePhase?.roles || []);
      setPhaseHeading(activePhase?.name);
      setPhaseIsRequired(activePhase?.is_required);
      setPhaseIsEndPhase(
        activePhase?.condition === "end" && activePhase?.display_order !== null
          ? 1
          : 0
      );
    }
    if (pipeline.id) {
      setCurrPipeline(pipeline);
    }
  }, [activePhase]);

  useEffect(() => {
    if (activePhase && activePhase?.id) {
      const cloneActivePhase = pipeline.phases.filter(
        (d) => d.id === activePhase?.id
      )[0];
      if (cloneActivePhase && cloneActivePhase?.id) {
        setActivePhase(cloneActivePhase);
      }
    } else {
      setActivePhase(pipeline.phases[0]);
    }
  }, [pipeline]);

  const getPipelineDetailsById = () => {
    setLoading(true);
    getPipelineByID(currPipeline.id)
      .then(({ data: { pipeline } }) => {
        history.replace({ state: { pipelineDetails: pipeline } });
        setCurrPipeline(pipeline);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeletePipelinePhase = (id: number) => {
    setDeleteModalLoading(true);
    deletePipelinePhase(id)
      .then((data) => {
        setActivePhase(pipeline.phases?.[0]);
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById();
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
      });
  };

  const handleOnSelectItem = (item: RoleType) => {
    const selectedItemsArr = selectedRole || [];
    const index = selectedItemsArr.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    // if doesnt exist push item
    if (index === -1) {
      setSelectedRole(() => [...selectedRole, item]);
    }
    // if already exists remove item
    else {
      setSelectedRole((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  const handleOnSelectPhase = (item: any) => {
    const index = selectedPhases.findIndex(
      // @ts-ignore
      (selectedItem) => selectedItem?.id === item.id
    );

    if (index === -1) {
      // @ts-ignore
      setSelectedPhases((prev: any) => [...prev, item]);
    }
    else {
      setSelectedPhases((prevState) => {
        prevState.splice(index, 1);
        return [...prevState];
      });
    }
  };

  const handleOnBlur = () => {
    const ids = selectedRole.map((selectedItem) => {
      return selectedItem.id;
    });
    if (activePhase?.id) {
      setRoleLoading(true);
      addPhaseRoles(activePhase?.id, ids || [])
        .then(() => {
          successToast(
            intl.formatMessage({
              id: "PROJECTS_PIPELINE_ROLE_UPDATE_SUCCES_MESSAGE",
            })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({
              id: "PROJECTS_PIPELINE_ROLE_UPDATE_FAILURE_MESSAGE",
            })
          );
        })
        .finally(() => {
          getPipelineDetailsById?.();
          setRoleLoading(false);
        });
    }
  };

  const handlePhaseHeading = (phaseIsRequired: any) => {
    updatePhase(
      currPipeline.id,
      activePhase?.id,
      phaseHeading,
      phaseIsRequired,
      activePhase.is_endPhase
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleIsEndpHase = (isEndPhase: number) => {
    updatePhase(
      currPipeline.id,
      activePhase?.id,
      phaseHeading,
      phaseIsRequired,
      isEndPhase
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleIsDisplayMobile = (isMobile: number) => {
    updateDisplayInMobile(activePhase?.id, isMobile)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleApproval = (need_approvals: number) => {
    needApproval(activePhase?.id, need_approvals)
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PIPELINE_APPROVAL_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PIPELINE_APPROVAL_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handlePipelineHeading = (type: string, value: string) => {
    updatePipelines(
      currPipeline.id,
      pipelineHeading,
      type === "transition" ? value : pipelineTransition,
      pipelineStatus
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_NAME_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_NAME_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handlePipelineStatus = (status: string) => {
    updatePipelineStatus(
      currPipeline.id,
      status,
    )
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_STATUS_SUCCES_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_STATUS_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        getPipelineDetailsById?.();
      });
  };

  const handleDrop = useCallback(
    (
      dropzone: {
        childrenCount: any;
        path: any;
        phase: number;
        pipeline: number;
      },
      item: { id: number; isNew: any; type: any }
    ) => {
      // console.log(item);
      if (item && item.isNew) {
        const existingPhase = currPipeline.phases.filter(
          (data) => data.id === dropzone.phase
        )[0];
        const existingTabIds = existingPhase.tabs.map((data) => data.id) || [];
        // console.log(existingTabIds);
        addPhaseTabs(dropzone.phase, [...existingTabIds, item.id])
          .then(() => {
            successToast(
              intl.formatMessage({
                id: "PROJECTS_PIPELINE_TAB_ADD_SUCCES_MESSAGE",
              })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({
                id: "PROJECTS_PIPELINE_TAB_ADD_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            getPipelineDetailsById?.();
          });
      }
    },
    [pipeline]
  );

  const handleDeletePipelinePhaseTab = (id: number) => {
    // const filteredTabs = activePhase?.tabs.filter((data) => data.id !== id);
    // const ids =
    //   filteredTabs && filteredTabs.length > 0
    //     ? filteredTabs.map((data) => data.id)
    //     : [];
    setDeleteModalLoading(true);
    deletePhaseTabs(activePhase?.id, [id])
      .then((data) => {
        successToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setPhaseTabActiveIndex(0);
        getPipelineDetailsById();
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
      });
  };

  const handlePipelinePhaseSort = (sortedList: PipelinePhaseType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: i + 1,
      });
    });
    // sort existing tags API Call
    SortPipelinePhases(currPipeline.id, newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getPipelineDetailsById();
      });
  };

  const handlePhaseTabSort = (sortedList: TabType[]) => {
    const newSortedTabsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTabsArr.push({
        id: sortedListItem.id,
        newposition: i + 1,
      });
    });
    // sort existing tags API Call
    SortPhasesTabs(activePhase?.id, newSortedTabsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getPipelineDetailsById();
      });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const updateDisplayTabsList = () => {
    const updatedTabsList = fieldData.filter((tab) => {
      if (
        (tab.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setFieldData(updatedTabsList);
  };

  useEffect(() => {
    updateDisplayTabsList();

    if (searchText?.length === 0) {
      setFieldData(tabsList);
    }

    return () => {
      setFieldData(tabsList);
    };
  }, [tabsList, searchText]);

  const dragPhase = useRef<number>(0);
  const dragOverPhase = useRef<number>(0);

  const dragTab = useRef<number>(0);
  const dragOverTab = useRef<number>(0);

  const getTabsListAPI = () => {
    getTabsList().then(({ data: { data } }) => {
      dispatch(actions.setTabsList(data));
    });
  };

  const closeModal = () => {
    setShowAddModal(false);
  };

  const hasNative = document && document.elementsFromPoint;

  function getDropTargetElementsAtPoint(x: any, y: any, dropTargets: any) {
    return dropTargets.filter((t: any) => {
      const rect = t.getBoundingClientRect();
      return (
        x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
      );
    });
  }

  // use custom function only if elementsFromPoint is not supported
  const backendOptions = {
    getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
  };

  const handleUpdateApprovers = (approverUsers: UserType[], type: string) => {
    const result: { users_id: number; condition: string }[] = [];
    const existingResult: { users_id: number; condition: string }[] = [];
    approverUsers.map((data) => {
      let obj = {
        users_id: data.id,
        condition: type,
      };
      result.push(obj);
    });

    activePhase.phaseApprovers.map((data) => {
      let obj = {
        users_id: data.users_id,
        condition: data.condition,
      };
      existingResult.push(obj);
    });

    updateApprovers(activePhase.id, [...result, ...existingResult])
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "APPROVERS_ADDED_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "APPROVERS_ADDED_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getPipelineDetailsById();
      });
  };

  const handleSubmit = () => {
    importTabsFromPipeline(
      activePhase?.id,
      selectedPhases?.map((phase: any) => phase?.id)
    )
      .then((data) => {
        closeModal?.();
        SuccessAlert(
          intl.formatMessage({ id: "TABS_IMPORTED_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        closeModal?.();
        errorAlert(
          intl.formatMessage({ id: "TABS_IMPORTED_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getPipelineDetailsById();
      });
  };

  const deleteApprover = (user: PhaseApproverType) => {
    setDeleteModalLoading(true);
    const result = activePhase.phaseApprovers.filter(
      (data) => data.users_id !== user.users_id
    );
    updateApprovers(activePhase.id, result)
      .then((data) => {
        successToast(
          intl.formatMessage({ id: "APPROVERS_REMOVED_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({ id: "APPROVERS_REMOVED_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(true);
        hideDeleteConfirmModal();
        getPipelineDetailsById();
      });
  };

  return (
    <>
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={backendOptions}
      >
        <div className="mt-4">
          {showPhaseModal && (
            <AddPipelinePhaseModal
              show={showPhaseModal}
              closeModal={() => {
                setShowPhaseModal(false);
              }}
              pipeline={currPipeline}
              getPipelineDetailsById={getPipelineDetailsById}
            />
          )}
          {addTabModal.show && (
            <TabsModal
              ModalProps={addTabModal}
              closeModal={() => {
                setAddTabModal({ show: false, type: "new" });
              }}
              getTabsListAPI={getTabsListAPI}
            />
          )}
          {openUserModal.show && (
            <SelectedUsersModal
              show={openUserModal.show}
              closeModal={() => {
                setOpenUserModal({
                  ...openUserModal,
                  show: false,
                });
              }}
              onSelectUsers={(customers) => {
                if (activePhase.phaseApprovers.length === 0) {
                  handleApproval(openUserModal.approvalValue);
                }
                handleUpdateApprovers(customers, openUserModal.type);
                // console.log(customers);
                setOpenUserModal({
                  ...openUserModal,
                  show: false,
                });
              }}
              isSelectionModal={true}
              selectedContacts={activePhase.phaseApprovers}
            />
          )}
          {/* pipline name header */}
          <div className="card  bgi-position-y-center bgi-no-repeat mb-10 mt-3">
            <div className="p-8 d-flex flex-row align-items-center">
              <div className="me-5">
                <SVGICON
                  src={FilemanagerIcon}
                  className="svg-icon-primary svg-icon-3x"
                />
              </div>
              <h4>
                {intl.formatMessage({ id: "PIPELINE_DETAILS_EDIT_TITLE" })}
              </h4>
            </div>
          </div>

          {/* General Details */}
          <div className="card  bgi-position-y-center bgi-no-repeat mb-10 p-4">
            <h4>
              {intl.formatMessage({
                id: "PIPELINE_DETAILS_GENERAL_DETAILS_TTILE",
              })}
            </h4>
            <div className="d-flex col-12 mt-2">
              {/* Pipeline Name */}
              <div className="mb-4 col-6">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="">
                    {intl.formatMessage({
                      id: "PIPELINE_DETAILS_PIPELINE_NAME",
                    })}
                  </span>
                </label>
                <div className=" d-flex flex-row align-items-center">
                  <input
                    type="text"
                    value={pipelineHeading}
                    onChange={(e) => {
                      setPipelineHeading(e.target.value);
                    }}
                    style={{
                      flex: 1,
                      border: "none",
                      outline: "none",
                    }}
                    className="form-control form-control-lg form-control-solid me-4 fw-bold py-4"
                    onBlur={() => {
                      if (pipelineHeading.length === 0) {
                        setPipelineHeading(activePhase?.name || "");
                      } else if (pipeline?.name !== pipelineHeading) {
                        handlePipelineHeading("heading", pipelineHeading);
                      }
                    }}
                  />
                </div>
              </div>

              {/* Transition Type */}
              <div className="col-3">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="">
                    {intl.formatMessage({
                      id: "PIPELINE_DETAILS_TRANSITION_TYPE",
                    })}
                  </span>
                </label>
                <div className=" d-flex flex-row align-items-center">
                  <div
                    className="me-5 "
                    style={{
                      flex: 1,
                    }}
                  >
                    <div className="d-flex flex-row align-items-center transitionDropdown">
                      <DropDown
                        hideSearch
                        id="users-titles-dropdown"
                        items={PIPELINE_TRANSITION_TYPE_OPTIONS}
                        displayFunc={(item) => {
                          // @ts-ignore
                          return item.title;
                        }}
                        displayValue={
                          PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                            (TITLES_OPTION) =>
                              TITLES_OPTION.name === pipelineTransition
                          )?.title || ""
                        }
                        onSelectItem={(item) => {
                          setPipelineTransition(item.name);
                          handlePipelineHeading("transition", item.name);
                        }}
                        selectedItem={PIPELINE_TRANSITION_TYPE_OPTIONS.find(
                          (TITLES_OPTION) =>
                            TITLES_OPTION.name === pipelineTransition
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Pipeline Status */}
              <div className="col-3">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="">
                    {intl.formatMessage({
                      id: "PIPELINE_ADD_STATUS_TITLE",
                    })}
                  </span>
                </label>
                <div className=" d-flex flex-row align-items-center">
                  <div
                    className="me-5 "
                    style={{
                      flex: 1,
                    }}
                  >
                    <div className="d-flex flex-row align-items-center transitionDropdown">
                      <DropDown
                        hideSearch
                        id="pipeline-status"
                        items={PIPELINE_STATUS_TYPE_OPTIONS}
                        displayFunc={(item) => {
                          // @ts-ignore
                          return item.title;
                        }}
                        displayValue={
                          PIPELINE_STATUS_TYPE_OPTIONS.find(
                            (TITLES_OPTION) =>
                              TITLES_OPTION.name === pipelineStatus
                          )?.title || ""
                        }
                        onSelectItem={(item) => {
                          // @ts-ignore
                          setPipelineStatus(item.name);
                          handlePipelineStatus(item.name);
                        }}
                        selectedItem={PIPELINE_STATUS_TYPE_OPTIONS.find(
                          (TITLES_OPTION) =>
                            TITLES_OPTION.name === pipelineStatus
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-xl-row ">
            {/* Phases card */}
            <div
              className=" card  d-flex flex-column  w-100 me-4 w-xl-300px mb-lg-8"
              style={{ flex: "0 0 300px" }}
            >
              <div className="card-header d-flex align-items-center">
                <h2>{intl.formatMessage({ id: "PROJECTS_PHASES_TITLE" })}</h2>
              </div>
              <div className="card-body">
                <div className="timeline">
                  {currPipeline?.phases?.map((phase, index) => (
                    <>
                      {phase.condition === "start" && (
                        <div
                          className={clsx(
                            "timeline-item ",
                            activePhase?.id === phase.id && "text-primary"
                          )}
                          role="button"
                          key={index}
                          onClick={() => {
                            if (activePhase?.id !== phase.id) {
                              setActivePhase(phase);
                            }
                          }}
                        >
                          <div className="timeline-line w-40px"></div>
                          <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                            <div
                              className={clsx(
                                "symbol-label bg-light bg-light-primary",
                                activePhase?.id === phase.id &&
                                  "bg-light-primary"
                              )}
                            >
                              <KTSVG
                                path="/media/icons/duotune/communication/com009.svg"
                                className="svg-icon-2 svg-icon-gray-500"
                              />
                            </div>
                          </div>

                          <div className="timeline-content  mt-n1 d-flex justify-content-between">
                            <div className=" mb-5">
                              <div
                                className="fs-5 fw-bold mt-4"
                                style={{
                                  flex: 1,
                                }}
                              >
                                {phase.name}
                              </div>
                            </div>
                            {phase.display_order && (
                              <button
                                className="btn btn-icon btn-light btn-active-light-danger btn-sm mt-2"
                                onClick={() => {
                                  showDeleteConfirmModal(
                                    intl.formatMessage({
                                      id: "PROJECTS_PIPELINE_DELETE_CONFIRM_MESSAGE",
                                    }),
                                    () => {
                                      handleDeletePipelinePhase(phase.id);
                                    }
                                  );
                                }}
                              >
                                <SVGICON
                                  src={DeleteIcon}
                                  className="svg-icon-3"
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {phase.display_order && (
                        <div
                          className={clsx(
                            "timeline-item draggable",
                            activePhase?.id === phase.id && "text-primary"
                          )}
                          key={index}
                          draggable
                          role="button"
                          onClick={() => {
                            if (activePhase?.id !== phase.id) {
                              setActivePhase(phase);
                            }
                          }}
                          onDragStart={(e) => {
                            e.stopPropagation();
                            dragPhase.current = index;
                          }}
                          onDragEnter={(e) => {
                            e.stopPropagation();
                            dragOverPhase.current = index;
                            // console.log(index);
                          }}
                          onDragEnd={(e) => {
                            e.stopPropagation();
                            if (dragPhase.current !== dragOverPhase.current) {
                              const clonePhases = [...currPipeline.phases];

                              const deletedSlice = clonePhases.splice(
                                dragPhase.current,
                                1
                              )[0];
                              clonePhases.splice(
                                dragOverPhase.current,
                                0,
                                deletedSlice
                              );
                              const phaseWithDisplayOrder = clonePhases.filter(
                                (data) => data?.display_order
                              );

                              handlePipelinePhaseSort(phaseWithDisplayOrder);
                            }
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div className="timeline-line w-40px"></div>
                          <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                            <div
                              className={clsx(
                                "symbol-label bg-light",
                                activePhase?.id === phase.id &&
                                  "bg-light-primary",
                                phase.condition === "end" && "bg-light-danger"
                              )}
                            >
                              <KTSVG
                                path="/media/icons/duotune/communication/com009.svg"
                                className="svg-icon-2 svg-icon-gray-500"
                              />
                            </div>
                          </div>

                          <div className="timeline-content  mt-n1 d-flex justify-content-between">
                            <div className=" mb-5">
                              <div
                                className="fs-5 fw-bold mt-4 "
                                style={{
                                  flex: 1,
                                  maxWidth: "120px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <SVGICON
                                  src={DragIcon}
                                  className="svg-icon-2 svg-icon-hover-primary me-3"
                                />
                                {phase.name}
                              </div>
                            </div>
                            {(!phase.can_delete && phase.is_required === 0) ||
                            userData.role.id === 1
                              ? phase.display_order && (
                                  <button
                                    className="btn btn-icon btn-light btn-active-light-danger btn-sm mt-2"
                                    onClick={() => {
                                      showDeleteConfirmModal(
                                        intl.formatMessage({
                                          id: "PROJECTS_PIPELINE_DELETE_CONFIRM_MESSAGE",
                                        }),
                                        () => {
                                          handleDeletePipelinePhase(phase.id);
                                        }
                                      );
                                    }}
                                  >
                                    <SVGICON
                                      src={DeleteIcon}
                                      className="svg-icon-3"
                                    />
                                  </button>
                                )
                              : phase.display_order && (
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      zIndex: "11",
                                    }}
                                  >
                                    <a
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        phase.is_required === 1
                                          ? "Can not delete the phase because this is a required Phase."
                                          : "Can not delete the phase because deals are attached to it."
                                      }
                                      data-tooltip-place="top"
                                    >
                                      <i className="bi bi-info-circle-fill fs-3 text-primary"></i>
                                    </a>

                                    <Tooltip
                                      id="my-tooltip"
                                      place="top"
                                      style={{
                                        backgroundColor: "#f5f8fa",
                                        color: "black",
                                      }}
                                      border="1px solid green"
                                    />
                                  </div>
                                )}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                  <div
                    className="timeline-item "
                    role="button"
                    onClick={() => {
                      setShowPhaseModal(true);
                    }}
                  >
                    <div className="timeline-line w-40px"></div>
                    <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                      <div className="symbol-label btn btn-icon btn-icon-primary">
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr013.svg"
                          className="svg-icon-2 svg-icon-gray-500"
                        />
                      </div>
                    </div>

                    <div className="timeline-content  mt-n1">
                      <div className=" mb-5">
                        <div
                          className="fs-5 fw-bold mt-4"
                          style={{
                            flex: 1,
                          }}
                        >
                          {intl.formatMessage({
                            id: "PROJECTS_PHASE_ADD_PHASE_TITLE",
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {currPipeline?.phases?.map((phase, index) => (
                    <>
                      {phase.condition === "end" &&
                        phase.display_order == undefined && (
                          <div
                            className={clsx(
                              "timeline-item ",
                              activePhase?.id === phase.id && "text-primary"
                            )}
                            role="button"
                            key={index}
                            onClick={() => {
                              if (activePhase?.id !== phase.id) {
                                setActivePhase(phase);
                              }
                            }}
                          >
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                              <div
                                className={clsx(
                                  "symbol-label bg-light bg-light-danger",
                                  activePhase?.id === phase.id &&
                                    "bg-light-primary"
                                )}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/communication/com009.svg"
                                  className="svg-icon-2 svg-icon-gray-500"
                                />
                              </div>
                            </div>

                            <div className="timeline-content  mt-n1 d-flex justify-content-between">
                              <div className=" mb-5">
                                <div
                                  className="fs-5 fw-bold mt-4"
                                  style={{
                                    flex: 1,
                                  }}
                                >
                                  {phase.name}
                                </div>
                              </div>
                              {phase.display_order && (
                                <button
                                  className="btn btn-icon btn-light btn-active-light-danger btn-sm mt-2"
                                  onClick={() => {
                                    showDeleteConfirmModal(
                                      intl.formatMessage({
                                        id: "PROJECTS_PIPELINE_DELETE_CONFIRM_MESSAGE",
                                      }),
                                      () => {
                                        handleDeletePipelinePhase(phase.id);
                                      }
                                    );
                                  }}
                                >
                                  <SVGICON
                                    src={DeleteIcon}
                                    className="svg-icon-3"
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                    </>
                  ))}
                </div>
              </div>
            </div>
            {/* Phase edit Card */}
            <div
              className="card  d-flex flex-column flex-row-fluid  me-4 flex-fill mb-lg-8"
              style={{
                flex: 1,
              }}
            >
              <div className="card-header d-flex align-items-center justify-content-between">
                <div
                  className="d-flex flex-row align-items-center"
                  style={{
                    flex: 1,
                  }}
                >
                  <input
                    type="text"
                    value={phaseHeading}
                    onChange={(e) => {
                      setPhaseHeading(e.target.value);
                    }}
                    style={{
                      flex: 1,
                      border: "none",
                      outline: "none",
                    }}
                    className="panelHeading me-4 fw-bold py-4"
                    onBlur={() => {
                      if (phaseHeading.length === 0) {
                        setPhaseHeading(activePhase?.name || "");
                      } else if (activePhase?.name !== phaseHeading) {
                        handlePhaseHeading(phaseIsRequired);
                      }
                    }}
                  />
                </div>
                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="notifications"
                    value=""
                    checked={phaseIsRequired === 1 ? true : false}
                    style={{ width: "45px", height: "22px" }}
                    onChange={(e) => {
                      if (userData.role.id === 1) {
                        setPhaseIsRequired(e.target.checked ? 1 : 0);
                        handlePhaseHeading(e.target.checked ? 1 : 0);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="card-body">
                {/* questionirre */}

                <div className="d-flex  flex-column">
                  {activePhase?.condition !== "start" &&
                    activePhase?.display_order !== null && (
                      <div className="d-flex flex-stack mb-4">
                        <div className="fw-bold fs-6">
                          {intl.formatMessage({
                            id: "PIPELINE_PROJECTS_QUESTION1",
                          })}
                        </div>
                        <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="notifications"
                            value=""
                            checked={phaseIsEndPhase === 1 ? true : false}
                            style={{ width: "45px", height: "22px" }}
                            onChange={(e) => {
                              setPhaseIsEndPhase(e.target.checked ? 1 : 0);
                              handleIsEndpHase(e.target.checked ? 1 : 0);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  <div className="d-flex flex-stack mb-4">
                    <div className="fw-bold fs-6">
                      {intl.formatMessage({
                        id: "PIPELINE_PROJECTS_QUESTION2",
                      })}
                    </div>
                    <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="notifications"
                        value=""
                        checked={
                          activePhase?.display_in_mobile === 1 ? true : false
                        }
                        style={{ width: "45px", height: "22px" }}
                        onChange={(e) => {
                          handleIsDisplayMobile(e.target.checked ? 1 : 0);
                        }}
                      />
                    </div>
                  </div>
                  {activePhase?.condition === "start" ||
                  (activePhase?.condition === "end" &&
                    activePhase?.display_order === null)
                    ? null
                    : pipeline?.transition_type === "step-by-step" && (
                        <div className="d-flex flex-stack mb-2">
                          <div className="fw-bold fs-6">
                            {intl.formatMessage({
                              id: "PIPELINE_PROJECTS_QUESTION3",
                            })}
                          </div>
                          <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="notifications"
                              value=""
                              checked={
                                activePhase?.need_approvals === 1 ? true : false
                              }
                              style={{ width: "45px", height: "22px" }}
                              onChange={(e) => {
                                if (activePhase?.phaseApprovers?.length === 0) {
                                  setOpenUserModal({
                                    show: true,
                                    type: "primary",
                                    approvalValue: e.target.checked ? 1 : 0,
                                  });
                                } else {
                                  handleApproval(e.target.checked ? 1 : 0);
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                </div>
                <div className="mb-4">
                  {activePhase?.need_approvals === 1 && (
                    <div className="  flex-wrap pt-1 borderNone">
                      {/* Primary Users */}
                      <div>
                        <div className="fw-bold fs-6">
                          {intl.formatMessage({
                            id: "PIPELINE_APPROVAL_PRIMARY_USERS",
                          })}
                        </div>
                        {activePhase?.phaseApprovers &&
                        activePhase?.phaseApprovers.length > 0 ? (
                          <div className="symobleDetails d-flex justify-content-between align-items-center ms-3">
                            <div className="symbol-group symbol-hover flex-nowrap">
                              {activePhase?.phaseApprovers?.map(
                                (user, index) => {
                                  if (user.condition === "primary") {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="symbol symbol-35px symbol-circle"
                                          style={{ position: "relative" }}
                                          onMouseOver={() => {
                                            const tooltipEle =
                                              document.getElementById(
                                                `role-user-tooltip-${index}${activePhase?.id}`
                                              );
                                            if (tooltipEle) {
                                              tooltipEle.style.display = "flex";
                                            }
                                          }}
                                          onMouseOut={() => {
                                            const tooltipEle =
                                              document.getElementById(
                                                `role-user-tooltip-${index}${activePhase?.id}`
                                              );
                                            if (tooltipEle) {
                                              tooltipEle.style.display = "none";
                                            }
                                          }}
                                        >
                                          <div
                                            id={`role-user-tooltip-${index}${activePhase?.id}`}
                                            className="position-absolute bg-light px-3 py-2 fs-8"
                                            style={{
                                              display: "none",
                                              backgroundColor: "white",
                                              top: -60,
                                              borderRadius: 5,
                                            }}
                                          >
                                            {`${user.firstname}  ${user.lastname}`}
                                          </div>

                                          <div>
                                            <span className="symbol-label bg-warning text-inverse-warning fw-bold position-relative">
                                              {(user.firstname ||
                                                " ")[0].toUpperCase()}
                                            </span>
                                            <span
                                              className="position-absolute bg-secondary border d-flex align-items-center"
                                              style={{
                                                top: "-5px",
                                                left: "-5px",
                                                borderRadius: "20px",
                                                height: "17px",
                                                width: "17px",
                                              }}
                                              onClick={() => {
                                                if (
                                                  activePhase?.phaseApprovers &&
                                                  activePhase?.phaseApprovers
                                                    .length > 0 &&
                                                  activePhase?.phaseApprovers.filter(
                                                    (temp) =>
                                                      temp.condition ===
                                                      "primary"
                                                  ).length > 0
                                                ) {
                                                  setDeleteModalLoading(false);
                                                  showDeleteConfirmModal(
                                                    intl.formatMessage({
                                                      id: "APPROVER_PRIMARY_DELETE_CONFIRM_MESSAGE",
                                                    }),
                                                    () => {
                                                      deleteApprover(user);
                                                    }
                                                  );
                                                }
                                              }}
                                            >
                                              <SVGICON
                                                src={CloseIcon}
                                                className="svg svg-icon "
                                              />
                                            </span>
                                          </div>
                                        </div>
                                        {index ===
                                          activePhase?.phaseApprovers.length -
                                            1 && (
                                          <div
                                            className="symbol symbol-35px symbol-circle "
                                            onClick={() => {
                                              setOpenUserModal({
                                                show: true,
                                                type: "primary",
                                                approvalValue:
                                                  activePhase?.need_approvals ===
                                                  1
                                                    ? 1
                                                    : 0,
                                              });
                                            }}
                                          >
                                            <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                              <SVGICON
                                                src={PlusIcon}
                                                className="svg-icon-2 svg-icon-white"
                                              />
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        {index ===
                                          activePhase?.phaseApprovers.length -
                                            1 && (
                                          <div
                                            className="symbol symbol-35px symbol-circle "
                                            onClick={() => {
                                              setOpenUserModal({
                                                show: true,
                                                type: "primary",
                                                approvalValue:
                                                  activePhase?.need_approvals ===
                                                  1
                                                    ? 1
                                                    : 0,
                                              });
                                            }}
                                          >
                                            <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                              <SVGICON
                                                src={PlusIcon}
                                                className="svg-icon-2 svg-icon-white"
                                              />
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              className="symbol symbol-35px symbol-circle "
                              role="button"
                              onClick={() => {
                                setOpenUserModal({
                                  show: true,
                                  type: "primary",
                                  approvalValue:
                                    activePhase?.need_approvals === 1 ? 1 : 0,
                                });
                              }}
                            >
                              <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                <SVGICON
                                  src={PlusIcon}
                                  className="svg-icon-2 svg-icon-white"
                                />
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      {/* Secondary Users */}
                      <div>
                        {activePhase?.phaseApprovers &&
                          activePhase?.phaseApprovers.length > 0 &&
                          activePhase?.phaseApprovers.filter(
                            (temp) => temp.condition === "primary"
                          ).length > 0 && (
                            <>
                              <div className="fw-bold fs-6">
                                {intl.formatMessage({
                                  id: "PIPELINE_APPROVAL_SECONDARY_USERS",
                                })}
                              </div>
                              <div className="symobleDetails d-flex justify-content-between align-items-center ms-3">
                                <div className="symbol-group symbol-hover flex-nowrap">
                                  {activePhase?.phaseApprovers?.map(
                                    (user, index) => {
                                      if (user.condition === "secondary") {
                                        return (
                                          <>
                                            <div
                                              key={index}
                                              className="symbol symbol-35px symbol-circle"
                                              style={{ position: "relative" }}
                                              onMouseOver={() => {
                                                const tooltipEle =
                                                  document.getElementById(
                                                    `role-user-tooltip-${index}${activePhase?.id}`
                                                  );
                                                if (tooltipEle) {
                                                  tooltipEle.style.display =
                                                    "flex";
                                                }
                                              }}
                                              onMouseOut={() => {
                                                const tooltipEle =
                                                  document.getElementById(
                                                    `role-user-tooltip-${index}${activePhase?.id}`
                                                  );
                                                if (tooltipEle) {
                                                  tooltipEle.style.display =
                                                    "none";
                                                }
                                              }}
                                            >
                                              <div
                                                id={`role-user-tooltip-${index}${activePhase?.id}`}
                                                className="position-absolute bg-light px-3 py-2 fs-8"
                                                style={{
                                                  display: "none",
                                                  backgroundColor: "white",
                                                  top: -60,
                                                  borderRadius: 5,
                                                }}
                                              >
                                                {`${user.firstname}  ${user.lastname}`}
                                              </div>

                                              <div>
                                                <span className="symbol-label bg-warning text-inverse-warning fw-bold position-relative">
                                                  {(user.firstname ||
                                                    " ")[0].toUpperCase()}
                                                </span>
                                                <span
                                                  className="position-absolute bg-secondary border d-flex align-items-center"
                                                  style={{
                                                    top: "-5px",
                                                    left: "-5px",
                                                    borderRadius: "20px",
                                                    height: "17px",
                                                    width: "17px",
                                                  }}
                                                  onClick={() => {
                                                    setDeleteModalLoading(
                                                      false
                                                    );
                                                    showDeleteConfirmModal(
                                                      intl.formatMessage({
                                                        id: "APPROVER_SECONDARY_DELETE_CONFIRM_MESSAGE",
                                                      }),
                                                      () => {
                                                        deleteApprover(user);
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <SVGICON
                                                    src={CloseIcon}
                                                    className="svg svg-icon "
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                            {index ===
                                              activePhase?.phaseApprovers
                                                .length -
                                                1 && (
                                              <div
                                                className=""
                                                onClick={() => {
                                                  setOpenUserModal({
                                                    show: true,
                                                    type: "secondary",
                                                    approvalValue:
                                                      activePhase?.need_approvals ===
                                                      1
                                                        ? 1
                                                        : 0,
                                                  });
                                                }}
                                              >
                                                <div className="symbol symbol-35px symbol-circle">
                                                  <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                                    <SVGICON
                                                      src={PlusIcon}
                                                      className="svg-icon-2 svg-icon-white"
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            {index ===
                                              activePhase?.phaseApprovers
                                                .length -
                                                1 && (
                                              <div
                                                className=""
                                                onClick={() => {
                                                  setOpenUserModal({
                                                    show: true,
                                                    type: "secondary",
                                                    approvalValue:
                                                      activePhase?.need_approvals ===
                                                      1
                                                        ? 1
                                                        : 0,
                                                  });
                                                }}
                                                role="button"
                                              >
                                                <div className="symbol symbol-35px symbol-circle">
                                                  <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                                    <SVGICON
                                                      src={PlusIcon}
                                                      className="svg-icon-2 svg-icon-white"
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="position-relative">
                  <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                    <span className="">
                      {intl.formatMessage({ id: "ADD_ROLE_MODAL_FIELD_NAME" })}
                    </span>
                  </label>

                  <div className="d-flex">
                    <div
                      style={{
                        flex: 1,
                      }}
                      className="position-relative"
                    >
                      <DropDown
                        id="phaseRoleTypeDropdown"
                        hideSearch
                        multiSelect
                        items={rolesList.filter((role) => role.id !== 1) || []}
                        selectedItems={selectedRole}
                        onSelectItem={(item) => {
                          //   @ts-ignore
                          handleOnSelectItem(item);
                        }}
                        displayFunc={(item) => {
                          return item.name;
                        }}
                        // onMultiSelectBlur={() => {
                        //     handleOnBlur();
                        // }}
                      />
                    </div>

                    <button
                      className="btn  btn-light btn-active-light-primary btn-lg  ms-2"
                      onClick={() => {
                        handleOnBlur();
                      }}
                    >
                      {!roleLoading &&
                        intl.formatMessage({ id: "SAVE_BUTTON" })}
                      {roleLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {intl.formatMessage({
                            id: "MASTERDATA_TAGS_LOADING_MESSAGE",
                          })}{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>

                <div className="mt-10 firstPhaseTab">
                  <p className="text-info">
                    {selectedRole.length > 0
                      ? `${intl.formatMessage({
                          id: "EDIT_PIPELINE_ROLE_INFO_MESSAGE1",
                        })} ${selectedRole
                          .map((role) => role.name)
                          .join(" ,")} ${intl.formatMessage({
                          id: "EDIT_PIPELINE_USERS_INFO",
                        })}`
                      : intl.formatMessage({
                          id: "EDIT_PIPELINE_ROLE_INFO_MESSAGE2",
                        })}
                  </p>

                  <TabDropzone
                    accept={["tab"]}
                    data={{
                      path: `${pipeline.id}`,
                      pipeline: pipeline.id,
                      phase: activePhase?.id,
                      childrenCount: activePhase?.tabs.length,
                    }}
                    onDrop={handleDrop}
                    path={`${pipeline.id}`}
                    isLast
                  />
                </div>
                <div className="d-flex align-items-center flex-row mt-6">
                  <div
                    role="button"
                    className="badge badge-primary"
                    onClick={() => {
                      setShowAddModal(true);
                    }}
                    title="Import"
                  >
                    {intl.formatMessage({
                      id: "PIPELINE_IMPORT_BUTTON",
                    })}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      data-tooltip-id="import-tooltip"
                      data-tooltip-content={intl.formatMessage({
                        id: "PIPELINE_TOOLTIP_INFO",
                      })}
                      data-tooltip-place="top"
                    >
                      <i className="bi bi-info-circle-fill fs-3 svg-icon mx-2"></i>
                    </span>

                    <Tooltip
                      id="import-tooltip"
                      place="top"
                      style={{
                        backgroundColor: "#f5f8fa",
                        color: "#a1a5b7",
                        zIndex: 99,
                        whiteSpace: "normal",
                        maxWidth: "250px",
                        wordWrap: "break-word",
                      }}
                      border="1px solid #a1a5b7"
                      className="btn text-dark my-2"
                    />
                  </div>
                  <Modal
                    show={showAddModal}
                    centered
                    contentClassName={loading ? "pe-none" : "AddNew"}
                    dialogClassName={"medium-size-modal"}
                    backdrop="static"
                  >
                    <Modal.Header>
                      <Modal.Title>
                        {intl.formatMessage({ id: "PIPELINE_IMPORT_TABS" })}
                      </Modal.Title>
                      <CloseButton
                        // @ts-ignore
                        onClick={() => {
                          setShowAddModal(false);
                        }}
                      />
                    </Modal.Header>
                    
                      <Modal.Body>
                        <div className="p-4">
                          <div className="mb-4">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="">
                                {intl.formatMessage({
                                  id: "PIPELINE_PHASES",
                                })}
                              </span>
                            </label>
                          </div>
                          <div className="mb-4">
                            <DropDown
                              id="post-groups-dropdown"
                              multiSelect={true}
                              items={otherPhases?.map((phase: any) => {
                                return { id: phase?.id, name: phase?.name };
                              })}
                              displayFunc={(item) => item.name}
                              selectedItems={selectedPhases}
                              displayValue={
                                otherPhases.filter(
                                  //@ts-ignore
                                  (item: any) =>//@ts-ignore
                                    item?.name === selectedPhases?.name
                                )[0]?.name
                              }
                              onSelectItem={(item) => {
                                //   @ts-ignore
                                handleOnSelectPhase(item);
                              }}
                              //selectedItems={selectedLabels}
                              enableHoverBackground
                              hideSelectedItems
                              // onMultiSelectBlur={() => {
                              //   handleOnBlur("label");
                              // }}
                            />
                          </div>
                        </div>
                      </Modal.Body>

                      <Modal.Footer className="justify-content-center">
                        <Button
                          variant="secondary"
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          {intl.formatMessage({ id: "CLOSE_BUTTON" })}
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {!loading &&
                            intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              {intl.formatMessage({
                                id: "MASTERDATA_TAGS_LOADING_MESSAGE",
                              })}{" "}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </Button>
                      </Modal.Footer>
                    
                  </Modal>
                </div>
                {loading ? (
                  <SimpleLoader />
                ) : (
                  activePhase &&
                  activePhase?.tabs &&
                  activePhase?.tabs.length > 0 && (
                    <>
                      <div className="card card-boody p-4 mt-6">
                        <div>
                          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fw-bold">
                            {activePhase?.tabs.map((tab, i) => {
                              if (tab) {
                                return (
                                  <div className="d-flex align-items-center">
                                    <span>
                                     <SVGICON
                                  src={DragIcon}
                                  className="svg-icon-2 svg-icon-hover-primary me-3"
                                />
                                </span>
                                  <li
                                    className="nav-item d-flex me-4"
                                    key={i}
                                    onClick={() => {
                                      if (phaseTabActiveIndex !== i) {
                                        setPhaseTabActiveIndex(i);
                                      }
                                    }}
                                    draggable
                                    onDragStart={(e) => {
                                      e.stopPropagation();
                                      dragTab.current = i;
                                    }}
                                    onDragEnter={(e) => {
                                      e.stopPropagation();
                                      dragOverTab.current = i;
                                    }}
                                    onDragEnd={(e) => {
                                      e.stopPropagation();
                                      if (
                                        dragTab.current !== dragOverTab.current
                                      ) {
                                        const cloneTabs = [
                                          ...activePhase?.tabs,
                                        ];
                                        const deletedSlice = cloneTabs.splice(
                                          dragTab.current,
                                          1
                                        )[0];
                                        cloneTabs.splice(
                                          dragOverTab.current,
                                          0,
                                          deletedSlice
                                        );
                                        // const temp = cloneTabs[dragTab.current];
                                        // cloneTabs[dragTab.current] =
                                        //   cloneTabs[dragOverTab.current];
                                        // cloneTabs[dragOverTab.current] = temp;
                                        handlePhaseTabSort(cloneTabs);
                                      }
                                    }}
                                    onDragOver={(e) => {
                                      //   e.stopPropagation();
                                      e.preventDefault();
                                    }}
                                  >
                                    <span
                                      className={clsx(
                                        "nav-link text-active-primary pb-2 m-0",
                                        phaseTabActiveIndex === i
                                          ? "active"
                                          : ""
                                      )}
                                      role={"button"}
                                    >
                                      {tab.name}
                                    </span>
                                    <button
                                      className={clsx(
                                        "btn btn-icon   btn-sm",
                                        phaseTabActiveIndex === i
                                          ? "btn-icon-danger"
                                          : ""
                                      )}
                                      role={"button"}
                                      onClick={() => {
                                        showDeleteConfirmModal(
                                          intl.formatMessage({
                                            id: "PROJECTS_PIPELINE_PHASE_TAB_DELETE_CONFIRM_MESSAGE",
                                          }),
                                          () => {
                                            handleDeletePipelinePhaseTab(
                                              tab.id
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      <SVGICON
                                        src={DeleteIcon}
                                        className="svg-icon-3 mb-2"
                                      />
                                    </button>
                                  </li>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    </>
                  )
                )}
                {activePhase && activePhase?.tabs.length > 0 && (
                  <div className="text-muted my-4">
                    {intl.formatMessage({ id: "PROJECTS_PIPELINE_TAB_INFO" })}
                    <a
                      onClick={() => {
                        const tabData = activePhase?.tabs[phaseTabActiveIndex];
                        history.push(`/projects/master-data/tabs/editTab`, {
                          tabDetails: tabData,
                        });
                      }}
                      role="button"
                      className="ms-2"
                    >
                      {intl.formatMessage({
                        id: "PROJECTS_PIPELINE_TAB_INFO1",
                      })}
                    </a>
                  </div>
                )}
              </div>
            </div>
            {/* Tab Card */}
            <div
              className="card  d-flex flex-column  w-100 w-xl-300px mb-lg-8"
              style={{ flex: "0 0 300px" }}
            >
              <div className="card-header d-flex align-items-center justify-content-between">
                <h2>
                  {intl.formatMessage({ id: "PROJECTS_PIPELINE_TABS_TITLE" })}
                </h2>
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-primary"
                  onClick={() => {
                    setAddTabModal({
                      show: true,
                      type: "new",
                    });
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                </button>
              </div>
              <div className="card-body">
                <div className="">
                  <div className="d-flex align-items-center">
                    <SVGICON
                      src={SearchIcon}
                      className="svg-icon svg-icon-1 position-absolute ms-6"
                    />
                    <input
                      type="text"
                      className="form-control form-control-solid ps-15 my-4"
                      placeholder={intl.formatMessage({
                        id: "USERS_SEARCH_PLACEHOLDER",
                      })}
                      onChange={(e) => {
                        onSearchTextChange(e.target.value);
                      }}
                      value={searchText}
                    />
                  </div>
                  <ScrollingComponent
                    style={{
                      height: "400px",
                      overflow: "auto",
                    }}
                  >
                    {fieldData.map((tab: TabType, index) => {
                      if (
                        activePhase?.tabs.filter((data) => data.id === tab.id)
                          .length > 0
                      ) {
                        return (
                          <div className="p-4 my-2 border border-secondary border-rounded bg-secondary sideBarItem">
                            {tab.name}
                          </div>
                        );
                      } else {
                        return (
                          <div className="sideBar">
                            <TabSideBar field={tab} type="tab" key={index} />
                          </div>
                        );
                      }
                    })}
                  </ScrollingComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default EditPipeline;
