const languages = {
  NUMBER_ITEMS_PER_PAGE_OPTIONS: {
    en: JSON.stringify([
      { id: 1, value: 10 },
      { id: 2, value: 20 },
      { id: 3, value: 50 },
    ]),
    de: JSON.stringify([
      { id: 1, value: 10 },
      { id: 2, value: 20 },
      { id: 3, value: 50 },
    ]),
  },
  CLOSE_BUTTON: {
    en: "Close",
    de: "Schließen",
  },
  SUBMIT_BUTTON: {
    en: "Submit",
    de: "Absenden",
  },
  CANCEL_BUTTON: {
    en: "Cancel",
    de: "Abbrechen",
  },
  SAVE_BUTTON: {
    en: "Save",
    de: "Speichern",
  },
  CONTINUE_BUTTON: {
    en: "Continue",
    de: "Weiter",
  },
  OKAY_BUTTON: {
    en: "Okay",
    de: "OK",
  },
  EDIT_BUTTON: {
    en: "Edit",
    de: "Bearbeiten",
  },
  DELETE_BUTTON: {
    en: "Delete",
    de: "Löschen",
  },
  FILTER_BUTTON: {
    en: "Filter",
    de: "Filter",
  },
  FILTER_OPTIONS_TITLE: {
    en: "Filter options",
    de: "Filter",
  },
  APPLY_BUTTON: {
    en: "Apply",
    de: "Anwenden",
  },
  SAVE_CHANGES_BUTTON: {
    en: "Save",
    de: "Speichern",
  },
  YES_START_BUTTON: {
    en: "Yes, start",
    de: "Ja, starten",
  },
  YES_DELETE_BUTTON: {
    en: "Yes, delete",
    de: "Ja, löschen",
  },
  NO_CANCEL_BUTTON: {
    en: "No, cancel",
    de: "Nein, abbrechen",
  },
  UPLOAD_FILE_BUTTON: {
    en: "Upload File",
    de: "Datei hochladen",
  },
  ADD_BUTTON: {
    en: "Add",
    de: "Hinzufügen",
  },
  DOWNLOAD_REPORT_BUTTON: {
    en: "Download Report",
    de: "Bericht herunterladen",
  },
  DOWNLOAD_TITLE: {
    en: "Download",
    de: "Herunterladen",
  },
  PREVIEW_TITLE: {
    en: "Preview",
    de: "Vorschau",
  },
  YOU_TITLE: {
    en: "You",
    de: "Du",
  },
  SHOW_MORE_TITLE: {
    en: "Show more",
    de: "Mehr anzeigen",
  },
  HIDE_TITLE: {
    en: "Hide",
    de: "Verstecken",
  },
  AT_TITLE: {
    en: "at",
    de: "um",
  },
  NO_ITEMS_FOUND: {
    en: "No items found.",
    de: "Keine Elemente gefunden.",
  },
  NO_PHASES_FOUND: {
    en: "No phases found.",
    de: "Keine Phasen gefunden.",
  },
  NO_TASKS_FOUND: {
    en: "No tasks found.",
    de: "Keine Aufgaben gefunden.",
  },
  NO_DATA_FOUND: {
    en: "No data found.",
    de: "Keine Daten gefunden.",
  },
  SELECTED_TITLE: {
    en: "Selected",
    de: "Ausgewählt",
  },
  BLOCKING_DEPENDENCY_AFTER_TITLE: {
    en: "After",
    de: "Nach",
  },
  BLOCKING_DEPENDENCY_TITLE: {
    en: "After (AND)",
    de: "Nach (UND)",
  },
  WARNING_DEPENDENCY_TITLE: {
    en: "After (OR)",
    de: "Nach (ODER)",
  },
  BLOCKING_DEPENDENCY_AND_TITLE: {
    en: "AND",
    de: "UND",
  },
  WARNING_DEPENDENCY_OR_TITLE: {
    en: "OR",
    de: "ODER",
  },
  FORM_VALIDATION_60SYMBOLS_REQUIRED: {
    en: "Maximum 60 symbols!",
    de: "Es sind maximal 60 Zeichen erlaubt.",
  },
  FORM_VALIDATION_FIELD_LABEL_NAME: {
    en: "Name",
    de: "Name",
  },
  FORM_VALIDATION_FIELD_LABEL_DESCRIPTION: {
    en: "Description",
    de: "Beschreibung",
  },
  FORM_VALIDATION_FIELD_LABEL_EMAIL: {
    en: "Email",
    de: "E-Mail",
  },
  FORM_VALIDATION_FIELD_LABEL_PHONE: {
    en: "Phone Number",
    de: "Telefonnummer",
  },
  FORM_VALIDATION_FIELD_LABEL_ADDRESS_LINE_1: {
    en: "Address Line 1",
    de: "Adresszeile 1",
  },
  FORM_VALIDATION_FIELD_LABEL_ADDRESS_LINE_2: {
    en: "Address Line 2",
    de: "Adresszeile 2",
  },
  FORM_VALIDATION_FIELD_LABEL_COUNTRY: {
    en: "Country",
    de: "Land",
  },
  FORM_VALIDATION_FIELD_LABEL_STATE: {
    en: "State",
    de: "Bundesland",
  },
  FORM_VALIDATION_FIELD_LABEL_CITY: {
    en: "City",
    de: "Stadt",
  },
  FORM_VALIDATION_FIELD_LABEL_ZIPCODE: {
    en: "Zipcode",
    de: "Postleitzahl",
  },
  FORM_VALIDATION_FIELD_LABEL_STREET: {
    en: "Street",
    de: "Straße",
  },
  FORM_VALIDATION_FIELD_LABEL_STREET_EXTRA: {
    en: "Street extra",
    de: "Adresszusatz",
  },
  FORM_VALIDATION_FIELD_LABEL_SUPPORT_PHONE: {
    en: "Support Phone",
    de: "Support Telefon",
  },
  FORM_VALIDATION_FIELD_LABEL_SUPPORT_EMAIL: {
    en: "Support Email",
    de: "Support E-Mail",
  },
  FORM_VALIDATION_FIELD_LABEL_SALES_PHONE: {
    en: "Sales Phone",
    de: "Telefon",
  },
  FORM_VALIDATION_FIELD_LABEL_SALES_EMAIL: {
    en: "Sales Email",
    de: "E-Mail",
  },
  FORM_VALIDATION_FIELD_LABEL_REMARKS: {
    en: "Remarks",
    de: "Bemerkungen",
  },
  FORM_VALIDATION_FIELD_NAME_REQUIRED: {
    en: "Name is required!",
    de: "Name ist erforderlich!",
  },
  FORM_VALIDATION_FIELD_LABEL_USERNAME: {
    en: "Username",
    de: "Benutzername",
  },
  FORM_VALIDATION_FIELD_LABEL_ORDER_NUMBER: {
    en: "Order number",
    de: "Bestellnummer",
  },
  FORM_VALIDATION_FIELD_LABEL_TARGET: {
    en: "Target kWp",
    de: "Ziel kWp",
  },
  FORM_VALIDATION_FIELD_LABEL_FINAL_OUTPUT: {
    en: "Final output kWp",
    de: "Endergebnis kWp",
  },
  FORM_VALIDATION_FIELD_LABEL_TARGET_COUNT: {
    en: "Target count",
    de: "Zielanzahl",
  },
  FORM_VALIDATION_FIELD_LABEL_TARGET_DURATION: {
    en: "Target duration",
    de: "Zieldauer",
  },
  FORM_VALIDATION_FIELD_LABEL_EXPECTED_COUNT: {
    en: "Expected count",
    de: "Erwartete Anzahl",
  },
  FORM_VALIDATION_FIELD_LABEL_EXPECTED_DURATION: {
    en: "Expected duration",
    de: "Erwartete Dauer",
  },
  FORM_VALIDATION_FIELD_LABEL_PHASE_STATUS: {
    en: "Phase status",
    de: "Phasenstatus",
  },
  FORM_VALIDATION_FIELD_LABEL_STATUS: {
    en: "Status",
    de: "Status",
  },
  FORM_VALIDATION_FIELD_LABEL_BLOCKING: {
    en: "After (AND)",
    de: "Nach (UND)",
  },
  FORM_VALIDATION_FIELD_LABEL_WARNING: {
    en: "After (OR)",
    de: "Nach (ODER)",
  },
  FORM_VALIDATION_FIELD_LABEL_TASK_CONDITION: {
    en: "Task condition",
    de: "Aufgabenbedingung",
  },
  FORM_VALIDATION_FIELD_LABEL_TASKS_LIST: {
    en: "Tasks List",
    de: "Aufgabenliste",
  },
  FORM_VALIDATION_TARGET_DURATION_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "days", title: "Days" },
      { id: 2, name: "weeks", title: "Weeks" },
      { id: 3, name: "months", title: "Months" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "days", title: "Tage" },
      { id: 2, name: "weeks", title: "Wochen" },
      { id: 3, name: "months", title: "Monate" },
    ]),
  },
  FORM_VALIDATION_DPEPENDENT_PHASE_OPTIONS: {
    en: JSON.stringify([
      { id: 3, name: "", title: "Please select one option" },
      { id: 1, name: "phasestarttime", title: "Phase start time" },
      { id: 2, name: "task", title: "Task" },
    ]),
    de: JSON.stringify([
      { id: 3, name: "", title: "Bitte wählen Sie eine Option" },
      { id: 1, name: "phasestarttime", title: "Phasenstartzeit" },
      { id: 2, name: "task", title: "Aufgabe" },
    ]),
  },
  FORM_VALIDATION_TASK_TYPE_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "and", title: "Block" },
      { id: 2, name: "or", title: "Warning" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "and", title: "Blockieren" },
      { id: 2, name: "or", title: "Warnung" },
    ]),
  },
  FORM_VALIDATION_WRONG_EMAIL_FORMAT: {
    en: "Wrong email format!",
    de: "Bitte gebe eine gültige E-Mail-Adresse ein!",
  },
  FORM_VALIDATION_FIELD_EMAIL_REQUIRED: {
    en: "Email is required!",
    de: "Bitte gebe eine gültige E-Mail-Adresse ein!",
  },
  PASSWORD_IS_REQUIRED: {
    en: "Password is required",
    de: "Passwort erforderlich",
  },
  MENU_FILEMANAGER: {
    en: "FILE MANAGER",
    de: "DATEIMANAGER",
  },
  MENU_DASHBOARD: {
    en: "DASHBOARD",
    de: "DASHBOARD",
  },
  MENU_CONTENT: {
    en: "PROJECTS",
    de: "PROJEKTE",
  },
  MENU_SYSTEM: {
    en: "SYSTEM",
    de: "SYSTEM",
  },
  MENU_SYSTEM_USER: {
    en: "USER",
    de: "BENUTZER",
  },
  MENU_USERMANAGEMENT: {
    en: "USER MANAGEMENT",
    de: "BENUTZERVERWALTUNG",
  },
  MENU_SYSTEM_PARTNER: {
    en: "ORGANISATIONS",
    de: "ORGANISATIONEN",
  },
  MENU_CRM: {
    en: "CRM",
    de: "CRM",
  },
  MENU_SYSTEM_CUSTOMER: {
    en: "CONTACTS",
    de: "KONTAKTE",
  },
  MENU_CUSTOMERMANAGEMENT: {
    en: "Customer Management",
    de: "Kundenbetreuung",
  },
  MENU_PARTNERMANAGEMENT: {
    en: "Edit organisation",
    de: "Organisation bearbeiten",
  },
  MENU_DEVICE_MANAGEMENT_MASTERDATA: {
    en: "Masterdata",
    de: "Stammdaten",
  },
  ROUTES_OVERVIEW: {
    en: "Overview",
    de: "Übersicht",
  },
  ROUTES_USERMANAGEMENT_EDITUSER: {
    en: "Edit user",
    de: "Benutzer bearbeiten",
  },
  ROUTES_CUSTOMERMANAGEMENT_EDITCUSTOMER: {
    en: "Edit customer",
    de: "Kunden Bearbeiten",
  },
  ROUTES_TEMPLATEMANAGEMENT_EDITTEMPLATE: {
    en: "Edit template",
    de: "Template Bearbeiten",
  },
  ROUTES_TEMPLATEMANAGEMENT_GANTTCHART: {
    en: "Gantt Chart",
    de: "Gantt Diagramm",
  },
  ROUTES_PROJECTMANAGEMENT_EDITTEMPLATE: {
    en: "Edit project management",
    de: "Projekt Bearbeiten",
  },
  ROUTES_CONTRACTMANAGEMENT_EDITCONTRACT: {
    en: "Edit contract",
    de: "Vertrag Bearbeiten",
  },
  USERS_TITLES_NAME: {
    en: "Title",
    de: "Titel",
  },
  USERS_FIRSNAME_FIELD: {
    en: "First name",
    de: "Vorname",
  },
  USERS_LASTNAME_FIELD: {
    en: "Last name",
    de: "Nachname",
  },
  USERS_EMAIL_FIELD: {
    en: "Email",
    de: "E-Mail",
  },
  USERS_PASSWORD_FIELD: {
    en: "Password",
    de: "Passwort",
  },
  USERS_PASSWORD_INFO: {
    en: "Use 8 or more characters with a mix of letters, numbers & symbols.",
    de: "Verwenden 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen.",
  },
  USERS_CONFIRM_PASSWORD_FIELD: {
    en: "Confirm Password",
    de: "Passwort bestätigen",
  },
  USERS_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  USERS_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  USERS_ADD_NEW_USER_BUTTON: {
    en: "Add New User",
    de: "Benutzer hinzufügen",
  },
  USERS_TABLE_COLUMN1: {
    en: "User",
    de: "Benutzer",
  },
  USERS_TABLE_COLUMN2: {
    en: "Role",
    de: "Rolle",
  },
  USERS_TABLE_COLUMN3: {
    en: "Last login",
    de: "Letzte Anmeldung",
  },
  USERS_TABLE_COLUMN4: {
    en: "2FA Active",
    de: "2FA Aktiv",
  },
  USERS_TABLE_COLUMN5: {
    en: "Joined date",
    de: "Registrierungsdatum",
  },
  USERS_VERIFICATION_ENABLED_TEXT: {
    en: "Enabled",
    de: "Aktiviert",
  },
  USERS_VERIFICATION_DISABLED_TEXT: {
    en: "Disabled",
    de: "Deaktiviert",
  },
  USERS_ADDED_SUCCESS_MESSAGE: {
    en: "User Details saved successfully!",
    de: "Die Benutzerdaten wurden erfolgreich gespeichert",
  },
  USERS_ADDED_FAILURE_MESSAGE: {
    en: "Cannot save user details, Please try again!",
    de: "Die Benutzerdaten konnten gespeichert werden, bitte versuche es noch mal!",
  },
  USERS_PROFILE_DETAILS_TITLE: {
    en: "Personal data",
    de: "Persönliche Daten",
  },
  USERS_AVATAR_TITLE: {
    en: "Avatar",
    de: "Profilbild",
  },
  USERS_FORM_INFO: {
    en: "Allowed file types: png, jpg, jpeg (upto 5 MB).",
    de: "Erlaubte Dateiendungen sind: *.png, *.jpg und *.jpeg (upto 5 MB).",
  },
  USERS_DROP_DOWN_PLACEHOLDER: {
    en: "Select option",
    de: "Bitte wählen",
  },
  USERS_SALUATION_REQUIRED: {
    en: "Please enter Salutation!",
    de: "Das Feld Anrede ist ein Pflichtfeld!",
  },
  USERS_60SYMBOLS_REQUIRED: {
    en: "Maximum 60 symbols!",
    de: "Es sind maximal 60 Zeichen erlaubt.",
  },
  USERS_FIRSTNAME_REQUIRED: {
    en: "First name is required!",
    de: "Das Feld Vornam eist ein Pflichtfeld!",
  },
  USERS_LASTNAME_REQUIRED: {
    en: "Last name is required!",
    de: "Das Feld Nachname ist ein Pflichtfeld!",
  },
  USERS_WRONG_EMAIL_FORMAT: {
    en: "Wrong email format!",
    de: "Bitte gebe eine gültige E-Mail-Adresse ein!",
  },
  USERS_EMAIL_REQUIRED: {
    en: "Email is required!",
    de: "Das Feld E-Mail ist ein Pflichtfeld!",
  },
  USERS_USERNAME_FIELD_ERROR: {
    en: "Invalid username",
    de: "Bearbeiten",
  },
  USERS_PASSWORD_REQUIRED: {
    en: "Password is required",
    de: "Das Feld Passwort ist ein Pflichtfeld",
  },
  USERS_PASSWORD_MATCHES_INFO: {
    en: "Password must be at least 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character!",
    de: "Das Passwort muss mindestens 8 Zeichen lang sein, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen enthalten!",
  },
  USERS_CONFIRM_PASSWORD_REQUIRED: {
    en: "Password confirmation is required!",
    de: "Bitte wiederholen Sie das Passwort!",
  },
  USERS_PASSWORD_MISMATCH: {
    en: "Password and Confirm Password didn't match",
    de: "Die Passwörter stimmen nicht überein!",
  },
  USERS_FULL_NAME: {
    en: "Full Name",
    de: "Name",
  },
  USERS_EMAIL_CHNAGE_SUCCESS_MESSAGE: {
    en: "User email changed successfully!",
    de: "Die E-Mail Adresse wurde erfolgreich aktualisiert!",
  },
  USERS_EMAIL_CHANGE_FAILURE_MESSAGE: {
    en: "User email change failed!",
    de: "Bei der aktualisierung der E-Mail Adresse ist fehler aufgetreten, bitte versuche es noch mal!",
  },
  USERS_PASSWORD_CHANGE_SUCCESS_MESSAGE: {
    en: "User password changed successfully!",
    de: "Das Passwort wurde erfolgreich geändert!",
  },
  USERS_PASSWORD_CHANGE_FAILURE_MESSAGE: {
    en: "User password change failed!",
    de: "Bei der aktualisierung des Passworts ist ein Fehler aufgetreten, bitte versuche es noch mal!",
  },
  USERS_DISABLE_VERIFICATION_SUCCESS_MESSAGE: {
    en: "Two-factor authentication disabled successfully!",
    de: "Two-factor Authentifizierung wurde erfolgreich deaktiviert!",
  },
  USERS_DISABLE_VERIFICATION_FAILURE_MESSAGE: {
    en: "Disabling Two-factor authentication failed, Please try again!",
    de: "Beim deaktivieren der Two-factor Authentifizierung ist ein Fehler aufgetreten, bitte versuche es noch mal!",
  },
  USERS_AUTH_LOADING_MESSAGE: {
    en: "Generating QR code...",
    de: "Generiere QR-Code, bitte warten...",
  },
  USERS_QR_CODE_FAILURE_MESSAGE: {
    en: "There was an issue generting codes, please try again!",
    de: "Beim generieren des QR-Codes ist ein Fehler aufgetreten!",
  },
  USERS_ENABLING_VERIFICATION_MESSAGE: {
    en: "Enabling Two-factor authentication...",
    de: "Aktiviere Two-factor Authentifizierung, bitte warten...",
  },
  USERS_INCORRECT_PASSWORD_MESSAGE: {
    en: "Incorrect password, please try again!",
    de: "Das Passwort ist falsch, bitte versuche es noch mal!",
  },
  USERS_LOGIN_INFO: {
    en: "In addition to your username and password, you’ll have to enter a code (delivered via app) to log into your account.",
    de: "Zusätzlich zu deinem Benutzernamen und Passwort musst du einen Code eingeben (per App), um sich einzuloggen.",
  },
  USERS_AUTHENTICATOR_APP_TITLE: {
    en: "Authenticator Apps",
    de: "Authentifizierungs APP",
  },
  USERS_AUTHENTICATOR_APP_INFO: {
    en: "Get codes from an app like Google Authenticator, Microsoft Authenticator, Authy or 1Password.",
    de: "Hole dir eine App wie Google Authenticator, Microsoft Authenticator, Authy oder 1Password",
  },
  USERS_PASSWORD_FORM_ERROR: {
    en: "You’ll have to enter your password , before enabling Two-factor authentication",
    de: "Du musst das Passwort eingeben, bevor du die Zwei-Faktor-Authentifizierung aktivieren kannst.",
  },
  USERS_PASSWORD_PLACEHOLDER: {
    en: "Please enter your password",
    de: "Bitte geben Sie ein Passwort ein",
  },
  USERS_AUTHENTICATOR_QR_CODE_TEXT1: {
    en: "Using an authenticator app like",
    de: "Mit einer Authentifizierungs-App wie ",
  },
  USERS_AUTHRNTICATOR_APP1: {
    en: "Google Authenticator",
    de: "Google Authenticator",
  },
  USERS_AUTHRNTICATOR_APP2: {
    en: " Microsoft Authenticator",
    de: " Microsoft Authenticator",
  },
  USERS_AUTHRNTICATOR_APP3: {
    en: " Authy",
    de: " Authy",
  },
  USERS_AUTHRNTICATOR_APP4: {
    en: " 1Password",
    de: " 1Password",
  },
  USERS_AUTHENTICATOR_QR_CODE_TEXT2: {
    en: " , scan the QR code. It will generate a 6 digit code for you to enter below.",
    de: " , scanne den QR-Code. Anschließend wird ein 6-stelliger Code generiert den du unten eingeben kannst.",
  },
  USERS_AUTHENTICATOR_QR_CODE_TEXT3: {
    en: "If you having trouble using the QR code, select manual entry on your app, and enter your username and the code",
    de: "Wenn du Probleme bei der Verwendung des QR-Codes hast, wähle die manuelle Eingabe und logge dich mit Benutzernamen und Passwort ein",
  },
  USERS_AUTH_MODAL_TITLE: {
    en: "Choose An Authentication Method",
    de: "Wähle eine Authentifizierungsmethode",
  },
  USERS_SIGN_IN_WRONG_EMAIL_FORMAT: {
    en: "Wrong email format",
    de: "Falsches E-Mail format",
  },
  USERS_SIGN_IN_CONFIRM_PASSWORD: {
    en: "Confirm password is required!",
    de: "Bitte wiederhole das Passwort!",
  },
  USERS_SIGN_IN_PASSWORD_MATCH: {
    en: "Passwords must match!",
    de: "Die Passwörter müssen übereinstimmen!",
  },
  USERS_INCORRECT_PASSOWRD: {
    en: "Incorrect password, please try again!",
    de: "Falsches Passwort, bitte versuche es erneut!",
  },
  USERS_INCORRECT_AUTH_CODE: {
    en: "Incorrect Authentication Code, please try again!",
    de: "Falscher Auth-Code, bitte versuche es erneut!",
  },
  USERS_CONFIRM_PASSWORD_MODAL_TITLE: {
    en: "Confirm password",
    de: "Passwort bestätigen",
  },
  USERS_STATUS_FIELD: {
    en: "status",
    de: "Status",
  },
  USERS_AUTH_STATUS: {
    en: "Authentication code is incorrect",
    de: "Bearbeiten",
  },
  USERS_CONFIRM_PASSWORD_AUTH: {
    en: " You’ll have to enter your password , before disabling Two-factor authentication",
    de: "Bitte geben Sie Passwort ein, bevor Sie die 2 Faktor authentifizierung deaktivieren können",
  },
  USERS_SIGN_IN_TITLE: {
    en: "Sign-in Method",
    de: "Anmeldedaten",
  },
  USERS_SIGN_IN_EMAIL_ADDRESS: {
    en: "Email",
    de: "E-Mail",
  },
  USERS_SIGN_IN_NEW_EMAIL_ADDRESS: {
    en: "Enter New Email Address",
    de: "Neue E-Mail-Adresse",
  },
  USERS_SIGN_IN_CURRENT_EMAIL_ADDRESS: {
    en: "Current Email Address",
    de: "Bearbeiten",
  },
  USERS_SIGN_IN_CONFIRM_PASSWORD_FIELD: {
    en: " Confirm Password",
    de: " Aktuelles Passwort",
  },
  USERS_UPDATE_EMAIL_BUTTON: {
    en: "Save",
    de: "Speichern",
  },
  USERS_CHANGE_EMAIL_BUTTON: {
    en: "Change Email",
    de: "E-Mail-Adresse ändern",
  },
  USERS_REQUIRED: {
    en: "************",
    de: "************",
  },
  USERS_SIGN_IN_CURRENT_PASSWORD: {
    en: "Current Password",
    de: "Aktuelles Passwort",
  },
  USERS_SIGN_IN_NEW_PASSWORD: {
    en: "New Password",
    de: "Neues Passwort",
  },
  USERS_SIGN_IN_CONFIRM_NEW_PASSWORD: {
    en: "Confirm New Password",
    de: "Neues Passwort wiederholen",
  },
  USERS_SIGN_IN_PASSWORD: {
    en: "Password must be at least 8 character and contain symbols",
    de: "Verwenden Sie 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen.",
  },
  USERS_UPDATE_PASSWORD_BUTTON: {
    en: "Save",
    de: "Speichern",
  },
  USERS_RESET_PASSWORD_BUTTON: {
    en: "Reset Password",
    de: "Passwort ändern",
  },
  USERS_AUTHNETICATION: {
    en: "Two-factor Authentication",
    de: "Zwei-Faktor-Authentifizierung",
  },
  USERS_DISABLE_BUTTON: {
    en: "Disable",
    de: "Deaktivieren",
  },
  USERS_DISABLING_LOADING_MESSAGE: {
    en: "Disabling...",
    de: "Deaktiviere...",
  },
  USERS_SECURE_ACCOUNT: {
    en: "Secure Your Account",
    de: "Sichern Sie Ihr Konto",
  },
  USERS_AUTHENTICATION_INFO: {
    en: "Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code",
    de: "Die Zwei-Faktor-Authentifizierung verleiht Ihrem Konto eine zusätzliche Sicherheitsebene. Um sich anzumelden, müssen Sie zusätzlich einen 6-stelligen Code eingeben",
  },
  USERS_ENABLE_BUTTON: {
    en: "Enable",
    de: "Aktivieren",
  },
  USERS_LOGIN_RECOVERY_MODE: {
    en: "Recovery Code",
    de: "Wiederherstellungscode",
  },
  USERS_LOGIN_AUTHENTICATOR_CODE: {
    en: "Authenticator Code",
    de: "Authentifizierungscode",
  },
  USERS_VERIFY_CODE_PLACEHOLDER: {
    en: "Please enter your code",
    de: "Bitte geben Sie Ihren Code ein",
  },
  USERS_TITLE: {
    en: "USERS",
    de: "Bearbeiten",
  },
  USERS_ADD_SUCCESS_MESSAGE: {
    en: "User added successfully!",
    de: "Der Benutzer wurde erfolgreich hinzugefügt!",
  },
  USERS_ADD_FAILURE_MESSAGE: {
    en: "There was an issue creating user, please try again!",
    de: "Bearbeiten",
  },
  USERS_ADD_NEW_TITLE: {
    en: "Add New User",
    de: "Bearbeiten",
  },
  USERS_SALUATION_TITLE: {
    en: "Salutation",
    de: "Anrede",
  },
  USERS_ADD_ROLE_FIELD_TITLE: {
    en: "Role",
    de: "Rolle",
  },
  USERS_ADD_ROLE_FIELD_ERROR: {
    en: "Role is required",
    de: "Rolle wird benötigt",
  },
  USERS_SALUATION_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "Mr" },
      { id: 2, name: "Mrs" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "Herr" },
      { id: 2, name: "Frau" },
    ]),
  },
  USERS_TITLES_OPTIONS: {
    en: JSON.stringify([
      { id: 0, name: "Select" },
      { id: 1, name: "Dr." },
      { id: 2, name: "Prof." },
      { id: 3, name: "Prof. Dr." },
    ]),
    de: JSON.stringify([
      { id: 0, name: "wähle" },
      { id: 1, name: "Dr." },
      { id: 2, name: "Prof." },
      { id: 3, name: "Prof. Dr." },
    ]),
  },
  USER_ROLE_TITLE: {
    en: "Role",
    de: "Rolle",
  },
  USER_ROLE_INFO: {
    en: "Two-factor authentication adds an extra layer of security to your account. To log in, in addition youll need to provide a 6 digit code.",
    de: "Die Zwei-Faktor-Authentifizierung fügt deinem Konto eine zusätzliche Sicherheitsebene hinzu. Um sich anzumelden, musst du zusätzlich einen 6-stelligen Code eingeben.",
  },
  USER_ROLE_ADD_BUTTON: {
    en: "Add role",
    de: "Rolle hinzufügen",
  },
  ADD_ROLE_MODAL_INFO: {
    en: "Please enter below details to create a role",
    de: "Bitte fülle alle Daten aus, um eine Rolle zu erstellen.",
  },
  USER_ROLE_COFIRM_MESSAGE: {
    en: "Are you sure you want remove this role?",
    de: "Bist du dir sicher, dass du die Rolle löschen möchtest?",
  },
  USER_ROLE_NO_ROLE_MESSAGE: {
    en: "No role added",
    de: "Keine Rolle hinzugefügt",
  },
  PARTNERS_TITLE: {
    en: "ORGANISATIONS",
    de: "Organisation",
  },
  PARTNERS_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  PARTNERS_ADD_NEW_PARTNER: {
    en: "Add New Organisations",
    de: "Neue Organisation hinzufügen",
  },
  PARTNERS_TABLE_COLUMN_NAME: {
    en: "Name",
    de: "Name",
  },
  PARTNERS_TABLE_COLUMN_ADDRESS: {
    en: "Address",
    de: "Adresse",
  },
  PARTNERS_TABLE_COLUMN_PHONE: {
    en: "Phone",
    de: "Telefon",
  },
  PARTNERS_TABLE_COLUMN_REMARKS: {
    en: "Remarks",
    de: "Bemerkungen",
  },
  PARTNERS_PARTNER_DETAILS_TITLE: {
    en: "Organisation Details",
    de: "Organisation",
  },
  PARTNERS_GENERAL_DETAILS_TITLE: {
    en: "General Details",
    de: "Details",
  },
  PARTNERS_CUSTOMERS_TITLE: {
    en: "Customers",
    de: "Kunden",
  },
  PARTNER_MANAGEMENT_ADD_SUCCESS_MESSAGE: {
    en: "Organisation added successfully!",
    de: "Organisation erfolgreich hinzugefügt!",
  },
  PARTNER_MANAGEMENT_ADD_FAILURE_MESSAGE: {
    en: "There was an issue creating partner, please try again!",
    de: "Beim Anlegen ist ein Fehler aufgetreten, bitte versuche es noch einmal!",
  },
  PARTNER_MANAGEMENT_DELETE_WARNING_MESSAGE: {
    en: "Are you sure you want to delete this organisation?",
    de: "Bist du sicher, dass du diese Organisation löschen möchtest?",
  },
  PARTNER_MANAGEMENT_DELETE_SUCCESS_MESSAGE: {
    en: "Organisation Deleted Successfully",
    de: "Organisation erfolgreich gelöscht",
  },
  PARTNER_MANAGEMENT_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete prganisation, please try again!",
    de: "Organisation kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  PARTNER_MANAGEMENT_UPDATE_SUCCESS_MESSAGE: {
    en: "Organisation Details saved successfully!",
    de: "Details über die Organisation erfolgreich gespeichert!",
  },
  PARTNER_MANAGEMENT_UPDATE_FAILURE_MESSAGE: {
    en: "Cannot save partner details, Please try again!",
    de: "Infos können nicht gespeichert werden, bitte versuche es erneut!",
  },
  PARTNERS_PROFILE_DETAILS_TITLE: {
    en: "Personal data",
    de: "Persönliche Daten",
  },
  PARTNERS_SUPPORT_DETAILS_TITLE: {
    en: "Support Details",
    de: "Supportdetails",
  },
  PARTNERS_SALES_DETAILS_TITLE: {
    en: "Sales Details",
    de: "Vertriebsdetails",
  },
  CUSTOMERS_TITLES_NAME: {
    en: "Customers",
    de: "Kunden",
  },
  CUSTOMERS_ADD_NEW_CUSTOMER_TITLE: {
    en: "Add New Customer",
    de: "Neuen Kunden hinzufügen",
  },
  CUSTOMERS_PARTNER_ADMIN_TITLE: {
    en: "Organisation Admin",
    de: "Organisation Admin",
  },
  CUSTOMERS_PARTNER_NAME_TITLE: {
    en: "Partner Name",
    de: "Name Partner",
  },
  CUSTOMERS_PARTNER_FORM_FIELD: {
    en: "Organisation",
    de: "Organisation",
  },
  CUSTOMERS_SELECT_PARTNER_TITLE: {
    en: "Select",
    de: "Bearbeiten",
  },
  CUSTOMERS_ADMINS_TITLE: {
    en: "Admins",
    de: "Admins",
  },
  CUSTOMERS_MAKE_AS_ADMIN_TITLE: {
    en: "Make as Admin",
    de: "Als Administrator festlegen",
  },
  CUSTOMERS_REMOVE_AS_ADMIN_TITLE: {
    en: "Remove as Admin",
    de: "Als Administrator entfernen",
  },
  CUSTOMERS_PARTNER_REQUIRED: {
    en: "Partner is required!",
    de: "Partner ist erforderlich!",
  },
  CUSTOMERS_ADDED_SUCCESS_MESSAGE: {
    en: "Customer Details saved successfully!",
    de: "Kundendetails erfolgreich gespeichert!",
  },
  CUSTOMERS_ADDED_FAILURE_MESSAGE: {
    en: "Cannot save customer details, Please try again!",
    de: "Kundendetails können nicht gespeichert werden, bitte versuche es erneut!",
  },
  CUSTOMERS_EMAIL_CHANGE_SUCCESS_MESSAGE: {
    en: "Customer email changed successfully!",
    de: "Kunden-E-Mail erfolgreich geändert!",
  },
  CUSTOMERS_EMAIL_CHANGE_FAILURE_MESSAGE: {
    en: "Customer email change failed!",
    de: "Änderung der Kunden-E-Mail fehlgeschlagen!",
  },
  CUSTOMERS_PASSWORD_CHANGE_SUCCESS_MESSAGE: {
    en: "Customer password changed successfully!",
    de: "Kundenpasswort erfolgreich geändert!",
  },
  CUSTOMERS_PASSWORD_CHANGE_FAILURE_MESSAGE: {
    en: "Customer password change failed!",
    de: "Änderung des Kundenpassworts fehlgeschlagen!",
  },
  CUSTOMERS_ADD_SUCCESS_MESSAGE: {
    en: "Customer added successfully!",
    de: "Kunde erfolgreich hinzugefügt!",
  },
  CUSTOMERS_ADD_FAILURE_MESSAGE: {
    en: "There was an issue creating customer, please try again!",
    de: "Beim Erstellen des Kunden ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  CUSTOMERS_DELETE_SUCCESS_MESSAGE: {
    en: "Customer Deleted Successfully",
    de: "Kunde erfolgreich gelöscht",
  },
  CUSTOMERS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete customer, please try again!",
    de: "Kunde kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  CUSTOMERS_DELETE_WARNING_MESSAGE: {
    en: "Are you sure you want to delete this customer?",
    de: "Bist du sicher, dass du diesen Kunden löschen möchtest?",
  },
  CUSTOMERS_PARTNER_ADMIN_ADD_SUCCESS_MESSAGE: {
    en: "Customer added as admin successfully!",
    de: "Benutzer erfolgreich als Administrator hinzugefügt!",
  },
  CUSTOMERS_PARTNER_ADMIN_ADD_FAILURE_MESSAGE: {
    en: "There was an issue adding customer as admin, please try again!",
    de: "Beim Hinzufügen des Benutzers als Administrator ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  CUSTOMERS_PARTNER_ADMIN_REMOVE_SUCCESS_MESSAGE: {
    en: "Customer removed as admin successfully!",
    de: "Benutzer erfolgreich als Administrator entfernt!",
  },
  CUSTOMERS_PARTNER_ADMIN_REMOVE_FAILURE_MESSAGE: {
    en: "There was an issue removing customer as admin, please try again!",
    de: "Beim Entfernen des Benutzers als Administrator ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  CUSTOMERS_CUSTOMER_FORM_FIELD: {
    en: "Customer",
    de: "Kunde",
  },
  CUSTOMERS_SELECT_CUSTOMER_TITLE: {
    en: "Select Customer",
    de: "Kunden auswählen",
  },
  PROJECT_ASSIGNED_TO_TTILE_USER: {
    en: "Assigned to User",
    de: "Benutzer zugewiesen",
  },
  PARTNER_UPDATED_TOAST: {
    en: "Partner Updated Successfully",
    de: "Partner erfolgreich aktualisiert",
  },
  CUSTOMER_UPDATED_TOAST: {
    en: "Customer Updated Successfully",
    de: "Kunde erfolgreich aktualisiert",
  },
  PARTNER_FAILURE_MESSAGE: {
    en: "Can not update Partner details ! please try again!",
    de: "Partnerdetails können nicht aktualisiert werden, bitte versuche es erneut!",
  },
  PARTNER_ADD_TITLE: {
    en: "Please select the Partner",
    de: "Bitte wähle einen Partner",
  },
  CUSTOMER_ADD_TITLE: {
    en: "Please select the Customer",
    de: "Bitte wähle einen Kunden",
  },
  ROLES_AND_PERMISSIONS_TITLE: {
    en: "USER ROLES",
    de: "BENUTZERROLLEN",
  },
  ROLES_AND_PERMISSIONS_EDIT_ROLE_TITLE: {
    en: "Edit role",
    de: "Rolle bearbeiten",
  },
  ROLES_PERMISSIONS_ADD_NEW_ROLE_TITLE: {
    en: "Add New Role",
    de: "Neue Rolle hinzufügen",
  },
  ROLES_PERMISSIONS_EDIT_ROLE_TITLE: {
    en: "Edit Role",
    de: "Rolle bearbeiten",
  },
  ROLES_PERMISSIONS_ASSIGN_TO_PARTNER_TITLE: {
    en: "Assign to Partner",
    de: "Partner zuweisen",
  },
  ROLES_PERMISSIONS_ADD_ROLE_SUCCESS_MESSAGE: {
    en: "Role created successfully.",
    de: "Rolle erfolgreich erstellt.",
  },
  ROLES_PERMISSIONS_ADD_ROLE_FAILURE_MESSAGE: {
    en: "There was an issue in creating role, please try again.",
    de: "Beim Erstellen der Rolle ist ein Fehler aufgetreten, bitte versuche es erneut.",
  },
  ROLES_PERMISSIONS_UPDATE_ROLE_SUCCESS_MESSAGE: {
    en: "Role updated successfully.",
    de: "Rolle erfolgreich aktualisiert.",
  },
  ROLES_PERMISSIONS_UPDATE_ROLE_FAILURE_MESSAGE: {
    en: "There was an issue in updating role, please try again.",
    de: "Beim Aktualisieren der Rolle ist ein Fehler aufgetreten, bitte versuche es erneut.",
  },
  ROLES_PERMISSIONS_DELETE_ROLE_SUCCESS_MESSAGE: {
    en: "Role deleted Successfully.",
    de: "Rolle erfolgreich gelöscht.",
  },
  ROLES_PERMISSIONS_DELETE_ROLE_FAILURE_MESSAGE: {
    en: "There was an issue in deleting role, please try again.",
    de: "Beim Löschen der Rolle ist ein Fehler aufgetreten, bitte versuche es erneut.",
  },
  ROLES_PERMISSIONS_ADD_PARTNERS_TO_ROLE_SUCCESS_MESSAGE: {
    en: "Organisations are added successfully to the role.",
    de: "Organisationen wurden erfolgreich der Rolle hinzugefügt.",
  },
  ROLES_PERMISSIONS_ADD_PARTNERS_TO_ROLE_FAILURE_MESSAGE: {
    en: "There was an issue in adding organisations to the role, please try again.",
    de: "Beim Hinzufügen von Organisationen zur Rolle ist ein Fehler aufgetreten, bitte versuche es erneut.",
  },
  FILEMANAGER_CREATE_FOLDER_MESSAGE: {
    en: "Folder Created Successfully!",
    de: "Der Ordner wurde erfolgreich erstellt.",
  },
  FILEMANAGER_CREATE_FOLDER_FAILURE_MESSAGE: {
    en: "There was an error creating folder",
    de: "Der Ordner wurde nicht erstellt, bitte versuche es noch mal!",
  },
  FILEMANAGER_UPDATE_FOLDER_MESASGE: {
    en: "Folder updated successfully!",
    de: "Der Ordner wurde erfolgreich aktualisiert!",
  },
  FILEMANAGER_UPDATE_FOLDER_FAILURE_MESSAGE: {
    en: "There was an error editing folder!",
    de: "Der Ordner konnte nicht aktualisiert werden, bitte versuche es noch mal!",
  },
  FILEMANAGER_EDIT_FILE_MESSAGE: {
    en: "File edited successfully!",
    de: "Die Datei wurde erfolgreich aktualisiert!",
  },
  FILEMANAGER_EDIT_FILE_FAILURE_MESSAGE: {
    en: "There was an error renaming file!",
    de: "Die Datei konnte nicht aktualisiert werden, bitte versuche es noch mal!",
  },
  FILEMANAGER_BROWSER_PDF_MESSAGE: {
    en: "Your browser does not support PDFs.",
    de: "Dein Browser unterstützt keine PDF Dateien",
  },
  FILEMANAGER_DOWNLOAD_PDF_BUTTON: {
    en: "Download the PDF",
    de: "PDF herunterladen",
  },
  FILEMANAGER_BROWSER_AUDIO_MESSAGE: {
    en: "Your browser does not support the audio.",
    de: "Dein Browser unterstützt keine Audio Dateien.",
  },
  FILEMANAGER_BROWSER_VIDEO_MESSAGE: {
    en: "Your browser does not support the video.",
    de: "Dein Browser unterstützt keine Video Dateien.",
  },
  FILEMANAGER_BROWSER_DISPLAY_FAILURE_MESSAGE: {
    en: "Unable to display file",
    de: "Fehler beim abspielen des Videos",
  },
  FILEMANAGER_EDIT_FILE_COPYRIGHT_FIELD_TITLE: {
    en: "CopyRight nextlevel GmbH",
    de: "Copyright nextlevel GmbH",
  },
  FILEMANAGER_EDIT_FILE_FILENAME_FIELD_TITLE: {
    en: "File name",
    de: "Dateiname",
  },
  FILEMANAGER_EDIT_FILE_FOLDERNAME_FIELD_TITLE: {
    en: "Folder name",
    de: "Ordnername",
  },
  FILEMANAGER_EDIT_FILE_FILENAME_PLACEHOLDER: {
    en: "Enter File name",
    de: "Gib einen Dateinamen ein",
  },
  FILEMANAGER_EDIT_FILE_FOLDERNAME_PLACEHOLDER: {
    en: "Enter Folder name",
    de: "Gib bitte einen Ordnernamen ein",
  },
  FILEMANAGER_EDIT_FILE_COPYRIGHT_PLACEHOLDER: {
    en: "Copyright",
    de: "Copyright",
  },
  FILEMANAGER_EDIT_MODAL_CLOSE_BUTTON: {
    en: "Close",
    de: "Schließen",
  },
  FILEMANAGER_EDIT_MODAL_SUBMIT_BUTTON: {
    en: "Submit",
    de: "Absenden",
  },
  FILEMANAGER_EDIT_MODAL_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  FILEMANAGER_EDIT_FILE_REQUIRED_MESSAGE: {
    en: "File name is required",
    de: "Dateiname ist erforderlich",
  },
  FILEMANAGER_EDIT_FOLDER_FORMAT_MESSAGE: {
    en: "Enter valid folder name",
    de: "Gib bitte einen gültigen Ordernamen ein!",
  },
  FILEMANAGER_EDIT_FOLDER_REQUIRED_MESSAGE: {
    en: "Folder name is required",
    de: "Ordnername ist erforderlich",
  },
  FILEMANAGER_EDIT_FILE_COPYRIGHT_REQUIRED_MESSAGE: {
    en: "Copyright text is required",
    de: "Copyright ist erforderlich",
  },
  FILEMANAGER_FIELD_60SYBMOLS_REQUIRED_MESSAGE: {
    en: "Maximum 60 symbols",
    de: "Maximal 60 Zeichen",
  },
  FILEMANAGER_FIELD_120SYBMOLS_REQUIRED_MESSAGE: {
    en: "Maximum 120 symbols",
    de: "Maximal 120 Zeichen",
  },
  FILEMANAGER_UPLOAD_FILE_MESSAGE: {
    en: "File(s) uploaded successfully!",
    de: "Datei(en) erfolgreich hochgeladen!",
  },
  FILEMANAGER_UPLOAD_FILE_FAILURE_MESSAGE: {
    en: "File(s) upload failed!",
    de: "Datei(en) nicht erfolgreich hochgeladen!",
  },
  FILEMANAGER_UPLOAD_FILE_TITLE: {
    en: "Upload Files",
    de: "Dateien hochladen",
  },
  FILEMANAGER_OPTIMIZE_SUCCESS_MESSAGE: {
    en: "Optimizing file, Please wait",
    de: "Die Datei wird optimiert, bitte warten.",
  },
  FILEMANAGER_OPTIMIZE_ERROR_MESSAGE: {
    en: "There was an issue optimizing this file, Pelase try again!",
    de: "Beim optimieren der Dateien ist ein Fehler aufgetreten!",
  },
  FILEMANAGER_UPLOAD_FILE_ATTACH_FILES: {
    en: "Attach Files",
    de: "Dateien auswählen",
  },
  FILEMANAGER_UPLOAD_FILE_REMOVE_ALL: {
    en: "Remove All",
    de: "Alle entfernen",
  },
  FILEMANAGER_UPLOAD_FILE_MODAL_CLOSE_BUTTON: {
    en: "Close",
    de: "Schließen",
  },
  FILEMANAGER_UPLOAD_FILE_MODAL_UPLOAD_BUTTON: {
    en: "Upload",
    de: "Hochladen",
  },
  FILEMANAGER_UPLOAD_FILE_MODAL_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  FILEMANAGER_SEARCH_PLACEHOLDER: {
    en: "Search Files & Folders",
    de: "Suchen",
  },
  FILEMANAGER_APPLY_BUTTON: {
    en: "Apply",
    de: "Übernehmen",
  },
  FILEMANAGER_DELETE_MESSAGE: {
    en: "Are you sure you want to delete selected files or folders?",
    de: "Möchtest du wirklich die ausgewählten Dateien oder Ordner löschen?",
  },
  FILEMANAGER_DELETE_BUTTON: {
    en: "Delete",
    de: "Löschen",
  },
  FILEMANAGER_NEW_FOLDER_TITLE: {
    en: "New Folder",
    de: "Neuer Ordner",
  },
  FILEMANAGER_UPLOAD_FILES_TITLE: {
    en: "Upload Files",
    de: "Dateien hochladen",
  },
  FILEMANAGER_TABLE_COLUMN1_TITLE: {
    en: "Name",
    de: "Name",
  },
  FILEMANAGER_TABLE_COLUMN2_TITLE: {
    en: "Type",
    de: "Typ",
  },
  FILEMANAGER_TABLE_COLUMN3_TITLE: {
    en: "Size",
    de: "Größe",
  },
  FILEMANAGER_TABLE_COLUMN4_TITLE: {
    en: "Optimized",
    de: "Optimiert",
  },
  FILEMANAGER_ACTIONS_DROPDOWN_OPTIMIZE: {
    en: "Optimize",
    de: "Optimieren",
  },
  FILEMANAGER_NEW_FILE_UPLOAD_MESSAGE: {
    en: "Start creating new folders or uploading a new file!",
    de: "Fange an einen neuen Ordner zu erstellen und Dateien hochzuladen!",
  },
  FILEMANAGER_DELETE_SUCCESS_MESSAGE: {
    en: "File deleted successfully!",
    de: "Die Datei wurde erfolgreich gelöscht!",
  },
  FILEMANAGER_DELETE_FAILURE_MESSAGE: {
    en: "There was an error deleting this file!",
    de: "Die Datei konnte nicht gelöscht werden, bitte versuche es noch mal!",
  },
  FILEMANAGER_FOLDER_DELETE_SUCCESS_MESSAGE: {
    en: "File deleted successfully!",
    de: "Der Ordner wurde erfolgreich gelöscht!",
  },
  FILEMANAGER_FOLDER_DELETE_FAILURE_MESSAGE: {
    en: "There was an error deleting this file!",
    de: "Der Ordner konnte nicht gelöscht werden, bitte versuche es noch mal!",
  },
  FILEMANAGER_EDIT_BUTTON: {
    en: "Edit",
    de: "Bearbeiten",
  },
  DOWNLOAD: {
    en: "Download",
    de: "Download",
  },
  DOWNLOAD_ZIP: {
    en: "Download ZIP",
    de: "Download ZIP",
  },
  FILEMANAGER_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete this folder?",
    de: "Möchtest du wirklich den Ordner löschen?",
  },
  FILEMANAGER_FILE_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete this file?",
    de: "Möchtest du wirklich diese Datei löschen?",
  },
  FILEMANAGER_DOWNLOAD_SUCCESS_MESSAGE: {
    en: "Zip of files or Folders downloaded successfully!",
    de: "Die ZIP-Datei wurde erfolgreich heruntergeladen!",
  },
  FILEMANAGER_DOWNLOAD_ERROR_MESSAGE: {
    en: "Zip of files or Folders download Failed!",
    de: "Beim herunterladen der ZIP-Datei ist ein Fehler aufgetreten!",
  },
  FILEMANAGER_MASS_DELETE_MESSAGE: {
    en: "files or Folders deleted successfully!",
    de: "Die Dateien oder Ordner wurden erfolgreich gelöscht!",
  },
  FILEMANAGER_MASS_DELETE_FAILURE_MESSAGE: {
    en: "files or Folders deleted Failed!",
    de: "Die Dateien oder Ordner wurden nicht erfolgreich gelöscht, bitte versuche es noch mal!",
  },
  FILEMANAGER_FILE_LINK_COPY_SUCCESS_MESSAGE: {
    en: "Copied to cliboard!",
    de: "Der Link wurde erfolgreich in die Zwischenablage kopiert.",
  },
  FILEMANAGER_FILE_SHARE_LINK: {
    en: "Link to copy",
    de: "Link zum kopieren",
  },
  FILEMANAGER_FILE_SHARE_LINK_GENERATING: {
    en: "Generating share link...",
    de: "Der Link wird generiert...",
  },
  FILEMANAGER_OPTIMIZATION_ERROR_MESSAGE: {
    en: "There was an issue optimizing this file, Please try again!",
    de: "Beim Optimieren dieser Datei ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  FILEMANAGER_MOVE_SUCCESS_MESSAGE: {
    en: "Moved Successfully!",
    de: "Erfolgreich verschoben!",
  },
  FILEMANAGER_MOVE_ERROR_MESSAGE: {
    en: "Moved Successfully!",
    de: "Erfolgreich verschoben!",
  },
  FILEMANAGER_MOVE_FOLDER_TITLE: {
    en: "Move Folder",
    de: "Ordner verschieben",
  },
  FILEMANAGER_MOVE_BUTTON_TITLE: {
    en: "Move",
    de: "Verschieben",
  },
  FILEMANAGER_OPTIMIZE_BUTTON_TITLE: {
    en: "Optimize",
    de: "Optimieren",
  },
  FILEMANAGER_MOVE_SELECT_DESTINATION_FOLDER_TITLE: {
    en: "Select Destination Folder",
    de: "Zielordner auswählen",
  },
  ITEMS: {
    en: "items",
    de: "Einträge",
  },
  SELECTED: {
    en: "selected",
    de: "ausgewählt",
  },
  FILEMANAGER_TITLE: {
    en: "FILE MANAGER",
    de: "Dateien",
  },
  ROUTES_FILEMANAGER_OVERVIEW: {
    en: "Overview",
    de: "Übersicht",
  },
  COMMON_EDIT: {
    en: "Edit",
    de: "Bearbeiten",
  },
  COMMON_DELETE: {
    en: "Delete",
    de: "Löschen",
  },
  COMMON_SUCCESS: {
    en: "Success",
    de: "Erfolg",
  },
  COMMON_YES_DELETE: {
    en: "Yes, delete",
    de: "Ja, löschen",
  },
  COMMON_YES_RESTORE: {
    en: "Yes, restore",
    de: "Ja, wiederherstellen",
  },
  WEBSITE_GENERAL_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  WEBSITE_META_DETAILS_SELECT_FILE_MODAL_TITLE: {
    en: "Select File",
    de: "Dateien auswählen",
  },
  RESOURCE_PERMISSION_TYPES: {
    en: JSON.stringify([
      { id: 1, name: "Read", slug: "read" },
      { id: 2, name: "Create", slug: "create" },
      { id: 3, name: "Write", slug: "write" },
      { id: 4, name: "Delete", slug: "delete" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "Lesen", slug: "read" },
      { id: 2, name: "Erstellen", slug: "create" },
      { id: 3, name: "Schreiben", slug: "write" },
      { id: 4, name: "Löschen", slug: "delete" },
    ]),
  },
  ROLE_RESOURCE_PERMISSIONS_TITLE: {
    en: "Resources & permissions",
    de: "Zugriffsberechtigungen",
  },
  ROLE_RESOURCE_PERMISSIONS_EDIT_ROLE: {
    en: "Edit Role",
    de: "Rolle bearbeiten",
  },
  ADD_ROLE_MODAL_FIELD_NAME: {
    en: "Role name",
    de: "Rollen Name",
  },
  ADD_ROLE_MODAL_FIELD_NAME_ERROR: {
    en: "Role name is required",
    de: "Name ist ein Pflichtfeld",
  },
  ADD_ROLE_SUCCESS_MESSAGE: {
    en: "Role created successfully for User!",
    de: "Die Benutzerrolle wurde erfolgreich aktualisiert!",
  },
  ADD_ROLE_FAILURE_MESSAGE: {
    en: "There was an issue adding this resource, Please try again!",
    de: "Beim erstellen der Rolle ist ein Fehler aufgetreten, bitte versuche es noch mal!",
  },
  ROLES_PERMISSIONS_ROLE_NAME: {
    en: "Role Name",
    de: "Rollen Name",
  },
  ROLES_PERMISSIONS_HEADER: {
    en: "Role Permissions",
    de: "Rollen Berechtigung",
  },
  ROLES_PERMISSIONS_SUPER_ADMIN_ACCESS: {
    en: "Super Administrator Access",
    de: "Super Administrator Zugang",
  },
  ROLES_PERMISSIONS_EDIT_ROLE_NAME_VALIDATION: {
    en: "Name is required",
    de: "Bitte gib einen Namen ein",
  },
  ROLES_PERMISSIONS_UPDATE_SUCCESS_MESSAGE: {
    en: "Permissions saved successfully!",
    de: "Berechtigungen erfolgreich gespeichert!",
  },
  ROLES_PERMISSIONS_UPDATE_ERROR_MESSAGE: {
    en: "There was an issue saving, please try again!",
    de: "Beim Speichern ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  ROLES_PERMISSIONS_NEW_SECTOR_TITLE: {
    en: "New sector",
    de: "Neuer Sektor",
  },
  ROLES_PERMISSIONS_ROLE_DELETE_CONFIRMATION_MESSAGE: {
    en: "Are you sure you want to delete this role?",
    de: "Bist du sicher, dass du diese Rolle löschen möchtest?",
  },
  ROLES_PERMISSIONS_ROLE_DELETE_SUCCESS_MESSAGE: {
    en: "Role deleted successfully",
    de: "Rolle erfolgreich gelöscht",
  },
  ROLES_PERMISSIONS_ROLE_DELETE_ERROR_MESSAGE: {
    en: "There was an issue deleting this role, Please try again!",
    de: "Beim Löschen dieser Rolle ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  ADD_RESOURCE_MODAL_TITLE: {
    en: "Create new resource",
    de: "Ressource erstellen",
  },
  ADD_RESOURCE_MODAL_FIELD_NAME: {
    en: "Resource name",
    de: "Ressourcenname",
  },
  RESOURCES_TYPES: {
    en: JSON.stringify([
      {
        id: 1,
        name: "Users",
        slug: "users",
      },
      {
        id: 2,
        name: "Industries & Sectors",
        slug: "industries_sectors",
      },
      {
        id: 3,
        name: "Labels",
        slug: "labels",
      },
      {
        id: 4,
        name: "Roles and Permissions",
        slug: "roles-and-permissions",
      },
      {
        id: 5,
        name: "Organisations",
        slug: "partners",
      },
      {
        id: 6,
        name: "File Manager",
        slug: "file-manager",
      },
      {
        id: 7,
        name: "Contacts",
        slug: "contacts",
      },

      {
        id: 11,
        name: "Teams",
        slug: "teams",
      },
      {
        id: 12,
        name: "Tabs",
        slug: "tabs",
      },
      {
        id: 13,
        name: "Products",
        slug: "products",
      },
      {
        id: 14,
        name: "Products Activities",
        slug: "products-activities",
      },
      {
        id: 15,
        name: "Fields",
        slug: "fields",
      },
      {
        id: 16,
        name: "Pipelines",
        slug: "pipelines",
      },
      {
        id: 17,
        name: "Pipelines Phases",
        slug: "pipelines-phases",
      },
      {
        id: 18,
        name: "Google",
        slug: "google",
      },
      {
        id: 19,
        name: "Deals",
        slug: "deals",
      },
      {
        id: 20,
        name: "Groups",
        slug: "groups",
      },
      {
        id: 21,
        name: "Product Categories",
        slug: "categories",
      },
      {
        id: 22,
        name: "Tags",
        slug: "tags",
      },
      {
        id: 23,
        name: "Deals Logs",
        slug: "deals-logs",
      },
      {
        id: 24,
        name: "Products Types",
        slug: "products-types",
      },
      {
        id: 25,
        name: "Contacts Type",
        slug: "contacts-types",
      },
      {
        id: 26,
        name: "Contacts Titles",
        slug: "contact-titles",
      },
      {
        id: 27,
        name: "Notes",
        slug: "notes",
      },
      {
        id: 28,
        name: "Tasks",
        slug: "tasks",
      },
      {
        id: 29,
        name: "System Messages",
        slug: "system-messages",
      },
      {
        id: 31,
        name: "Archived Deals",
        slug: "archived-deals",
      },
    ]),
    de: JSON.stringify([
      {
        id: 1,
        name: "Benutzer",
        slug: "users",
      },
      {
        id: 2,
        name: "Branchensektoren",
        slug: "industries_sectors",
      },
      {
        id: 3,
        name: "Labels",
        slug: "labels",
      },
      {
        id: 4,
        name: "Rollen und Zugriffe",
        slug: "roles-and-permissions",
      },
      {
        id: 5,
        name: "Organisation",
        slug: "partners",
      },
      {
        id: 6,
        name: "Dateimanager",
        slug: "file-manager",
      },
      {
        id: 7,
        name: "Kontakte",
        slug: "contacts",
      },

      {
        id: 11,
        name: "Teams",
        slug: "teams",
      },
      {
        id: 12,
        name: "Reiter",
        slug: "tabs",
      },
      {
        id: 13,
        name: "Produkte",
        slug: "products",
      },
      {
        id: 14,
        name: "Lagerverwaltung",
        slug: "products-activities",
      },
      {
        id: 15,
        name: "Feld",
        slug: "fields",
      },
      {
        id: 16,
        name: "Pipelines",
        slug: "pipelines",
      },
      {
        id: 17,
        name: "Archivierte Deals",
        slug: "archived-deals",
      },
      {
        id: 18,
        name: "Google",
        slug: "google",
      },
      {
        id: 19,
        name: "Pipeline Phasen",
        slug: "deals",
      },
      {
        id: 20,
        name: "Gruppen",
        slug: "groups",
      },
      {
        id: 21,
        name: "Produkt Kategorien",
        slug: "categories",
      },
      {
        id: 22,
        name: "Tags",
        slug: "tags",
      },
      {
        id: 23,
        name: "Deal Logs",
        slug: "deals-logs",
      },
      {
        id: 24,
        name: "Produktarten",
        slug: "products-types",
      },
      {
        id: 25,
        name: "Kontaktarten",
        slug: "contacts-types",
      },
      {
        id: 26,
        name: "Kontakttitel",
        slug: "contact_titles",
      },
      {
        id: 27,
        name: "Notizen",
        slug: "notes",
      },
      {
        id: 28,
        name: "Aufgaben",
        slug: "tasks",
      },
      {
        id: 29,
        name: "Systemnachrichten",
        slug: "system-messages",
      },
      {
        id: 31,
        name: "",
        slug: "archived-deals",
      },
    ]),
  },
  ADD_RESOURCE_MODAL_FIELD_NAME_ERROR: {
    en: "Resource name is required",
    de: "Ressourcen Name ist ein Pflichtfeld",
  },
  ADD_RESOURCE_SUCCESS_MESSAGE: {
    en: "Resource added successfully!",
    de: "Die Ressource wurde erfolgreich hinzugefügt!",
  },
  ADD_RESOURCE_FAILURE_MESSAGE: {
    en: "There was an issue trying to add resource, Please try again!",
    de: "Beim hinzufügen der Ressource ist ein Fehler aufgetreten, bitte versuche es noch mal!",
  },
  EDIT_RESOURCE_MODAL_TITLE: {
    en: "Edit resource",
    de: "Ressource bearbeiten",
  },
  ADD_RESOURCE_MODAL_INFO: {
    en: "Please enter below details to create a resource",
    de: "Bitte fülle alle Felder aus, um eine Ressource zu erstellen.",
  },
  EDIT_RESOURCE_MODAL_INFO: {
    en: "Please enter below details to edit resource",
    de: "Bitte fülle alle Felder aus, um eine Ressource zu aktualisieren.",
  },
  EDIT_RESOURCE_SUCCESS_MESSAGE: {
    en: "Resource edited successfully!",
    de: "Die Ressource wurde erfolgreich aktualisiert!",
  },
  EDIT_RESOURCE_FAILURE_MESSAGE: {
    en: "There was an issue editing this resource, Please try again!",
    de: "Beim aktualisieren der Ressource ist ein Fehler aufgetreten, bitte versuche es noch mal!",
  },
  ROLES_PERMISSIONS_SELECT_ALL: {
    en: "Select all",
    de: "Alle auswählen",
  },

  MENU_MASTERDATA: {
    en: "EDITOR",
    de: "STAMMDATEN",
  },
  MENU_CATEGORIES: {
    en: "PRODUCT CATEGORIES",
    de: "PRODUKT KATEGORIE",
  },
  MENU_GROUPS: {
    en: "GROUPS",
    de: "GRUPPEN",
  },
  MENU_TAGS: {
    en: "TAGS",
    de: "TAGS",
  },
  MENU_CONTACTTYPES: {
    en: "CONTACT TYPES",
    de: "KONTAKTDETAILS",
  },
  MENU_LABELS: {
    en: "LABELS DEALS",
    de: "LABELS DEALS",
  },
  MASTERDATA_INDUSTRIESSECTORS_TITLE: {
    en: "SECTORS",
    de: "ABSCHNITTE",
  },
  MASTERDATA_CATEGORIES_CREATE_SUCCESS_MESSAGE: {
    en: "Category Created Successfully!",
    de: "Die Kategorie wurde erfolgreich erstellt!",
  },
  MASTERDATA_CATEGORIES_CREATE_FAILURE_MESSAGE: {
    en: "Cannot create category, Please try again!",
    de: "Fehler beim erstellen der Kategorie, bitte versuche es noch mal!",
  },
  MASTERDATA_CATEGORIES_EDIT_SUCCESS_MESSAGE: {
    en: "Category name edited successfully!",
    de: "Die Kategorie wurde erfolgreich aktualisiert!",
  },
  MASTERDATA_CATEGORIES_EDIT_FAILURE_MESSAGE: {
    en: "Cannot edit category, Please try again!",
    de: "Fehler beim aktualisieren der Kategorie, bitte versuche es noch mal!",
  },
  MASTERDATA_EDIT_CATEGORY_TITLE: {
    en: "Edit category",
    de: "Kategorie bearbeiten",
  },
  MASTERDATA_CREATE_NEW_CATEGORY_TITLE: {
    en: "Create new category",
    de: "Kategorie hinzufügen",
  },
  MASTERDATA_CATEGORY_NAME_FIELD_NAME: {
    en: "Name",
    de: "Name",
  },
  MASTERDATA_CATEGORY_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  MASTERDATA_CATEGORY_DELETE_SUCCESS_MESSAGE: {
    en: "Category deleted successfully!",
    de: "Die Kategorie wurde erfolgreich gelöscht!",
  },
  MASTERDATA_CATEGORY_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete category, Please try again!",
    de: "Fehler beim löschen der Kategorie, bitte versuche es noch mal!",
  },
  MASTERDATA_CATEGORY_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Categories deleted successfully!",
    de: "Die Kategorie wurde erfolgreich gelöscht!",
  },
  MASTERDATA_CATEGORY_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete categories, Please try again!",
    de: "Fehler beim löschen der Kategorie, bitte versuche es noch mal!",
  },
  MASERDATA_CATEGORY_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete this Category?",
    de: "Möchtest du wirklich die Kategorie löschen?",
  },
  MASTERDATA_CATEGORY_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  MASTERDATA_CATEGORY_SELECTED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete selected Categories?",
    de: "Alle ausgewählten Katagorien löschen?",
  },
  MASTERDATA_LABEL_DELETE_SUCCESS_MESSAGE: {
    en: "Label deleted successfully!",
    de: "Etikett erfolgreich gelöscht!",
  },
  MASTERDATA_LABEL_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete label, Please try again!",
    de: "Etikett kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_LABEL_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Labels deleted successfully!",
    de: "Kategorien erfolgreich gelöscht!",
  },
  MASTERDATA_LABEL_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete labels, Please try again!",
    de: "Etiketten können nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_LABEL_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure, you want to delete this label?",
    de: "Bist du sicher, dass du dieses Etikett löschen möchtest?",
  },
  MASTERDATA_LABEL_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  MASTERDATA_LABEL_SELECTED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete selected labels?",
    de: "Bist du sicher, dass du die ausgewählten Etiketten löschen möchtest?",
  },
  MASTERDATA_LABELS_FORM_NAME_REQUIRED: {
    en: "Label name is required",
    de: "Etikettenname ist erforderlich",
  },
  MASTERDATA_LABEL_CREATE_SUCCESS_MESSAGE: {
    en: "Label is created successfully!",
    de: "Etikett wurde erfolgreich erstellt!",
  },
  MASTERDATA_LABEL_CREATE_FAILURE_MESSAGE: {
    en: "Cannot create label, please try again!",
    de: "Etikett kann nicht erstellt werden, bitte versuche es erneut!",
  },
  MASTERDATA_LABEL_EDIT_SUCCESS_MESSAGE: {
    en: "Label is updated successfully!",
    de: "Etikett wurde erfolgreich aktualisiert!",
  },
  MASTERDATA_LABEL_EDIT_FAILURE_MESSAGE: {
    en: "Cannot update label, please try again!",
    de: "Etikett kann nicht aktualisiert werden, bitte versuche es erneut!",
  },
  MASTERDATA_EDIT_LABEL_TITLE: {
    en: "Edit label",
    de: "Etikett bearbeiten",
  },
  MASTERDATA_LABEL_NAME_FIELD_NAME: {
    en: "Label name",
    de: "Etikettenname",
  },
  MASTERDATA_CREATE_NEW_LABEL_TITLE: {
    en: "Create new label",
    de: "Neues Etikett erstellen",
  },
  MASTERDATA_LABEL_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  MASTERDATA_CREATE_NEW_AUTHOR_BUTTON_TITLE: {
    en: "Create author",
    de: "Autor hinzufügen",
  },
  MASTERDATA_CREATE_NEW_CATEOGRY_BUTTON: {
    en: "Create new Category",
    de: "Kategorie hinzufügen",
  },
  MASTERDATA_CREATE_NEW_LABEL_BUTTON: {
    en: "Create new Label",
    de: "Neues Etikett erstellen",
  },
  MASTERDATA_TAGS_CREATE_SUCCESS_MESSAGE: {
    en: "Tag Created Successfully!",
    de: "Das Stichwort wurde erfolgreich erstellt!",
  },
  MASTERDATA_TAGS_CREATE_FAILURE_MESSAGE: {
    en: "Cannot create tag, Please try again!",
    de: "Fehler beim Erstellen des Stichworts, bitte versuche es noch mal!",
  },
  MASTERDATA_TAGS_EDIT_SUCCESS_MESSAGE: {
    en: "Tag name updated successfully!",
    de: "Das Stichwort wurde erfolgreich aktualisiert!",
  },
  MASTERDATA_TAGS_EDIT_FAILURE_MESSAGE: {
    en: "Cannot edit tag, Please try again!",
    de: "Fehler beim aktualisieren des Stichworts, bitte versuche es noch mal!",
  },
  MASTERDATA_TAGS_EDIT_TITLE: {
    en: "Edit tag",
    de: "Stichwort bearbeiten",
  },
  MASTERDATA_TAGS_EDIT_NEW_TITLE: {
    en: "Create new tag",
    de: "Neuen Tag erstellen",
  },
  MASTERDATA_TAGS_TAG_NAME_FIELD_NAME: {
    en: "Name",
    de: "Name",
  },
  MASTERDATA_TAGS_TAG_NAME_PLACEHOLDER: {
    en: "Enter tag name",
    de: "Stichwort eingeben",
  },
  MASTERDATA_TAGS_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  MASTERDATA_TAGS_DELETE_SUCCESS_MESSAGE: {
    en: "Tag deleted successfully!",
    de: "Stichwort erfolgreich gelöscht!",
  },
  MASTERDATA_TAGS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete tag, Please try again!",
    de: "Stichwort kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_TAGS_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Tag(s) deleted successfully!",
    de: "Stichwort/Stichwörter erfolgreich gelöscht!",
  },
  MASTERDATA_TAGS_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete tag(s), Please try again!",
    de: "Stichwort/Stichwörter können nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_TAGS_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete this Tag?",
    de: "Bist du sicher, dass du dieses Stichwort löschen möchtest?",
  },
  MASTERDATA_TAGS_SEARCH_PLACEHOLDER: {
    en: "Search Tags",
    de: "Stichwörter durchsuchen",
  },
  MASTERDATA_TAGS_SELECTED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete selected Tag(s)?",
    de: "Bist du sicher, dass du die ausgewählten Stichwörter löschen möchtest?",
  },
  MASTERDATA_TAGS_CREATE_NEW_BUTTON: {
    en: "Create new Tag",
    de: "Neues Stichwort erstellen",
  },
  MASTERDATA_GROUPS_CREATE_SUCCESS_MESSAGE: {
    en: "Group Created Successfully!",
    de: "Gruppe erfolgreich erstellt!",
  },
  MASTERDATA_GROUPS_CREATE_FAILURE_MESSAGE: {
    en: "Cannot create group, Please try again!",
    de: "Gruppe kann nicht erstellt werden, bitte versuche es erneut!",
  },
  MASTERDATA_GROUPS_EDIT_SUCCESS_MESSAGE: {
    en: "Group name edited successfully!",
    de: "Gruppenname erfolgreich bearbeitet!",
  },
  MASTERDATA_GROUPS_EDIT_FAILURE_MESSAGE: {
    en: "Cannot edit group, Please try again!",
    de: "Gruppe kann nicht bearbeitet werden, bitte versuche es erneut!",
  },
  MASTERDATA_GROUPS_EDIT_TITLE: {
    en: "Edit group",
    de: "Gruppe bearbeiten",
  },
  MASTERDATA_GROUPS_EDIT_NEW_TITLE: {
    en: "Create new group",
    de: "Neue Gruppe erstellen",
  },
  MASTERDATA_GRPUPS_GROUP_NAME_FIELD_NAME: {
    en: "Name",
    de: "Name",
  },
  MASTERDATA_GROUPS_GROUP_NAME_PLACEHOLDER: {
    en: "Enter group name",
    de: "Gruppennamen eingeben",
  },
  MASTERDATA_GROUPS_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  MASTERDATA_GROUPS_DELETE_SUCCESS_MESSAGE: {
    en: "Group deleted successfully!",
    de: "Gruppe erfolgreich gelöscht!",
  },
  MASTERDATA_GROUPS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete group, Please try again!",
    de: "Gruppe kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_GROUPS_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Group(s) deleted successfully!",
    de: "Gruppe(n) erfolgreich gelöscht!",
  },
  MASTERDATA_GROUPS_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete group(s), Please try again!",
    de: "Gruppe(n) können nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_GROUPS_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete this group?",
    de: "Bist du sicher, dass du diese Gruppe löschen möchtest?",
  },
  MASTERDATA_GROUPS_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  MASTERDATA_GROUPS_SELECTED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete selected group(s)?",
    de: "Bist du sicher, dass du die ausgewählten Gruppen löschen möchtest?",
  },
  MASTERDATA_GROUPS_CREATE_NEW_BUTTON: {
    en: "Create new group",
    de: "Neue Gruppe erstellen",
  },
  MASTERDATA_CONTACT_TYPES_CREATE_SUCCESS_MESSAGE: {
    en: "ContactType Created Successfully!",
    de: "Kontaktdetails erfolgreich erstellt!",
  },
  MASTERDATA_CONTACT_TYPE_CREATE_FAILURE_MESSAGE: {
    en: "Cannot create ContactType, Please try again!",
    de: "Die Kontaktdetails können nicht erstellt werden, bitte versuche es erneut!",
  },
  MASTERDATA_CONTACT_TYPE_EDIT_SUCCESS_MESSAGE: {
    en: "Contact name edited successfully!",
    de: "Kontaktname erfolgreich bearbeitet!",
  },
  MASTERDATA_CONTACT_TYPE_EDIT_FAILURE_MESSAGE: {
    en: "Cannot edit Contact, Please try again!",
    de: "Kontakt kann nicht bearbeitet werden, bitte versuche es erneut!",
  },
  MASTERDATA_CONTACT_TYPE_EDIT_TITLE: {
    en: "Edit contactType",
    de: "Kontaktdetails bearbeiten",
  },
  MASTERDATA_CONTACT_TYPE_EDIT_NEW_TITLE: {
    en: "Create new contact",
    de: "Kontaktdetails hinzufügen",
  },
  MASTERDATA_CONTACT_TYPE_CONTACT_NAME_FIELD_NAME: {
    en: "Name",
    de: "Name",
  },
  MASTERDATA_CONTACT_TYPE_CONTACT_NAME_PLACEHOLDER: {
    en: "Enter contact name",
    de: "Kontaktnamen eingeben",
  },
  MASTERDATA_CONTACT_TYPE_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  MASTERDATA_CONTACT_TYPE_DELETE_SUCCESS_MESSAGE: {
    en: "Contact deleted successfully!",
    de: "Kontakt erfolgreich gelöscht!",
  },
  MASTERDATA_CONTACT_TYPE_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete contact, Please try again!",
    de: "Kontakt kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_CONTACT_TYPE_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Contact(s) deleted successfully!",
    de: "Kontakt(e) erfolgreich gelöscht!",
  },
  MASTERDATA_CONTACT_TYPE_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete contact(s), Please try again!",
    de: "Kontakt(e) können nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_CONTACT_TYPE_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete this contact?",
    de: "Bist du sicher, dass du diesen Kontakt löschen möchtest?",
  },
  MASTERDATA_CONTACT_TYPE_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  MASTERDATA_CONTACT_TYPE_SELECTED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete selected Contact types?",
    de: "Bist du sicher, dass du die ausgewählten Kontaktdetails löschen möchtest?",
  },
  MASTERDATA_CONTACT_TYPE_CREATE_NEW_BUTTON: {
    en: "Create new Contact",
    de: "Kontaktdetails hinzufügen",
  },
  MASTERDATA_CONTACT_TYPE_CONTACT_NAME_REQUIRED: {
    en: "Name is required!",
    de: "Name ist erforderlich!",
  },
  MASTERDATA_CATEGORY_CATEGORY_NAME_REQUIRED: {
    en: "Category name is required",
    de: "Kategoriename ist erforderlich",
  },
  MASTERDATA_GROUPS_GROUP_NAME_REQUIRED: {
    en: "Group name is required",
    de: "Gruppenname ist erforderlich",
  },
  MASTERDATA_TAGS_TAG_NAME_REQUIRED: {
    en: "Tag name is required",
    de: "Stichwortname ist erforderlich",
  },
  MASTERDATA_60SYMBOLS_REQUIRED: {
    en: "Maximum 60 symbols",
    de: "Maximal 60 Zeichen",
  },
  "MENU.LEGALTECH.MASTERDATA": {
    en: "Masterdata",
    de: "Stammdaten",
  },
  MASTERDATA_TAGS_TITLE: {
    en: "TAGS",
    de: "Tags",
  },
  WORDPRESS_CATEGORY_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure, you want to delete this Category?",
    de: "Bist du sicher, dass du diese Kategorie löschen möchtest?",
  },
  MASRERDATA_CATEGORIES_TITLE: {
    en: "CATEGORIES",
    de: "Produkt Kategorien",
  },
  MASTERDATA_GROUPS_TTLE: {
    en: "GROUPS",
    de: "Gruppen",
  },
  MASTERDATA_ROUTE_LABELS_TITLE: {
    en: "LABELS",
    de: "Labels",
  },
  MASTERDATA_CONTACT_DETAILS_TITLE: {
    en: "CONTACT DETAILS",
    de: "Kontaktdetails",
  },
  PARTNERS_INDUSTRY_GROUP_UPDATE_SUCCESS_MESSAGE: {
    en: "Group updated successfully!",
    de: "Gruppe erfolgreich aktualisiert!",
  },
  PARTNERS_INDUSTRY_GROUP_UPDATE_ERROR_MESSAGE: {
    en: "There was an issue updating group, please try again!",
    de: "Beim Aktualisieren der Gruppe ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  PARTNERS_INDUSTRY_GROUP_MODAL_MESSAGE: {
    en: "Please enter the below details to create a group",
    de: "Bitte gib die folgenden Details ein, um eine Gruppe zu erstellen",
  },
  PARTNERS_INDUSTRY_GROUP_NAME_LABEL_TITLE: {
    en: "Group name",
    de: "Gruppenname",
  },
  PARTNERS_ADD_NEW_INDUSTRY_SECTOR_NAME_VALIDATION: {
    en: "Name is required",
    de: "Name ist erforderlich",
  },
  PARTNERS_INDUSTRY_SECTOR_MODAL_MESSAGE: {
    en: "Please enter the below details to create a sector",
    de: "Bitte gib die folgenden Details ein, um einen Sektor zu erstellen",
  },
  PARTNERS_INDUSTRY_SECTOR_NAME_LABEL_TITLE: {
    en: "Sector name",
    de: "Sektorname",
  },
  PARTNERS_INDUSTRY_SECTOR_DELETE_SUCCESS_MESSAGE: {
    en: "Sector deleted successfully",
    de: "Sektor erfolgreich gelöscht",
  },
  PARTNERS_INDUSTRY_SECTOR_DELETE_ERROR_MESSAGE: {
    en: "There was an issue deleting this sector",
    de: "Beim Löschen dieses Sektors ist ein Fehler aufgetreten",
  },
  PARTNERS_INDUSTRY_GROUP_MODAL_TITLE: {
    en: "Group Details",
    de: "Gruppendetails",
  },
  PARTNERS_INDUSTRY_GROUP_NO_SECTORS: {
    en: "No sectors for ths group",
    de: "Keine Sektoren für diese Gruppe",
  },
  PARTNERS_INDUSTRY_SECTOR_DELETE_CONFIRMATION_MESSAGE: {
    en: "Are you sure you want to delete this sector",
    de: "Bist du sicher, dass du diesen Sektor löschen möchtest",
  },
  PARTNERS_INDUSTRY_GROUP_DELETE_SUCCESS_MESSAGE: {
    en: "Group deleted successfully",
    de: "Gruppe erfolgreich gelöscht",
  },
  PARTNERS_INDUSTRY_GROUP_DELETE_ERROR_MESSAGE: {
    en: "There was an issue deleting this group",
    de: "Beim Löschen dieser Gruppe ist ein Fehler aufgetreten",
  },
  PARTNERS_INDUSTRY_SEARCH_PLACEHOLDER: {
    en: "Search industry & sectors",
    de: "Durchsuche Branche und Sektoren",
  },
  PARTNERS_INDUSTRY_ADD_NEW_GROUP: {
    en: "Add new group",
    de: "Neue Gruppe hinzufügen",
  },
  PARTNERS_INDUSTRY_GROUP_DELETE_CONFIRMATION_MESSAGE: {
    en: "Are you sure you want to delete this group, all sectors will also be deleted",
    de: "Bist du sicher, dass du diese Gruppe löschen möchtest? Alle Sektoren werden ebenfalls gelöscht",
  },
  PARTNERS_INDUSTRY_GROUP_SECTORS_EMPTY_MESSAGE: {
    en: "No sectors for ths group",
    de: "Keine Sektoren für diese Gruppe",
  },
  PARTNERS_ADD_PARTNER_MODAL_PARTNER_NAME: {
    en: "Organisation name",
    de: "Name der Organisation",
  },
  MASTERDATA_TITLE: {
    en: "Title",
    de: "Titel",
  },
  MASTERDATA_DESCRIPTION_FIELD: {
    en: "Description",
    de: "Beschreibung",
  },
  MASTERDATA_ISVISIBLE: {
    en: "Visible?",
    de: "Sichtbar?",
  },
  MASTERDATA_PICTURE_FIELD: {
    en: "Picture",
    de: "Bild",
  },
  PARTNERS_LIST_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  PARTNERS_LIST_TITLE: {
    en: "Title",
    de: "Titel",
  },
  PARTNERS_LIST_ADDRESS: {
    en: "Address",
    de: "Adresse",
  },
  PARTNERS_LIST_LABELS: {
    en: "Labels",
    de: "Labels",
  },
  PARTNERS_LIST_SECTORS: {
    en: "Sectors",
    de: "Branchen",
  },
  PARTNERS_LIST_SELECTED: {
    en: "Selected",
    de: "Auswahl",
  },
  PARTNERS_LIST_DELETE_SELECTED: {
    en: "Delete selected",
    de: "Ausgewählte löschen",
  },
  PARTNERS_LIST_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete these organisation(s)?",
    de: "Bist du sicher, dass du diese Organisation(en) löschen möchtest?",
  },
  PARTNERS_LIST_TABLE_FILTER_BTN: {
    en: "Filter",
    de: "Filter",
  },
  PARTNERS_LIST_TABLE_FILTER_COUNTRY_FIELD: {
    en: "Country",
    de: "Land",
  },
  PARTNERS_LIST_TABLE_FILTER_LABELS_FIELD: {
    en: "Labels",
    de: "Etiketten",
  },
  PARTNERS_LIST_TABLE_FILTER_PRODUCTS_FIELD: {
    en: "Products",
    de: "Produkte",
  },
  PARTNERS_LIST_TABLE_FILTER_SUBSCRIPTIONS_FIELD: {
    en: "Subscriptions",
    de: "Abonnements",
  },
  PARTNERS_LIST_FILTER_PRODUCT_KEY_NAME: {
    en: "Product",
    de: "Produkt",
  },
  PARTNERS_LIST_FILTER_SUBSCRIPTION_KEY_NAME: {
    en: "Subscription",
    de: "Abonnement",
  },
  PARTNERS_LIST_TABLE_FILTER_SECTORS_FIELD: {
    en: "Sectors",
    de: "Sektoren",
  },
  PARTNERS_LIST_ADD_PARTNER: {
    en: "Add new organisation",
    de: "Neue Organisation hinzufügen",
  },
  PARTNERS_LIST_NOITEMS_FOUND: {
    en: "No organisations found.",
    de: "Keine Organisation vorhanden.",
  },
  PARTNERS_LABELS_TAGS_TITLE: {
    en: "Labels",
    de: "Etiketten",
  },
  PARTNERS_EDIT_HEADER: {
    en: "Organisation Details",
    de: "Organisationsdetails",
  },
  PARTNERS_EDIT_CONTACTS_HEADER: {
    en: "Contact Details",
    de: "Kontaktdetails",
  },
  PARTNERS_EDIT_TAB_CONTENT: {
    en: "Content",
    de: "Inhalt",
  },
  PARTNERS_EDIT_TAB_CONTACT: {
    en: "Contact",
    de: "Kontakt",
  },
  PARTNERS_EDIT_TAB_FORGE: {
    en: "Forge",
    de: "Forge",
  },
  PARTNERS_EDIT_TAB_MYCONTRACTS: {
    en: "My contracts",
    de: "Meine Verträge",
  },
  PARTNERS_LOGO_SQUARE_TITLE: {
    en: "Logo ",
    de: "Logo ",
  },
  PARTNERS_LOGO_ALLOWED_MESSAGE: {
    en: "Only *.png, *.jpg and *.jpeg image files are accepted!",
    de: "Es werden nur *.png, *.jpg and *.jpeg Dateien akzeptiert!",
  },
  PARTNERS_GENERAL_TITLE: {
    en: "General",
    de: "Zuweisungen",
  },
  PARTNERS_GENERAL_DETAILS_HEADER: {
    en: "General",
    de: "Allgemein",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_COMPANYNAME: {
    en: "Company",
    de: "Unternehmen",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_COMPANYNAME_VALIDATION_ERROR: {
    en: "Company name is required",
    de: "Der Unternehmensname ist ein Pflichtfeld!",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS1: {
    en: "Address 1",
    de: "Adresse",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_ADDRESS2: {
    en: "Address 2",
    de: "Adresszusatz ",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_CITY: {
    en: "City",
    de: "Stadt",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_ZIPCODE: {
    en: "Zip code",
    de: "PLZ",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_COUNTRY: {
    en: "Country",
    de: "Land",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_EMAIL: {
    en: "Email",
    de: "E-Mail",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_WEBSITE_URL: {
    en: "Website URL",
    de: "Webseite",
  },
  PARTNERS_GENERAL_DETAILS_FIELD_TELEPHONE: {
    en: "Telephone",
    de: "Telefon",
  },
  PARTNERS_GENERAL_DETAILS_SAVE_BUTTON: {
    en: "Save",
    de: "Speichern",
  },
  PARTNERS_GENERAL_DETAILS_SAVE_LOADING: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  PARTNERS_GENERAL_DETAILS_UPDATE_SUCCESS: {
    en: "General details are updated successfully!",
    de: "Die Allgemeinen Daten wurden erfolgreich aktualisiert!",
  },
  PARTNERS_GENERAL_DETAILS_UPDATE_FAILURE: {
    en: "There was an issue updating general details, Please try again!",
    de: "Beim aktualiseren der Allgemeinen Daten ist ein Fehler aufgetreten, bitte versuchen Sie es noch mal!",
  },
  PARTNERS_FINANCE_DETAILS_HEADER: {
    en: "Finance",
    de: "Finanzen",
  },
  PARTNERS_FINANCE_DETAILS_FIELD_TAXNUMBER: {
    en: "Tax number",
    de: "Steuernummer",
  },
  PARTNERS_FINANCE_DETAILS_FIELD_USTID: {
    en: "USt-ID",
    de: "USt-ID",
  },
  PARTNERS_FINANCE_DETAILS_FIELD_DEBITORNUMBER: {
    en: "Debitor number",
    de: "Debitorennummer",
  },
  PARTNERS_FINANCE_DETAILS_FIELD_CREDITORNUMBER: {
    en: "Creditor number",
    de: "Kreditorennummer",
  },
  PARTNERS_FINANCE_DETAILS_FIELD_BILLINGEMAIL: {
    en: "Billing email",
    de: "Rechnungs E-Mail",
  },
  PARTNERS_FINANCE_DETAILS_FIELD_PAYMENT_TARGETDAYS: {
    en: "Payment target days",
    de: "Zahlungsziel",
  },
  PARTNERS_FINANCE_DETAILS_FIELD_INVOICES: {
    en: "Are invoices separated?",
    de: "Rechnungsposten seperieren?",
  },
  PARTNERS_FINANCE_DETAILS_SAVE_BUTTON: {
    en: "Save",
    de: "Speichern",
  },
  PARTNERS_FINANCE_DETAILS_SAVE_LOADING: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  PARTNERS_FINANCE_DETAILS_UPDATE_SUCCESS: {
    en: "Finance details are updated successfully!",
    de: "Die Finanzdaten wurden erfolgreich aktualisert!",
  },
  PARTNERS_FINANCE_DETAILS_UPDATE_FAILURE: {
    en: "There was an issue updating finance details, Please try again!",
    de: "Beim aktualiseren der Finanzdaten ist ein Fehler aufgetreten, bitte versuchen Sie es noch mal!",
  },
  PARTNERS_CONTACTTYPES_HEADER: {
    en: "Contact Types",
    de: "Kontaktdetails",
  },
  PARTNERS_CONTACTTYPES_EMPTY: {
    en: "No contacts added!",
    de: "Es wurden noch keine Kontaktdetails hinzugefügt!",
  },
  PARTNERS_CONTACTTYPES_ADD_CONTACT_BUTTON: {
    en: "Add contact",
    de: "Kontakart hinzufügen",
  },
  PARTNERS_CONTACTTYPES_ADD_CONTACT_MODAL_TITLE: {
    en: "Add contact",
    de: "Kontakt hinzufügen",
  },
  PARTNERS_CONTACTTYPES_ADD_CONTACT_MODAL_FIELD_CONTACTTYPE: {
    en: "Contact type",
    de: "Kontakttyp",
  },
  PARTNERS_CONTACTTYPES_ADD_CONTACT_MODAL_FIELD_DESCRIPTION: {
    en: "Description",
    de: "Beschreibung",
  },
  PARTNERS_CONTACTTYPES_ADD_CONTACT_MODAL_CLOSE: {
    en: "Close",
    de: "Bearbeiten",
  },
  PARTNERS_CONTACTTYPES_ADD_CONTACT_MODAL_SUBMIT: {
    en: "Submit",
    de: "Speichern",
  },
  PARTNERS_CONTACTTYPES_ADD_CONTACT_MODAL_SUBMIT_LOADING: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  PARTNERS_NOTES_HEADER: {
    en: "Notes",
    de: "Notizen",
  },
  PARTNERS_NOTES_UPDATE_SUCCESS: {
    en: "Notes added successfully!",
    de: "Notizen erfolgreich hinzugefügt!",
  },
  PARTNERS_NOTES_UPDATE_FAILURE: {
    en: "There was an issue adding notes, Please try again!",
    de: "Beim Hinzufügen der Notizen ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  PARTNER_FINANCE_PAYMENT_TARGET_MIN_VALIDATION: {
    en: "Min value 1",
    de: "Mindestwert 1",
  },
  PARTNER_FINANCE_DETAILS_UPDATE_SUCCESS: {
    en: "Finance details are updated successfully!",
    de: "Finanzdetails wurden erfolgreich aktualisiert!",
  },
  PARTNER_FINANCE_DETAILS_UPDATE_ERROR: {
    en: "There was an issue updating finance details, Please try again!",
    de: "Beim Aktualisieren der Finanzdetails ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  PARTNER_FINANCE_DETAILS_TITLE: {
    en: "Finance",
    de: "Finanzen",
  },
  EDITOR_SAVE_BUTTON: {
    en: "Save",
    de: "Speichern",
  },
  PARTNERS_LOGO_CHANGE_TOOLTIP: {
    en: "Change logo",
    de: "Logo austauschen",
  },
  PARTNERS_PERMISSIONS_SUCCESS_MESSAGE: {
    en: "Module Permissions updated successfully!",
    de: "Modulberechtigungen erfolgreich aktualisiert!",
  },
  PARTNERS_PERMISSIONS_ERROR_MESSAGE: {
    en: "Can not update permissions, Please try again!",
    de: "Berechtigungen können nicht aktualisiert werden, bitte versuche es erneut!",
  },
  PARTNERS_PERMISSIONS_TITLE: {
    en: "Module Permissions",
    de: "Modulberechtigungen",
  },
  PARTNERS_COUNTRY_CODE_COPY_SUCCESS: {
    en: "Copied to clipboard",
    de: "In die Zwischenablage kopiert",
  },
  PARTNERS_CONTACT_TYPES_CONTACT_ID_VALIDATION: {
    en: "Please select a contact",
    de: "Bitte wähle einen Kontakt aus",
  },
  PARTNERS_CONTACT_TYPES_DESCRIPTION_MAX_VALIDATION: {
    en: "Maximum 60 symbols",
    de: "Maximal 60 Zeichen",
  },
  PARTNERS_CONTACT_TYPES_DESCRIPTION_REQUIRED_VALIDATION: {
    en: "value is required",
    de: "Wert ist erforderlich",
  },
  PARTNERS_CONTACT_TYPES_EDIT_SUCCESS_MESSAGE: {
    en: "Contact edited successfully!",
    de: "Kontakt erfolgreich bearbeitet!",
  },
  PARTNERS_CONTACT_TYPES_EDIT_ERROR_MESSAGE: {
    en: "Cannot edit contact, Please try again!",
    de: "Kontakt kann nicht bearbeitet werden, bitte versuche es erneut!",
  },
  PARTNERS_CONTACT_TYPES_ADD_SUCCESS_MESSAGE: {
    en: "Contact added successfully!",
    de: "Kontakt erfolgreich hinzugefügt!",
  },
  PARTNERS_CONTACT_TYPES_ADD_ERROR_MESSAGE: {
    en: "Cannot add contact, Please try again!",
    de: "Kontakt kann nicht hinzugefügt werden, bitte versuche es erneut!",
  },
  PARTNERS_CONTACT_TYPES_DELETE_CONFIRMATION_MESSAGE: {
    en: "Are you sure you want to delete this contact?",
    de: "Bist du sicher, dass du diesen Kontakt löschen möchtest?",
  },
  PARTNERS_CONTACT_TYPES_DELETE_SUCCESS_MESSAGE: {
    en: "Contact deleted successfully!",
    de: "Kontakt erfolgreich gelöscht!",
  },
  PARTNERS_CONTACT_TYPES_DELETE_ERROR_MESSAGE: {
    en: "Cannot delete this contact, Please try again!",
    de: "Kontakt kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  PARTNERS_CONTACT_TYPES_ALL_CONTATCS_ADDED_SUCCESS_MESSAGE: {
    en: "All contacts added already!",
    de: "Alle Kontakte bereits hinzugefügt!",
  },
  WEBSITE_SETTINGS_GENERAL_EDIT_IMAGE_SCALE: {
    en: "Scale",
    de: "Skalieren",
  },
  WEBSITE_SETTINGS_GENERAL_EDIT_IMAGE_ROTATE: {
    en: "Rotate",
    de: "Rotieren",
  },
  EDITOR_PLEASE_WAIT_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  PARTNERS_DELETE_CONFIRMATION_MESSAGE: {
    en: "Are you sure you want to delete this organisation?",
    de: "Bist du sicher, dass du diese Organisation löschen möchtest?",
  },
  PARTNERS_DELETE_SUCCESS_MESSAGE: {
    en: "Organisation deleted successfully!",
    de: "Organisation erfolgreich gelöscht!",
  },
  PARTNERS_CONTACTS_TABLE_NAME_COLUMN: {
    en: "Name",
    de: "Name",
  },
  PARTNERS_CONTACTS_TABLE_EMAIL_COLUMN: {
    en: "Email",
    de: "E-Mail",
  },
  PARTNERS_CONTACTS_TABLE_ADDRESS_COLUMN: {
    en: "Address",
    de: "Adresse",
  },
  PARTNERS_CONTACTS_ADD_CONTACTS_SUCCESS_MESSAGE: {
    en: "Organisation contacts added successfully",
    de: "Kontakte der Organisation erfolgreich hinzugefügt",
  },
  PARTNERS_CONTACTS_ADD_CONTACTS_ERROR_MESSAGE: {
    en: "Organisation contacts adding failed!",
    de: "Hinzufügen von Kontakten zur Organisation fehlgeschlagen!",
  },
  PARTNERS_CONTACTS_DELETE_BUTTON: {
    en: "Delete",
    de: "Löschen",
  },
  PARTNERS_CONTACT_DELETE_SUCCESS_MESSAGE: {
    en: "Contact deleted successfully!",
    de: "Kontakt erfolgreich gelöscht!",
  },
  PARTNERS_CONTACT_DELETE_ERROR_MESSAGE: {
    en: "Contact deletion failed!",
    de: "Löschen des Kontakts fehlgeschlagen!",
  },
  PARTNERS_CONTACT_DELETE_CONFIRMATION_MESSAGE: {
    en: "Are you sure , you want to delete this Contact?",
    de: "Bist du sicher, dass du diesen Kontakt löschen möchtest?",
  },
  PARTNERS_CONTACT_DELETE_MULTIPLE_CONFIRMATION_MESSAGE: {
    en: "Are you sure , you want to delete selected contacts?",
    de: "Bist du sicher, dass du die ausgewählten Kontakte löschen möchtest?",
  },
  PARTNERS_CONTACTS_MULTIPLE_DELETE_SUCCESS_MESSAGE: {
    en: "Contacts deleted successfully!",
    de: "Kontakte erfolgreich gelöscht!",
  },
  PARTNERS_CONTACTS_MULTIPLE_DELETE_ERROR_MESSAGE: {
    en: "Contacts deletion failed!",
    de: "Löschen der Kontakte fehlgeschlagen!",
  },
  CUSTOMER_MANAGEMENT_VERIFY_TRIGGER_SUCCESS: {
    en: "Verification email sent successfully!",
    de: "Verifizierungs-E-Mail erfolgreich gesendet!",
  },
  CUSTOMER_MANAGEMENT_VERIFY_TRIGGER_FAILURE: {
    en: "Cannot send verification email, please add email settings for contact verification",
    de: "Verifizierungs-E-Mail kann nicht gesendet werden, bitte füge E-Mail-Einstellungen für die Kontaktverifizierung hinzu",
  },
  CUSTOMER_MANAGEMENT_BLOCK_CUSTOMER_SUCCESS: {
    en: "Customer blocked successfully!",
    de: "Der Kontakt wurde erfolgreich gesperrt!",
  },
  CUSTOMER_MANAGEMENT_TABLE_FILTER_FIELD_BLOCKED: {
    en: "Blocked",
    de: "Gesperrt",
  },
  CUSTOMER_MANAGEMENT_TABLE_FILTER_FIELD_ZIP_CODE: {
    en: "Zip Code",
    de: "Postleitzahl",
  },
  CUSTOMER_MANAGEMENT_BLOCK_CUSTOMER_FAILURE: {
    en: "Cannot update contact state, please try again!",
    de: "Kontaktstatus kann nicht aktualisiert werden, bitte versuche es erneut!",
  },
  CUSTOMER_MANAGEMENT_UNBLOCK_CUSTOMER_SUCCESS: {
    en: "Customer unblocked successfully!",
    de: "Der Kontakt wurde erfolgreich entsperrt!",
  },
  CUSTOMER_MANAGEMENT_LIST_SELECTED: {
    en: "Selected",
    de: "Auswahl",
  },
  CUSTOMER_MANAGEMENT_LIST_DELETE: {
    en: "Delete",
    de: "Löschen",
  },
  CUSTOMER_UPLOAD_PROFILEPIC_SECTION_TITLE: {
    en: "Profile picture",
    de: "Profilbild",
  },
  CUSTOMER_UPLOAD_PROFILEPIC_SUCCESS: {
    en: "Contact profile picture updated successfully!",
    de: "Das Profilbild wurde erfolgreich aktualisiert!",
  },
  CUSTOMER_UPLOAD_PROFILEPIC_FAILURE: {
    en: "Could not update contact profile picture, please try again!",
    de: "Beim speichern des Profilbildes ist ein Fehler aufgetreten!",
  },
  CUSTOMER_MANAGEMENT_LIST_ADD_NEW_CUSTOMER: {
    en: "New Contact",
    de: "Kontakt erstellen",
  },
  CUSTOMER_MANAGEMENT_LIST_CUSTOMER: {
    en: "Contact",
    de: "Kontakt",
  },
  CUSTOMER_MANAGEMENT_LIST_ROLE: {
    en: "Role",
    de: "Rolle",
  },
  CUSTOMER_MANAGEMENT_LIST_LAST_LOGIN: {
    en: "Last Login",
    de: "Letzter Login",
  },
  CUSTOMER_MANAGEMENT_LIST_2_STEP: {
    en: "Two-step",
    de: "2-FA",
  },
  CUSTOMER_MANAGEMENT_TABLE_COLUMN_BLOCKED: {
    en: "Status",
    de: "Status",
  },
  CUSTOMER_MANAGEMENT_CUSTOMER_VALUE_UNBLOCKED: {
    en: "Enabled",
    de: "Aktiviert",
  },
  CUSTOMER_MANAGEMENT_CUSTOMER_VALUE_BLOCKED: {
    en: "Blocked",
    de: "Gesperrt",
  },
  CUSTOMER_MANAGEMENT_TABLE_COLUMN_ADDRESS: {
    en: "Address",
    de: "Adresse",
  },
  CUSTOMER_MANAGEMENT_TABLE_COLUMN_LABELS: {
    en: "Labels",
    de: "Labels",
  },
  CUSTOMER_MANAGEMENT_LIST_JOINED_DATE: {
    en: "Joined Date",
    de: "Erstellungsdatum",
  },
  CUSTOMER_MANAGEMENT_LIST_ENABLED: {
    en: "Enabled",
    de: "Aktiviert",
  },
  CUSTOMER_MANAGEMENT_LIST_DISABLED: {
    en: "Disabled",
    de: "Deaktiviert",
  },
  CUSTOMER_MANAGEMENT_LIST_ACTIVATED: {
    en: "Activated",
    de: "Aktiviert",
  },
  CUSTOMER_MANAGEMENT_LIST_NOT_ACTIVATED: {
    en: "Not Activated",
    de: "Nicht Aktiviert",
  },
  CUSTOMER_MANAGEMENT_LIST_NO_ITEMS_FOUND: {
    en: "No items found.",
    de: "Keine Einträge vorhanden.",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_TITLE: {
    en: "Sign-in Method",
    de: "Anmelde Methode",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_EMAIL_ADDRESS: {
    en: "Email",
    de: "E-Mail",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_NEW_EMAIL: {
    en: "Enter New Email Address",
    de: "Bitte geben Sie eine E-Mail Adresse an",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_CONFIRM_PASSWORD: {
    en: "Confirm Password",
    de: "Passwort bestätigen",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_CANCEL: {
    en: "Cancel",
    de: "Abbrechen",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_CHANGE_EMAIL: {
    en: "Change Email",
    de: "E-Mail ändern",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_PASSWORD: {
    en: "Password",
    de: "Passwort",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_CURRENT_PASSWORD: {
    en: "Current Password",
    de: "Aktuelles Passwort",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_NEW_PASSWORD: {
    en: "New Password",
    de: "Neues Passwort",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_CONFIRM_NEW_PASSWORD: {
    en: "Confirm New Password",
    de: "Passwort wiederholen",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_PASSWORD_VALIDATION: {
    en: "Password must be at least 8 character and contain symbols",
    de: "Verwenden Sie 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen.",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_RESET_PASSWORD: {
    en: "Reset Password",
    de: "Passwort zurücksetzen",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_TWO_FACTOR: {
    en: "Two-factor Authentication",
    de: "Zwei-Faktor-Authentifizierung",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_ENABLED: {
    en: "Enabled",
    de: "Aktiviert",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_DISABLING: {
    en: "Disabling...",
    de: "Deaktiviere...",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_SECURE_ACCOUNT: {
    en: "Secure Your Account",
    de: "Sichern Sie Ihr Konto",
  },
  CUSTOMER_MANAGEMENT_SIGNIN_TWO_FACTOR_MESSAGE: {
    en: "Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code",
    de: "Die Zwei-Faktor-Authentifizierung verleiht deinem Konto eine zusätzliche Sicherheitsebene. Um sich anzumelden, musst du zusätzlich einen 6-stelligen Code eingeben",
  },
  CUSTOMER_MANAGEMENT_LIST_ENABLE: {
    en: "Enable",
    de: "Aktiviert",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_NAME: {
    en: "Company name",
    de: "Unternehmen",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_EMAIL: {
    en: "Company email",
    de: "E-Mail",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_TAX_NUMBER: {
    en: "Tax Number",
    de: "Steuernummer",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_VAT: {
    en: "VAT",
    de: "USt-ID",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_COUNTRY: {
    en: "Country",
    de: "Land",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_LINE_1: {
    en: "Address Line1",
    de: "Adresse",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_LINE_2: {
    en: "Address Line2",
    de: "Adresszusatz",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_CITY: {
    en: "City",
    de: "Stadt",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_ZIP: {
    en: "ZIP Code",
    de: "PLZ",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_STATE: {
    en: "State",
    de: "Bundesland",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_SUBMIT: {
    en: "Submit",
    de: "Speichern",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_CLOSE: {
    en: "Close",
    de: "Schließen",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_ADDRESS_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION: {
    en: "Salutation",
    de: "Anrede",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION_TYPES: {
    en: JSON.stringify([
      { id: 1, name: "Mr" },
      { id: 2, name: "Mrs." },
      { id: 3, name: "Divers" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "Herr" },
      { id: 2, name: "Frau" },
    ]),
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLE_TYPES: {
    en: JSON.stringify([
      { id: 0, name: "Select" },
      { id: 1, name: "Dr." },
      { id: 2, name: "Prof." },
      { id: 3, name: "Prof. Dr." },
    ]),
    de: JSON.stringify([
      { id: 0, name: "Select" },
      { id: 1, name: "Dr." },
      { id: 2, name: "Prof." },
      { id: 3, name: "Prof. Dr." },
    ]),
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION_MR: {
    en: "Mr",
    de: "Herr",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUTATION_MRS: {
    en: "Mrs.",
    de: "Frau",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLES: {
    en: "Titles",
    de: "Titel",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLES_DR: {
    en: "Dr.",
    de: "Dr.",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLES_PROF: {
    en: "Prof.",
    de: "Prof.",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_TITLES_PROF_DR: {
    en: "Prof. Dr.",
    de: "Prof. Dr.",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_FIRST_NAME: {
    en: "First name",
    de: "Vorname",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LAST_NAME: {
    en: "Last name",
    de: "Nachname",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_EMAIL: {
    en: "Email",
    de: "E-Mail",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_USERNAME_FIELD: {
    en: "Username",
    de: "Benutzername",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_CLOSE: {
    en: "Close",
    de: "Schließen",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SUBMIT: {
    en: "Save",
    de: "Speichern",
  },
  CUSTOMER_MANAGEMENT_DEVICES_DEREGISTER: {
    en: "Deregister",
    de: "Abmelden",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_COUNTRY: {
    en: "Country",
    de: "Land",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_LINE_1: {
    en: "Address Line1",
    de: "Adresse",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_LINE_2: {
    en: "Address Line2",
    de: "Adresszusatz",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_CITY: {
    en: "City",
    de: "Stadt",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_ZIP: {
    en: "ZIP Code",
    de: "PLZ",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_STATE: {
    en: "State",
    de: "Bundesland",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_CLOSE: {
    en: "Close",
    de: "Schließen",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_SUBMIT: {
    en: "Save",
    de: "Speichern",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  CUSTOMER_MANAGEMENT_BILLING_ADDRESS_MESSAGE: {
    en: "No address added",
    de: "Keine Adresse vorhanden",
  },
  CUSTOMER_MANAGEMENT_BILLING_ADDRESS_NEW_ADDRESS: {
    en: "+ New",
    de: "+ Erstellen",
  },
  CUSTOMER_MANAGEMENT_BILLING_ADDRESS_DELETE: {
    en: "Delete",
    de: "Löschen",
  },
  CUSTOMER_MANAGEMENT_BILLING_ADDRESS_EDIT: {
    en: "Edit",
    de: "Bearbeiten",
  },
  CUSTOMER_MANAGEMENT_BILLING_ADDRESS_TITLE: {
    en: "Address",
    de: "Adresse",
  },
  CUSTOMER_MANAGEMENT_COMMENTS: {
    en: "Comments",
    de: "Kommentare",
  },
  CUSTOMER_MANAGEMENT_CONTACT_TYPES_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  CUSTOMER_MANAGEMENT_CONTACT_TYPES_TITLE: {
    en: "Contact Types",
    de: "Kontaktdetails",
  },
  CUSTOMER_MANAGEMENT_DEVICES_TITLE: {
    en: "Registered Devices",
    de: "Registrierte Endgeräte",
  },
  CUSTOMER_MANAGEMENT_CONTACT_TYPES_NO_CONTACTS: {
    en: "No contacts added.",
    de: "Keine Kontakte vorhanden.",
  },
  CUSTOMER_MANAGEMENT_DEVICES_NO_DEVICES: {
    en: "No devices available.",
    de: "Keine Geräte verfügbar",
  },
  CUSTOMER_MANAGEMENT_LOGS_NO_LOGS: {
    en: "No logs available.",
    de: "Keine Logs",
  },
  CUSTOMER_MANAGEMENT_CONTACT_TYPES: {
    en: "Selected",
    de: "Auswahl",
  },
  CUSTOMER_MANAGEMENT_PROFILE_MAIN_TITLE: {
    en: "Profile Details",
    de: "Profildetails",
  },
  CUSTOMER_MANAGEMENT_PROFILE_SAVE_BTN: {
    en: "Save",
    de: "Speichern",
  },
  CUSTOMER_MANAGEMENT_DELETE_USER_SUCCESS: {
    en: "Customer deleted successfully",
    de: "Der Kontakt wurde erfolgreich gelöscht!",
  },
  CUSTOMER_MANAGEMENT_DELETE_USER_FAILED: {
    en: "Cannot delete contact, please try again!",
    de: "Der Kontakt konnte nicht gelöscht werden, bitte versuche es noch mal!",
  },
  USER_MANAGEMENT_DELETE_USER_SUCCESS: {
    en: "User deleted successfully",
    de: "Benutzer erfolgreich gelöscht",
  },
  USER_MANAGEMENT_DELETE_USER_FAILED: {
    en: "Cannot delete user, please try again!",
    de: "Der Benutzer konnte nicht gelöscht werden, bitte versuche es noch mal!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_AVATAR: {
    en: "Avatar",
    de: "Avatar",
  },
  CUSTOMER_MANAGEMENT_PROFILE_AVATAR_MESSAGE: {
    en: "Allowed file types: png, jpg, jpeg.",
    de: "Erlaubte Dateiendungen sind: *.png, *.jpg und *.jpeg.",
  },
  CUSTOMER_MANAGEMENT_PROFILE_SALUTATION: {
    en: "Salutation",
    de: "Anrede",
  },
  CUSTOMER_MANAGEMENT_PROFILE_SALUTATION_MR: {
    en: "Mr",
    de: "Herr",
  },
  CUSTOMER_MANAGEMENT_PROFILE_SALUTATION_MRS: {
    en: "Mrs.",
    de: "Frau",
  },
  CUSTOMER_MANAGEMENT_PROFILE_TITLE: {
    en: "Title",
    de: "Titel",
  },
  CUSTOMER_MANAGEMENT_PROFILE_TITLE_DR: {
    en: "Dr.",
    de: "Dr.",
  },
  CUSTOMER_MANAGEMENT_PROFILE_TITLE_PROF: {
    en: "Prof.",
    de: "Prof.",
  },
  CUSTOMER_MANAGEMENT_PROFILE_TITLE_PROF_DR: {
    en: "Prof. Dr.",
    de: "Prof. Dr.",
  },
  CUSTOMER_MANAGEMENT_PROFILE_FIRST_NAME: {
    en: "First name",
    de: "Vorname",
  },
  CUSTOMER_MANAGEMENT_PROFILE_LAST_NAME: {
    en: "Last name",
    de: "Nachname",
  },
  CUSTOMER_MANAGEMENT_PROFILE_FULL_NAME: {
    en: "Firstname, Lastname",
    de: "Vorname, Nachname",
  },
  CUSTOMER_MANAGEMENT_PROFILE_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  CUSTOMER_MANAGEMENT_USER_ACTION_EDIT: {
    en: "Edit",
    de: "Bearbeiten",
  },
  CUSTOMER_MANAGEMENT_USER_ACTION_DELETE: {
    en: "Delete",
    de: "Löschen",
  },
  CUSTOMER_MANAGEMENT_LABELS: {
    en: "Labels",
    de: "Labels",
  },
  CUSTOMER_MANAGEMENT_VERIFY_ACCOUNT_SECTION: {
    en: "Reset password",
    de: "Passwort zurücksetzen",
  },
  CUSTOMER_MANAGEMENT_VERIFY_ACCOUNT_BUTTON: {
    en: "Verify",
    de: "Verifizieren",
  },
  CUSTOMER_MANAGEMENT_VERIFY_ACCOUNT_STATUS_NULL: {
    en: "Your email is not yet verified",
    de: "Das Benutzerkonto wurde noch nicht verfiziert",
  },
  CUSTOMER_MANAGEMENT_VERIFY_ACCOUNT_STATUS_PROCESSING: {
    en: "Verification email has been sent",
    de: "Verifizierungs-E-Mail wurde gesendet",
  },
  CUSTOMER_MANAGEMENT_VERIFY_ACCOUNT_STATUS_SUCCESS: {
    en: "Your email has been verified",
    de: "Das Benutzerkonto wurde erfolgreich verifiziert",
  },
  CUSTOMER_MANAGEMENT_VERIFY_ACCOUNT_LOADING: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  CUSTOMER_MANAGEMENT_USER_ACTION_DELETE_MESSAGE: {
    en: "Are you sure, you want to permanently delete this contact?",
    de: "Wollen Sie wirklich den Kontakt dauerhaft löschen?",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_NAME_REQUIRED: {
    en: "Please enter Company name",
    de: "Bitte den Firmennamen eingeben",
  },
  CUSTOMER_MANAGEMNENT_ADD_COMPANY_WRONG_EMAIL_FORMAT: {
    en: "Wrong email format",
    de: "Falsches E-Mail-Format",
  },
  CUSTOMER_MANAGEMENT_MAX_SYMBOL_ERROR: {
    en: "Maximum 60 symbols",
    de: "Maximal 60 Zeichen",
  },
  CUSTOMER_MANAGEMENT_EMAIL_REQUIRED: {
    en: "Email is required",
    de: "Die E-Mail-Adresse ist ein Pflichtfeld",
  },
  CUSTOMER_MANAGEMENT_USERNAME_REQUIRED: {
    en: "Username is required",
    de: "Der Benutzername ist ein Pflichtfeld",
  },
  CUSTOMER_MANAGEMENT_ADDRESS_LINE1_REQUIRED: {
    en: "Please enter Address Line 1",
    de: "Bitte die erste Adresszeile eingeben",
  },
  CUSTOMER_MANAGEMENT_ZIPCODE_REQUIRED: {
    en: "Please enter ZIP Code",
    de: "Bitte die Postleitzahl eingeben",
  },
  CUSTOMER_MANAGEMENT_CITY_REQUIRED: {
    en: "Please enter City",
    de: "Bitte die Stadt eingeben",
  },
  CUSTOMER_MANAGEMENT_COUNTRY_REQUIRED: {
    en: "Please enter Country",
    de: "Bitte das Land eingeben",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_SUCCESS_MESSAGE: {
    en: "Company added successfully!",
    de: "Firma erfolgreich hinzugefügt!",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_FAILED_MESSAGE: {
    en: "Cannot add company, try again!",
    de: "Firma kann nicht hinzugefügt werden, bitte versuche es erneut!",
  },
  CUSTOMER_MANAGEMENT_ADD_COMPANY_TITLE: {
    en: "Add New Company",
    de: "Neue Firma hinzufügen",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_SALUATION_REQUIRED: {
    en: "Please enter Salutation",
    de: "Bitte Anrede eingeben",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_FIRST_NAME_REQUIRED: {
    en: "First name is required",
    de: "Vorname ist ein Pflichtfeld",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_LAST_NAME_REQUIRED: {
    en: "Last name is required",
    de: "Nachname ist ein Pflichtfeld",
  },
  CUSTOMER_MANAGEMENT_ADD_CUSTOMER_FAILURE_MESSAGE: {
    en: "There was an issue creating contact, please try again!",
    de: "Beim Erstellen des Kontaktes ist ein Fehler aufgetreten, bitte versuchen Sie es noch mal!",
  },
  CUSTOMER_MANAGEMENT_COMMENTS_SUCCESS_MESSAGE: {
    en: "Comments added successfully",
    de: "Der Kommentar wurde erfolgreich gespeichert",
  },
  CUSTOMER_MANAGEMENT_COMMENTS_FAILURE_MESSAGE: {
    en: "Comments not added, Please try again!",
    de: "Beim Speichern des Kommentars ist ein Fehler aufgetreten, bitte versuchen Sie es noch mal!",
  },
  CUSTOMER_MANAGEMENT_CONTACT_SUCCESS_MESSAGE: {
    en: "Contact edited successfully!",
    de: "Der Kontakt wurde erfolgreich aktualisiert!",
  },
  CUSTOMER_MANAGEMENT_CONTACT_FAILURE_MESSAGE: {
    en: "Cannot edit contact, Please try again!",
    de: "Beim Aktualisieren des Kontaktes ist ein Fehler aufgetreten, bitte versuchen Sie es noch mal!",
  },
  CUSTOMER_MANAGEMENT_ADD_CONTACT_SUCCESS_MESSAGE: {
    en: "Contact added successfully!",
    de: "Der Kontakt wurde erfolgreich erstellt!",
  },
  CUSTOMER_MANAGEMENT_ADD_CONTACT_FAILURE_MESSAGE: {
    en: "Cannot add contact, Please try again!",
    de: "Beim Erstellen des Kontaktes ist ein Fehler aufgetreten, bitte versuchen Sie es noch mal!",
  },
  CUSTOMER_MANAGEMENT_PASSWORD_MATCHES_INFO: {
    en: "Password must be at least 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character!",
    de: "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten!",
  },
  CUSTOMER_MANAGEMENT_ADD_CONTACT_TITLE: {
    en: "Add Contact",
    de: "Kontakt erstellen",
  },
  CUSTOMER_MANAGEMENT_EDIT_CONTACT_TITLE: {
    en: "Edit Contact",
    de: "Kontakt bearbeiten",
  },
  CUSTOMER_MANAGEMENT_CONTACT_TYPE: {
    en: "Contact Type",
    de: "Kontaktdetails",
  },
  CUSTOMER_MANAGEMENT_CONTACT_DESCRIPTION: {
    en: "Description",
    de: "Beschreibung",
  },
  CUSTOMER_MANAGEMENT_CONTACT_DELETE_MESSAGE: {
    en: "Are you sure you want to delete this contact?",
    de: "Wollen Sie wirklich den ausgewählten Kontakt löschen?",
  },
  CUSTOMER_MANAGEMENT_CONTACT_DEREGISTER_MESSAGE: {
    en: "Are you sure you want to deregister this device?",
    de: "Wollen Sie wirklich dieses Gerät abmelden?",
  },
  CUSTOMER_MANAGEMENT_CONTACT_DELETE_SUCCESS_MESSAGE: {
    en: "Contact deleted successfully!",
    de: "Der Kontakt wurde erfolgreich gelöscht!",
  },
  CUSTOMER_MANAGEMENT_CONTACT_DEREGISTER_SUCCESS_MESSAGE: {
    en: "Device deregistered successfully!",
    de: "Gerät erfolgreich abgemeldet!",
  },
  CUSTOMER_MANAGEMENT_CONTACT_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete this contact, Please try again!",
    de: "Fehler beim Löschen des Kontaktes, bitte versuchen Sie es noch mal!",
  },
  CUSTOMER_MANAGEMENT_CONTACT_DEREGISTER_FAILURE_MESSAGE: {
    en: "Cannot deregister this device, Please try again!",
    de: "Fehler beim Abmelden des Geräts, bitte versuchen Sie es noch mal!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_ADD_SUCCESS_MESSAGE: {
    en: "Contact Details saved successfully!",
    de: "Die Kontaktdetails wurden erfolgreich gespeichert!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_ADD_FAILURE_MESSAGE: {
    en: "Cannot save user details, Please try again!",
    de: "Fehler beim Speichern der Kontaktdetails, bitte versuchen Sie es noch mal!",
  },
  CUSTOMER_MANAGEMENT_SEARCH_PLACEHOLDER: {
    en: "Search Customer",
    de: "Kunde suchen",
  },
  CONTACTS_TAB1: {
    en: "Profile",
    de: "Profil",
  },
  CONTACTS_TAB2: {
    en: "Security",
    de: "Sicherheit",
  },
  CONTACTS_TAB3: {
    en: "Purchases",
    de: "Käufe & Abonnements",
  },
  CONTACTS_TITLE: {
    en: "Contact Details",
    de: "Kontaktdetails",
  },
  CONTACTS_OVERVIEW: {
    en: "Contact overview",
    de: "Kontaktübersicht",
  },
  CONTACTS_FORMS_OVERVIEW: {
    en: "Contacts Forms",
    de: "Kontaktformulare",
  },
  CONTACTS_LIST_TITLE: {
    en: "CONTACTS",
    de: "Kontakte",
  },
  CONTACTS_OVERVIEW_TITLE: {
    en: "Overview",
    de: "Übersicht",
  },
  CONTACTS_EMAIL_CHANGE_SUCCESS: {
    en: "E-mail changed successfully",
    de: "E-Mail erfolgreich geändert",
  },
  CONTACTS_EMAIL_CHANGE_FAILURE: {
    en: "E-mail change unsuccessful",
    de: "Änderung der E-Mail fehlgeschlagen",
  },
  CONTACTS_EMAIL_CHANGE_VALIDATION: {
    en: "E-mail is required",
    de: "E-Mail ist ein Pflichtfeld",
  },
  CONTACTS_EMAIL_CHANGE_NEW_EMAIL_VALIDATION_MESSAGE: {
    en: "Please enter new e-mail",
    de: "Bitte neue E-Mail eingeben",
  },
  CONTACTS_EMAIL_CHANGE_TITLE: {
    en: "Change Email",
    de: "E-Mail ändern",
  },
  CONTACTS_EMAIL_CHANGE_NOT_VERIFIED: {
    en: "Email not verified",
    de: "E-Mail nicht verifiziert",
  },
  CONTACTS_ACCOUNT_VERIFIED: {
    en: "Account verified",
    de: "Konto verifiziert",
  },
  CONTACTS_EMAIL_CHANGE_LABEL: {
    en: "New Email",
    de: "Neue E-Mail",
  },
  CONTACTS_EMAIL_CHANGE_VERIFY_BTN: {
    en: "Verify",
    de: "Verifizieren",
  },
  CONTACTS_EMAIL_CHANGE_VERIFY_SUCCESS: {
    en: "Verification mail sent successful!",
    de: "Verifizierungs-E-Mail erfolgreich gesendet!",
  },
  CONTACTS_EMAIL_CHANGE_VERIFY_FAILURE: {
    en: "Verification mail can not be sent, Please try again!",
    de: "Verifizierungs-E-Mail kann nicht gesendet werden, bitte versuche es erneut!",
  },
  CONTACTS_EMAIL_CHANGE_CURRENT_EMAIL_LABEL: {
    en: "Current Email",
    de: "Aktuelle E-Mail",
  },
  CONTACTS_PASSWORD_CHANGE_SUCCESS: {
    en: "Password changed successfully",
    de: "Passwort erfolgreich geändert",
  },
  CONTACTS_PASSWORD_CHANGE_FAILURE: {
    en: "Password change unsuccessful",
    de: "Passwortänderung fehlgeschlagen",
  },
  CONTACTS_PASSWORD_CHANGE_VALIDATION: {
    en: "New password is required",
    de: "Neues Passwort ist erforderlich",
  },
  CONTACTS_PASSWORD_CHANGE_RETYPE_VALIDATION: {
    en: "Retype new password",
    de: "Neues Passwort wiederholen",
  },
  CONTACTS_PASSWORD_CHANGE_TITLE: {
    en: "Change Password",
    de: "Passwort ändern",
  },
  CONTACTS_PASSWORD_CHANGE_LABEL: {
    en: "New Password",
    de: "Neues Passwort",
  },
  CONTACTS_PASSWORD_CHANGE_RETYPE_LABEL: {
    en: "Retype New Password",
    de: "Neues Passwort wiederholen",
  },
  CONTACTS_LOGS_TITLE: {
    en: "Logs",
    de: "Anmeldungen",
  },
  CONTACTS_LOGS_HEADER1: {
    en: "Status",
    de: "Status",
  },
  CONTACTS_LOGS_HEADER2: {
    en: "Date",
    de: "Datum",
  },
  CONTACTS_LOGS_HEADER3: {
    en: "Details",
    de: "Details",
  },
  CONTACTS_LOGS_ERROR: {
    en: "Unable to fetch log data",
    de: "Logdaten können nicht abgerufen werden",
  },
  CONTACT_FORMS_LIST_PARTNER_LABEL: {
    en: "Partner",
    de: "Partner",
  },
  CONTACT_FORMS_LIST_TAGS_LABEL: {
    en: "Tags",
    de: "Stichwörter",
  },
  CONTACT_FORMS_LIST_FILTER_BUTTON: {
    en: "Filter",
    de: "Filter",
  },
  CONTACT_FORMS_LIST_FILTER_TITLE: {
    en: "Filter Options",
    de: "Filteroptionen",
  },
  CONTACT_FORMS_LIST_FILTER_TAGS_TITLE: {
    en: "Tags",
    de: "Stichwörter",
  },
  CONTACT_FORMS_LIST_EXPORT_FORM_BUTTON: {
    en: "Export Forms",
    de: "Formulare exportieren",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_MODAL_TITLE: {
    en: "Contact Form",
    de: "Kontaktformular",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_ADDRESS_LABEL: {
    en: "Address",
    de: "Adresse",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_PARTNER_LABEL: {
    en: "Partner",
    de: "Partner",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_CONTACT_INFO_LABEL: {
    en: "Contact Info",
    de: "Kontaktinformationen",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_EMAIL_LABEL: {
    en: "Email",
    de: "E-Mail",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_MOBILE_LABEL: {
    en: "Mobile",
    de: "Mobil",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_TELEPHONE_LABEL: {
    en: "Telephone",
    de: "Telefon",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_NAME_LABEL: {
    en: "Name",
    de: "Name",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_ADDRESS_1_LABEL: {
    en: "Address 1",
    de: "Adresse 1",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_ADDRESS_2_LABEL: {
    en: "Address 2",
    de: "Adresse 2",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_CITY_LABEL: {
    en: "City",
    de: "Stadt",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_ZIP_CODE_LABEL: {
    en: "Zip Code",
    de: "PLZ",
  },
  CONTACT_FORMS_LIST_FORM_PREVIEW_DESCRIPTION_LABEL: {
    en: "Description",
    de: "Beschreibung",
  },
  CONTACT_FORMS_LIST_EXCEL_SALUTATION_LABEL: {
    en: "Salutation",
    de: "Anrede",
  },
  CONTACT_FORMS_LIST_EXCEL_TITLE_LABEL: {
    en: "Title",
    de: "Titel",
  },
  CONTACT_FORMS_LIST_EXCEL_FIRSTNAME_LABEL: {
    en: "Firstname",
    de: "Vorname",
  },
  CONTACT_FORMS_LIST_EXCEL_LASTNAME_LABEL: {
    en: "Lastname",
    de: "Nachname",
  },
  CONTACT_FORMS_LIST_EXCEL_EMAIL_LABEL: {
    en: "Email",
    de: "E-Mail",
  },
  CONTACT_FORMS_LIST_EXCEL_MOBILE_LABEL: {
    en: "Mobile",
    de: "Mobil",
  },
  CONTACT_FORMS_LIST_EXCEL_TELEPHONE_LABEL: {
    en: "Telephone",
    de: "Telefon",
  },
  CONTACT_FORMS_LIST_EXCEL_PARTNER_LABEL: {
    en: "Partner",
    de: "Partner",
  },
  CONTACT_FORMS_LIST_EXCEL_ORIGIN_LABEL: {
    en: "Origin",
    de: "Herkunft",
  },
  CONTACT_FORMS_EXCEL_DOWNLOAD_SUCCESS_MESSAGE: {
    en: "Contact forms exported successfully!",
    de: "Kontaktformulare erfolgreich exportiert!",
  },
  CONTACT_FORMS_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete these contact form(s)?",
    de: "Möchtest du diese Kontaktformulare wirklich löschen?",
  },
  CONTACT_FORM_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this contact form?",
    de: "Möchtest du dieses Kontaktformular wirklich löschen?",
  },
  CONTACT_FORMS_EXPORT_SELECTED_BUTTON: {
    en: "Export selected",
    de: "Ausgewählte exportieren",
  },
  CONTACT_FORMS_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Contact forms deleted successfully",
    de: "Kontaktformulare erfolgreich gelöscht",
  },
  CONTACT_FORMS_MASS_DELETE_ERROR_MESSAGE: {
    en: "There was an issue deleting contact forms, Please try again",
    de: "Beim Löschen der Kontaktformulare ist ein Fehler aufgetreten, bitte versuche es erneut",
  },
  CONTACT_FORM_DELETE_SUCCESS_MESSAGE: {
    en: "Contact form deleted successfully",
    de: "Kontaktformular erfolgreich gelöscht",
  },
  CONTACT_FORM_DELETE_ERROR_MESSAGE: {
    en: "There was an issue deleting contact form, Please try again",
    de: "Beim Löschen des Kontaktformulars ist ein Fehler aufgetreten, bitte versuche es erneut",
  },
  ROUTES_CHECKS_OVERVIEW: {
    en: "Overview",
    de: "Übersicht",
  },
  CUSTOMER_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this address?",
    de: "Bist du sicher, dass du diese Adresse löschen möchtest?",
  },
  CUSTOMER_ADD_CUSTOMER_SUCCESS_MESSAGE: {
    en: "User added successfully!",
    de: "Benutzer erfolgreich hinzugefügt!",
  },
  CUSTOMER_ADDRESS_VALIDATION_MESSAGE: {
    en: "Please enter Address Line 1",
    de: "Bitte die erste Adresszeile eingeben",
  },
  CUSTOMER_ADDRESS_ZIP_CODE_VALIDATION_MESSAGE: {
    en: "ZIP Code must be less than 10",
    de: "Die Postleitzahl darf nicht mehr als 10 Zeichen enthalten",
  },
  CUSTOMER_ADDRESS_ZIP_CODE_VALIDATION_REQUIRED_MESSAGE: {
    en: "Please enter ZIP Code",
    de: "Bitte die Postleitzahl eingeben",
  },
  CUSTOMER_ADDRESS_CITY_VALIDATION_REQUIRED_MESSAGE: {
    en: "Please enter City",
    de: "Bitte die Stadt eingeben",
  },
  CUSTOMER_ADDRESS_COUNTRY_VALIDATION_REQUIRED_MESSAGE: {
    en: "Please enter Country",
    de: "Bitte das Land eingeben",
  },
  CUSTOMER_PERSONAL_ADDRESS_SUCCESS_MESSAGE: {
    en: "Address added successfully!",
    de: "Adresse erfolgreich hinzugefügt!",
  },
  CUSTOMER_PERSONAL_ADDRESS_FAILURE_MESSAGE: {
    en: "Cannot add address, try again!",
    de: "Adresse kann nicht hinzugefügt werden, bitte versuche es erneut!",
  },
  CUSTOMER_CONTACT_TYPES_CONTACT_VALIDATION_MESSAGE: {
    en: "Please select a contact",
    de: "Bitte einen Kontakt auswählen",
  },
  CUSTOMER_CONTACT_TYPES_DESCRIPTION_MAX_VALIDATION_MESSAGE: {
    en: "Maximum 60 symbols",
    de: "Maximal 60 Zeichen",
  },
  CUSTOMER_CONTACT_TYPES_DESCRIPTION_REQUIRED_VALIDATION_MESSAGE: {
    en: "Value is required",
    de: "Wert ist erforderlich",
  },
  CUSTOMER_CONTACT_TYPES_SUCCESS_MESSAGE: {
    en: "All contacts added already!",
    de: "Alle Kontakte bereits hinzugefügt!",
  },
  CUSTOMER_PROFILE_SALUATION_VALIDATION_MESSAGE: {
    en: "Please enter Salutation",
    de: "Bitte Anrede eingeben",
  },
  CUSTOMER_PROFILE_FIRSTNAME_VALIDATION_MESSAGE: {
    en: "First name is required",
    de: "Vorname ist ein Pflichtfeld",
  },
  CUSTOMER_PROFILE_LASTNAME_VALIDATION_MESSAGE: {
    en: "Last name is required",
    de: "Nachname ist ein Pflichtfeld",
  },
  CUSTOMER_PROFILE_CONFIRM_DELETE_MESSAGE: {
    en: "Are you sure you want to remove this file?",
    de: "Möchtest du diese Datei wirklich entfernen?",
  },
  CONTACTS_GENERAL_TITLE: {
    en: "General",
    de: "Allgemein",
  },
  BILLING_ADDRESS_DELETE_SUCCESS_MESSAGE: {
    en: "Address deleted successfully!",
    de: "Adresse erfolgreich gelöscht!",
  },
  BILLING_ADDRESS_DELETE_FAILURE_MESSAGE: {
    en: "There was an issue deleting this address, Please try again!",
    de: "Beim Löschen der Adresse ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  BILLING_ADDRESS_UPDATE_SUCCESS_MESSAGE: {
    en: "Address updated successfully!",
    de: "Adresse erfolgreich aktualisiert!",
  },
  BILLING_ADDRESS_UPDATE_FAILURE_MESSAGE: {
    en: "There was an issue updating this address, Please try again!",
    de: "Beim Aktualisieren der Adresse ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  BILLING_ADDRESS_COMPANY_DELETE_SUCCESS_MESSAGE: {
    en: "Address deleted successfully!",
    de: "Adresse erfolgreich gelöscht!",
  },
  BILLING_ADDRESS_COMPANY_DELETE_FAILURE_MESSAGE: {
    en: "There was an issue deleting this address, Please try again!",
    de: "Beim Löschen der Adresse ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  BILLING_PERSONAL_ADDRESS_TITLE: {
    en: "Personal Address",
    de: "Persönliche Adresse",
  },
  BILLING_COMPANY_ADDRESS_TITLE: {
    en: "Company Address",
    de: "Firmenadresse",
  },
  PARTNERS_LIST_SEARCH_VALIDATION_MESSAGE: {
    en: "Search text should be at least 3 characters",
    de: "Der Suchtext muss mindestens 3 Zeichen lang sein",
  },
  PARTNER_SELECT_MESSAGE: {
    en: "Select Organisation",
    de: "Organisation auswählen",
  },
  PARTNERS_LOGO_REMOVE: {
    en: "Yes, remove",
    de: "Ja, bitte löschen",
  },
  PARTNERS_LOGO_REMOVE_TOOLTIP: {
    en: "Remove logo",
    de: "Logo entfernen",
  },
  PARTNERS_LOGO_UPDATE_SUCCESS: {
    en: "Logo updated successfully!",
    de: "Logo erfolgreich aktualisiert!",
  },
  PARTNERS_LOGO_UPDATE_FAILURE: {
    en: "Can not update logo, please try again later!",
    de: "Logo kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  PARTNERS_LOGO_DELETE_SUCCESS: {
    en: "Logo is removed successfully!",
    de: "Das Logo wurde erfolgreich entfernt!",
  },
  PARTNERS_LOGO_DELETE_FAILURE: {
    en: "There was an issue removing logo, Please try again!",
    de: "Beim Entfernen des Logos ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  CONTACTS_FILTER: {
    en: "Filter Options",
    de: "Filteroptionen",
  },
  SELECT_CUSTOMER_MODAL_TITLE: {
    en: "Select Customer",
    de: "Kunde auswählen",
  },
  USERS_2FA_AUTHENTICATOR_CODE_MODAL_TITLE: {
    en: "Enter Authenticator Code",
    de: "Authenticator-Code eingeben",
  },
  USERS_2FA_RECOVERY_CODE_MODAL_TITLE: {
    en: "Enter Recovery Code",
    de: "Wiederherstellungscode eingeben",
  },
  USERS_2FA_GET_RECOVERY_CODES_BUTTON: {
    en: "Recovery Codes",
    de: "Wiederherstellungscodes",
  },
  USERS_2FA_GET_RECOVERY_CODES_ERROR_MESSAGE: {
    en: "Error fetching Recovery codes!",
    de: "Fehler beim Abrufen der Wiederherstellungscodes!",
  },
  USERS_2FA_RECOVERY_CODES_MODAL_TITLE: {
    en: "Recovery codes",
    de: "Wiederherstellungscodes",
  },
  USERS_2FA_RECOVERY_CODES_MODAL_COPY_CODE_MESSAGE: {
    en: "Click on code to copy!",
    de: "Klicken Sie auf den Code, um ihn zu kopieren!",
  },
  USERS_2FA_RECOVERY_CODE_COPY_SUCCESS_MESSAGE: {
    en: "Recovery code copied successfully!",
    de: "Wiederherstellungscode erfolgreich kopiert!",
  },
  USERS_2FA_RECOVERY_CODE_COPY_ERROR_MESSAGE: {
    en: "Recovery code copy failed!",
    de: "Kopieren des Wiederherstellungscodes fehlgeschlagen!",
  },
  USERS_2FA_AUTHENTICATOR_CODE_MODAL_RECOVERY_INFO: {
    en: "If you don't have access to Authenticator, Please click here to enter recovery code instead!",
    de: "Wenn Sie keinen Zugang zum Authenticator haben, klicken Sie bitte hier, um stattdessen den Wiederherstellungscode einzugeben!",
  },
  CATEGORY_REMOVE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to remove this picture?",
    de: "Möchten Sie wirklich das Bild entfernen?",
  },
  PARTNERS_ADD_NEW_INDUSTRY_GROUP_NAME_VALIDATION: {
    en: "Name is required",
    de: "Name ist erforderlich",
  },
  INDUSTRIES_GROUPS_ADD_SUCCESS_MESSAGE: {
    en: "Industries group added successfully!",
    de: "Branche erfolgreich hinzugefügt!",
  },
  INDUSTRIES_GROUPS_ADD_FAILURE_MESSAGE: {
    en: "Can not add Industries group, please try again later!",
    de: "Branche kann nicht hinzugefügt werden, bitte versuchen Sie es später erneut!",
  },
  INDUSTRIES_SECTORS_ADD_SUCCESS_MESSAGE: {
    en: "Industries sectors added successfully!",
    de: "Branchensektoren erfolgreich hinzugefügt!",
  },
  INDUSTRIES_SECTORS_ADD_FAILURE_MESSAGE: {
    en: "Can not add Industries sectors, please try again later!",
    de: "Branchensektoren können nicht hinzugefügt werden, bitte versuchen Sie es später erneut!",
  },
  REMOVE_FILE_MESSAGE: {
    en: "Cannot remove file!",
    de: "Datei kann nicht entfernt werden!",
  },
  MENU_TEAMS: {
    en: "TEAMS",
    de: "TEAM",
  },
  TEAMS_TITLE: {
    en: "TEAMS",
    de: "Team",
  },
  TEAMS_ADD_NEW_TEAM_TITLE: {
    en: "Add new team",
    de: "Neues Team hinzufügen",
  },
  TEAMS_DELETE_SUCCESS_MESSAGE: {
    en: "Teams deleted successfully!",
    de: "Team erfolgreich gelöscht!",
  },
  TEAMS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete teams, Please try again!",
    de: "Team können nicht gelöscht werden, bitte versuche es erneut!",
  },
  TEAMS_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Teams deleted successfully!",
    de: "Team erfolgreich gelöscht!",
  },
  TEAMS_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete teams, Please try again!",
    de: "Team können nicht gelöscht werden, bitte versuche es erneut!",
  },
  TEAMS_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this team?",
    de: "Bist du sicher, dass du dieses Team löschen möchtest?",
  },
  TEAMS_CREATED_SUCCESS_MESSAGE: {
    en: "Team created successfully!",
    de: "Team erfolgreich erstellt!",
  },
  TEAMS_CREATED_FAILURE_MESSAGE: {
    en: "Cannot create Team, Please try again later!",
    de: "Team kann nicht erstellt werden, bitte versuche es später erneut!",
  },
  TEAMS_UPDATED_SUCCESS_MESSAGE: {
    en: "Team updated successfully!",
    de: "Team erfolgreich aktualisiert!",
  },
  TEAMS_UPDATED_FAILURE_MESSAGE: {
    en: "Cannot update Team, Please try again later!",
    de: "Team kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  TEAMS_ADD_USER_BUTTON: {
    en: "Add user",
    de: "Benutzer hinzufügen",
  },
  SELECT_USERS_MODAL_TITLE: {
    en: "Select Users",
    de: "Benutzer auswählen",
  },
  PARTNERS_LOGO_REMOVE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to remove this logo?",
    de: "Bist du sicher, dass du dieses Logo entfernen möchtest?",
  },
  TEAMS_ADD_USER_SUCCESS_MESSAGE: {
    en: "Users added to team successfully!",
    de: "Benutzer erfolgreich zum Team hinzugefügt!",
  },
  TEAMS_ADD_USER_FAILURE_MESSAGE: {
    en: "Cannot add to team, Please try again later!",
    de: "Kann nicht zum Team hinzugefügt werden, bitte versuche es später erneut!",
  },
  TEAMS_USERS_TITLE: {
    en: "Team Users",
    de: "Team Mitglieder",
  },
  TEAM_USERS_NAME_TITLE: {
    en: "Name",
    de: "Name",
  },
  TEAM_USERS_ROLE_TITLE: {
    en: "Role",
    de: "Rolle",
  },
  TEAM_USERS_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this user?",
    de: "Bist du sicher, dass du diesen Benutzer löschen möchtest?",
  },
  TEAMS_NO_USERS_FOUND_MESSAGE: {
    en: "No users added to team.",
    de: "Keine Benutzer zum Team hinzugefügt.",
  },
  USER_MANAGEMENT_TEAMS_TITLE: {
    en: "User Teams",
    de: "Team",
  },
  USER_NO_TEAM_MESSAGE: {
    en: "Not a member of any Team",
    de: "Kein Mitglied eines Teams",
  },
  USER_TEAM_INFO: {
    en: "User is not joined in any of the teams yet. Click on join team to be a member of any one of the team.",
    de: "Der Benutzer ist noch keinem Team beigetreten. Klicke auf Team beitreten, um Mitglied eines Team zu werden.",
  },
  USER_TEAM_ADD_BUTTON: {
    en: "Join Team",
    de: "Team beitreten",
  },
  NO_ADDRESS_MESSAGE_INFO: {
    en: "Address details are missing for selected partner, please add them to reflect here.",
    de: "Adressdetails fehlen für den ausgewählten Partner, bitte füge diese hinzu, um sie hier anzuzeigen.",
  },
  MENU_SALES: {
    en: "MANAGEMENT",
    de: "VERWALTUNG",
  },
  MENU_PRODUCTS: {
    en: "PRODUCTS",
    de: "PRODUKTE",
  },
  PRODUCTS_TITLE: {
    en: "PRODUCTS",
    de: "Produkte",
  },
  ROUTES_PRODUCTS_EDIT: {
    en: "Edit Products",
    de: "Produkte bearbeiten",
  },
  PRODUCTS_OVERVIEW_NAME: {
    en: "Name",
    de: "Name",
  },
  PRODUCTS_PRICE_NAME: {
    en: "Price",
    de: "Preis",
  },
  PRODUCTS_STORAGE_LOCATION: {
    en: "Storage Location",
    de: "Lagerort",
  },
  PRODUCTS_PRODUCTTYPES_NAME: {
    en: "Product attributes",
    de: "Artikelmerkmale",
  },
  PRODUCTS_ADD_NEW_PRODUCT: {
    en: "Add New Product",
    de: "Neues Produkt hinzufügen",
  },
  ADD_PRODUCTS_NAME_REQUIRED_MESSAGAE: {
    en: "Name is required",
    de: "Name ist erforderlich",
  },
  ADD_PRODUCTS_CATEGORY_REQUIRED_MESSAGE: {
    en: "Category is required",
    de: "Kategorie ist erforderlich",
  },
  ADD_PRODUCTS_ADD_SUCCESS_MESSAGE: {
    en: "Product added successfully!",
    de: "Produkt erfolgreich hinzugefügt!",
  },
  ADD_PRODUCTS_ERROR_MESSAGE: {
    en: "Cannot add product, please try again later!",
    de: "Produkt kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  PRODUCTS_ADD_NEW_MODAL_NAME_FIELD: {
    en: "Name",
    de: "Name",
  },
  PRODUCTS_ADD_NEW_MODAL_CATEGORY_FIELD: {
    en: "Category",
    de: "Kategorie",
  },
  PRODUCTS_ADD_NEW_MODAL_TITLE: {
    en: "Add New Product",
    de: "Neues Produkt hinzufügen",
  },
  PRODUCTS_DELETE_POST_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete the product?",
    de: "Bist du sicher, dass du das Produkt löschen möchtest?",
  },
  PRODUCTS_DELETE_POST_MESSAGE: {
    en: "Product deleted successfully!",
    de: "Produkt erfolgreich gelöscht!",
  },
  PRODUCTS_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Product(s) deleted successfully!",
    de: "Produkt(e) erfolgreich gelöscht!",
  },
  PRODUCTS_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete Product(s), please try again later!",
    de: "Produkt(e) können nicht gelöscht werden, bitte versuche es später erneut!",
  },
  PRODUCTS_TAB1: {
    en: "Content",
    de: "Inhalt",
  },
  PRODUCTS_TAB2: {
    en: "Activity",
    de: "Aktivität",
  },
  PRODUCTS_TAB3: {
    en: "Files",
    de: "Dateien",
  },
  PRODUCTS_STOCK_TITLE: {
    en: "Stock",
    de: "Lagerbestand",
  },
  PRODUCTS_STOCK_CARD_TITLE: {
    en: "Price Details",
    de: "Preisinformationen",
  },
  PRODUCTS_GENERAL_DETAILS_TTILE: {
    en: "General Details",
    de: "Allgemeine Details",
  },
  PRODUCT_NAME_REQUIRED_MESSAGE: {
    en: "Name is required",
    de: "Name ist erforderlich",
  },
  PRODUCT_CATEGORY_REQUIRED_MESSAGE: {
    en: "Category is required",
    de: "Kategorie ist erforderlich",
  },
  PRODUCT_DESCRIOTION_REQUIRED_MESSAGE: {
    en: "Description is required",
    de: "Beschreibung ist erforderlich",
  },
  PRODUCT_GENERAL_DETAILS_SUCCESS_MESSAGE: {
    en: "Product general details updated successfully!",
    de: "Allgemeine Produktdetails erfolgreich aktualisiert!",
  },
  PRODUCT_GENERAL_DETAILS_FAILURE_MESSAGE: {
    en: "Cannot update, please try again later!",
    de: "Kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  PRODUCT_GENERAL_NAME_TITLE: {
    en: "Name",
    de: "Name",
  },
  PRODUCT_GENERAL_CATEGORY_TITLE: {
    en: "Category",
    de: "Kategorie",
  },
  PRODUCT_GENERAL_DESCRIPTION_TITLE: {
    en: "Description",
    de: "Beschreibung",
  },
  MASTERDATA_PRODUCT_TYPE_EDIT_TITLE: {
    en: "Edit product attribute",
    de: "Artikelmerkmal bearbeiten",
  },
  MASTERDATA_PRODUCT_TYPE_NEW_TITLE: {
    en: "Create new product attribute",
    de: "Neues Artikelmerkmal erstellen",
  },
  MASTERDATA_PRODUCT_TYPES_CREATE_SUCCESS_MESSAGE: {
    en: "Product attribute created successfully!",
    de: "Artikelmerkmal erfolgreich erstellt!",
  },
  MASTERDATA_PRODUCT_TYPE_CREATE_FAILURE_MESSAGE: {
    en: "Cannot create product attribute, please try again!",
    de: "Artikelmerkmal kann nicht erstellt werden, bitte versuche es erneut!",
  },
  MASTERDATA_PRODUCT_TYPE_EDIT_SUCCESS_MESSAGE: {
    en: "Product attribute name edited successfully!",
    de: "Artikelmerkmal erfolgreich bearbeitet!",
  },
  MASTERDATA_PRODUCT_TYPE_EDIT_FAILURE_MESSAGE: {
    en: "Cannot edit product attribute, please try again!",
    de: "Artikelmerkmal kann nicht bearbeitet werden, bitte versuche es erneut!",
  },
  MASTERDATA_PRODUCT_TYPE_DELETE_SUCCESS_MESSAGE: {
    en: "Product attribute deleted successfully!",
    de: "Artikelmerkmal erfolgreich gelöscht!",
  },
  MASTERDATA_PRODUCT_TYPE_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete product attribute, please try again!",
    de: "Artikelmerkmal kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_PRODUCT_TYPE_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Product attribute(s) deleted successfully!",
    de: "Artikelmerkmal(e) erfolgreich gelöscht!",
  },
  MASTERDATA_PRODUCT_TYPE_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete product attribute(s), please try again!",
    de: "Artikelmerkmal(e) können nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_PRODUCT_TYPE_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this product attribute?",
    de: "Bist du sicher, dass du dieses Artikelmerkmal löschen möchtest?",
  },
  MASTERDATA_PRODUCT_TYPES_TITLE: {
    en: "PRODUCT ATTRIBUTES",
    de: "ARTIKELMERKMALE",
  },
  MASTERDATA_PRODUCT_TYPE_CREATE_NEW_BUTTON: {
    en: "Add new product attributes",
    de: "Neue Merkmale hinzufügen",
  },
  MASTERDATA_PRODUCT_TYPE_PRODUCT_NAME_PLACEHOLDER: {
    en: "Enter product attributes name",
    de: "Artikelmerkmale eingeben",
  },
  PRODUCT_PRODUCTTYPES_HEADER: {
    en: "Product attributes",
    de: "Artikelmerkmale",
  },
  PRODUCT_PRODUCTTYPES_ADD_CONTACT_BUTTON: {
    en: "Add product attributes",
    de: "Artikelmerkmal hinzufügen",
  },
  PRODUCTS_NOTES_HEADER: {
    en: "Assemble Instructions",
    de: "Montageanleitung",
  },
  PRODUCTS_NOTES_UPDATE_SUCCESS: {
    en: "Instructions added successfully!",
    de: "Anleitung erfolgreich hinzugefügt!",
  },
  PRODUCTS_NOTES_UPDATE_FAILURE: {
    en: "There was an issue adding notes, Please try again!",
    de: "Beim Hinzufügen der Notizen ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  PRODUCT_MANUFACTURER_DETAILS_SUCCESS_MESSAGE: {
    en: "Product manufacturer details updated successfully!",
    de: "Produktionsdetails des Herstellers erfolgreich aktualisiert!",
  },
  PRODUCT_MANUFACTURER_DETAILS_FAILURE_MESSAGE: {
    en: "Cannot update, please try again later!",
    de: "Kann nicht aktualisiert werden, bitte versuchen Sie es später erneut!",
  },
  PRODUCTS_MANUFACTURER_DETAILS_TTILE: {
    en: "Manufacturer Details",
    de: "Herstellerdetails",
  },
  PRODUCT_MANUFACTURER_STORAGE_TITLE: {
    en: "Storage location",
    de: "Lagerort",
  },
  PRODUCT_MANUFACTURER_TITLE: {
    en: "Manufacturer",
    de: "Hersteller",
  },
  PRODUCT_MANUFACTURER_EAN_TITLE: {
    en: "EAN",
    de: "EAN",
  },
  PRODUCT_MANUFACTURER_ARTICLE_NUMBER_TITLE: {
    en: "Article Number",
    de: "Artikelnummer",
  },
  PRODUCT_STOCK_UPDATE_SUCCESS_MESSAGE: {
    en: "Selling price updated successfully!",
    de: "Verkaufspreis erfolgreich aktualisiert!",
  },
  PRODUCT_STOCK_UPDATE_FAILURE_MESSAGE: {
    en: "Cannot update the selling price, please try again later!",
    de: "Verkaufspreis kann nicht aktualisiert werden, bitte versuche ws später erneut!",
  },
  PRODUCT_ACTIVITY_HEADER: {
    en: "Activity",
    de: "Aktivität",
  },
  PRODUCT_ACTIVITY_INFO: {
    en: "Below is a breakdown of recent activity of the product.",
    de: "Unten ist eine Aufschlüsselung der letzten Aktivitäten des Produkts.",
  },
  PRODUCT_ADD_ACTIVITY_BUTTON: {
    en: "Add Activity",
    de: "Aktivität hinzufügen",
  },
  PRODUCT_ACTIVITY_DATE_REQUIRED_MESSAGE: {
    en: "Date is required",
    de: "Datum ist erforderlich",
  },
  PRODUCT_ACTIVITY_PRICE_REQUIRED_MESSAGE: {
    en: "Price is required",
    de: "Preis ist erforderlich",
  },
  PRODUCT_ACTIVITY_UNITS_REQUIRED_MESSAGE: {
    en: "Units is required",
    de: "Einheiten sind erforderlich",
  },
  PRODUCT_ACTIVITY_UNITS_MAX_REQUIRED_MESSAGE: {
    en: "Units should not be greater than stock.",
    de: "Einheiten sollten nicht größer als der Lagerbestand sein.",
  },
  PRODUCT_ACTIVITY_ADD_SUCCESS_MESSAGE: {
    en: "Product activity created successfully!",
    de: "Produktaktivität erfolgreich erstellt!",
  },
  PRODUCT_ACTIVITY_ADD_FAILURE_MESSAGE: {
    en: "Cannot create activity, please try again later!",
    de: "Aktivität kann nicht erstellt werden, bitte versuche es später erneut!",
  },
  PRODUCT_ACTIVITY_MODAL_TITLE: {
    en: "Add Product Activity",
    de: "Produktaktivität hinzufügen",
  },
  PRODUCT_ACTIVITY_MODAL_DATE_TITLE: {
    en: "Date",
    de: "Datum",
  },
  PRODUCT_ACTIVITY_MODAL_PRICE_TITLE: {
    en: "Price",
    de: "Preis",
  },
  PRODUCT_ACTIVITY_MODAL_UNITS_TITLE: {
    en: "Units",
    de: "Einheiten",
  },
  PRODUCT_ACTIVITY_MODAL_NOTES_TITLE: {
    en: "Notes",
    de: "Notizen",
  },
  PRODUCT_ACTIVITY_MODAL_INPUT_TITLE: {
    en: "Product Input",
    de: "Produkteingabe",
  },
  PRODUCT_DOCUMENTS_TITLE: {
    en: "Documents",
    de: "Dokumente",
  },
  PRODUCT_DOCUMENTS_TABLE_COLUMN1: {
    en: "Name",
    de: "Name",
  },
  PRODUCT_DOCUMENTS_TABLE_COLUMN2: {
    en: "Uploaded By",
    de: "Hochgeladen von",
  },
  PRODUCT_DOCUMENTS_TABLE_COLUMN3: {
    en: "Type",
    de: "Typ",
  },
  PRODUCT_DOCUMENTS_TABLE_COLUMN4: {
    en: "Date",
    de: "Datum",
  },
  PRODUCT_DROP_FILES_TITLE: {
    en: "Drop the files here",
    de: "Dateien hier ablegen",
  },
  PRODUCT_DRAG_AND_DROP_FILES_TITLE: {
    en: "Drag and Drop your files here.",
    de: "Dateien hierher ziehen und ablegen.",
  },
  PRODUCT_UPLOADING_FILES_TITLE: {
    en: "Uploading files....",
    de: "Dateien werden hochgeladen....",
  },
  PRODUCT_FILE_DELETE_CONFIRMATION_MESSAGE: {
    en: "Are you sure you want to delete this file(s)?",
    de: "Bist du sicher, dass du diese Datei(en) löschen möchtest?",
  },
  PRODUCT_FILE_ADD_SUCCESS_MESSAGE: {
    en: "File uploaded successfully!",
    de: "Datei erfolgreich hochgeladen!",
  },
  PRODUCT_FILE_ADD_FAILURE_MESSAGE: {
    en: "Cannot add file, Please try again later!",
    de: "Datei kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  PRODUCT_FILE_PREVIEW_TITLE: {
    en: "Preview",
    de: "Vorschau",
  },
  PRODUCT_PRODUCT_TYPES_NO_PRODUCT_TYPES: {
    en: "No product attribute added.",
    de: "Kein Artikelmerkmal hinzugefügt.",
  },
  PRODUCT_PRODUCTTYPES_ADD_SUCCESS_MESSAGE: {
    en: "Product types added successfully!",
    de: "Artikelmerkmal erfolgreich hinzugefügt!",
  },
  PRODUCT_PRODUCTTYPES_ADD_FAILURE_MESSAGE: {
    en: "Cannot add, please try again later!",
    de: "Kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  PRODUCT_PRODUCT_TYPE: {
    en: "Product attribute",
    de: "Artikelmerkmal",
  },
  PRODUCT_PRODUCTTYPE_INFO: {
    en: "Please enter values for selected product attribute.",
    de: "Bitte gib einen Wert für das ausgewählte Merkmal ein.",
  },
  PRODUCT_PRODUCT_TYPES_SUCCESS_MESSAGE: {
    en: "All product types added already!",
    de: "Alle Artikelmerkmalen bereits hinzugefügt!",
  },
  PRODUCT_PRODUCTTYPE_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete the product attribute?",
    de: "Bist du sicher, dass du das Artikelmerkmal löschen möchtest?",
  },
  PRODUCT_PRODUCTTYPE_DELETE_SUCCESS_MESSAGE: {
    en: "Product attribute delete successfully!",
    de: "Artikelmerkmal erfolgreich gelöscht!",
  },
  PRODUCT_PRODUCTTYPE_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete, please try again later!",
    de: "Kann nicht gelöscht werden, bitte versuche es später erneut!",
  },
  MENU_FIELDS: {
    en: "FIELDS",
    de: "EINGABEFELDER",
  },
  MASTERDATA_FIELDS_NAME_REQUIRED: {
    en: "Field name is required",
    de: "Feldname ist erforderlich",
  },
  MASTERDATA_FIELDS_TYPE_REQUIRED: {
    en: "Field type is required",
    de: "Feldtyp ist erforderlich",
  },
  MASTERDATA_EDIT_FIELDS_TITLE: {
    en: "Edit field",
    de: "Feld bearbeiten",
  },
  MASTERDATA_CREATE_NEW_FIELD_TITLE: {
    en: "Create new field",
    de: "Neues Feld erstellen",
  },
  MASTERDATA_FIELD_NAME_FIELD_NAME: {
    en: "Name",
    de: "Name",
  },
  MASTERDATA_FIELD_TYPE_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "text", title: "Text" },
      { id: 2, name: "longtext", title: "Long Text" },
      { id: 3, name: "date", title: "Date" },
      { id: 4, name: "dropdown", title: "Dropdown" },
      { id: 5, name: "radio", title: "Radio" },
      { id: 6, name: "checkbox", title: "Checkbox" },
      { id: 7, name: "uploader", title: "Uploader" },
      { id: 8, name: "product", title: "Product" },
      { id: 9, name: "number", title: "Number" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "text", title: "Text" },
      { id: 2, name: "longtext", title: "Textbox" },
      { id: 3, name: "date", title: "Datum" },
      { id: 4, name: "dropdown", title: "Auswahlfeld" },
      { id: 5, name: "radio", title: "Radio" },
      { id: 6, name: "checkbox", title: "Checkbox" },
      { id: 7, name: "uploader", title: "Upload" },
      { id: 8, name: "product", title: "Produkt" },
      { id: 9, name: "number", title: "" },
    ]),
  },
  MASTERDATA_FIELD_TYPE: {
    en: "Field type",
    de: "Feldtyp",
  },
  MASTERDATA_FIELDS_ADD_SUCCESS_MESSAGE: {
    en: "Field added successfully!",
    de: "Feld erfolgreich hinzugefügt!",
  },
  MASTERDATA_FIELDS_ADD_FAILURE_MESSAGE: {
    en: "Can not add field, please try again later!",
    de: "Feld kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  MASTERDATA_FIELDS_DELETE_SUCCESS_MESSAGE: {
    en: "Field deleted successfully!",
    de: "Feld erfolgreich gelöscht!",
  },
  MASTERDATA_FIELDS_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete field, please try again later!",
    de: "Feld kann nicht gelöscht werden, bitte versuche es später erneut!",
  },
  MASTERDATA_FIELDS_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Fields mass deleted successfully!",
    de: "Felder erfolgreich massenhaft gelöscht!",
  },
  MASTERDATA_FIELDS_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Can not mass delete field, please try again later!",
    de: "Felder können nicht massenhaft gelöscht werden, bitte versuche es später erneut!",
  },
  MASTERDATA_FIELDS_SELECTED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete selected fields?",
    de: "Bist du sicher, dass du die ausgewählten Felder löschen möchtest?",
  },
  MASTERDATA_FIELD_ADD_NEW_BUTTON: {
    en: "Add new Field",
    de: "Neues Feld hinzufügen",
  },
  MASTERDATA_FIELD_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete team(s)?",
    de: "Bist du sicher, dass du das Team/die Teams löschen möchtest?",
  },
  TEAM_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this team?",
    de: "Bist du sicher, dass du dieses Team löschen möchtest?",
  },
  USERS_AUTH_2FA_VERIFY_CODE_VALIDATION_MESSAGE: {
    en: "Verification code is required",
    de: "Verifizierungscode ist erforderlich",
  },
  MENU_PROJECTS: {
    en: "EDITOR",
    de: "EDITOR",
  },
  MENU_DEALS: {
    en: "DEALS",
    de: "DEALS",
  },
  MENU_PIPELINES: {
    en: "PIPELINES",
    de: "PIPELINES",
  },
  MENU_PANELS: {
    en: "PANELS",
    de: "PANELS",
  },
  MENU_TABS: {
    en: "TABS",
    de: "REITER",
  },
  PRODUCTS_AVG_BOUGHT_PRICE_TITLE: {
    en: "Avg bought price",
    de: "Durchschnittlicher Kaufpreis",
  },
  PRODUCTS_SELLING_PRICE_TITLE: {
    en: "Selling price",
    de: "Verkaufspreis",
  },
  PRODUCTS_GROSS_PROFIT_TITLE: {
    en: "Gross profit",
    de: "Bruttogewinn",
  },
  PRODUCTS_ACTIVITY_ADDED_TEXT: {
    en: "Added",
    de: "Hinzugefügt",
  },
  PRODUCTS_ACTIVITY_REMOVED_TEXT: {
    en: "Removed",
    de: "Entfernt",
  },
  PRODUCTS_ACTIVITY_TO_TEXT: {
    en: "to",
    de: "zu",
  },
  PRODUCTS_ACTIVITY_FROM_TEXT: {
    en: "from",
    de: "von",
  },
  PRODUCTS_ACTIVITY_PRICE_TEXT: {
    en: "of price €",
    de: "zum Preis von €",
  },
  FIELDS_IS_FIXED_TITLE: {
    en: "Hide delete Button for field",
    de: "Löschfunktion für Feld ausblenden",
  },
  FIELDS_OPTIONS_TITLE: {
    en: "Options",
    de: "Optionen",
  },
  FIELDS_OPTIONS_ADD_OPTIONS_TITLE: {
    en: "Add options",
    de: "Optionen hinzufügen",
  },
  MASTERDATA_TABS_TAG_NAME_REQUIRED: {
    en: "Tab name is required",
    de: "Bitte gib einen Reiternamen ein",
  },
  MASTERDATA_TABS_CREATE_SUCCESS_MESSAGE: {
    en: "Tab Created Successfully!",
    de: "Reiter erfolgreich erstellt!",
  },
  MASTERDATA_TABS_CREATE_FAILURE_MESSAGE: {
    en: "Cannot create tab, Please try again!",
    de: "Reiter kann nicht erstellt werden, bitte versuche es erneut!",
  },
  MASTERDATA_TABS_EDIT_SUCCESS_MESSAGE: {
    en: "Tab name updated successfully!",
    de: "Reitername erfolgreich aktualisiert!",
  },
  MASTERDATA_TABS_EDIT_FAILURE_MESSAGE: {
    en: "Cannot edit tab, Please try again!",
    de: "Reiter kann nicht bearbeitet werden, bitte versuche es erneut!",
  },
  MASTERDATA_TABS_EDIT_TITLE: {
    en: "Edit tab",
    de: "Reiter bearbeiten",
  },
  MASTERDATA_TABS_EDIT_NEW_TITLE: {
    en: "Create new tab",
    de: "Neuen Reiter erstellen",
  },
  MASTERDATA_TABS_DELETE_SUCCESS_MESSAGE: {
    en: "Tab deleted successfully!",
    de: "Reiter erfolgreich gelöscht!",
  },
  MASTERDATA_TABS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete tab, Please try again!",
    de: "Reiter kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_TABS_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Tab(s) deleted successfully!",
    de: "Reiter(s) erfolgreich gelöscht!",
  },
  MASTERDATA_TABS_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete tab(s), Please try again!",
    de: "Reiter(s) können nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_TABS_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this Tab?",
    de: "Bist du sicher, dass du diesen Reiter löschen möchtest?",
  },
  MASTERDATA_TABS_SEARCH_PLACEHOLDER: {
    en: "Search Tabs",
    de: "Reiter durchsuchen",
  },
  MASTERDATA_TABS_SELECTED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete selected Tab(s)?",
    de: "Bist du sicher, dass du die ausgewählten Reiter(s) löschen möchtest?",
  },
  MASTERDATA_TABS_CREATE_NEW_BUTTON: {
    en: "Create new Tab",
    de: "Neuen Reiter erstellen",
  },
  MASTERDATA_TABS_TAB_NAME_FIELD_NAME: {
    en: "Name",
    de: "Name",
  },
  MASTERDATA_TABS_TAB_NAME_PLACEHOLDER: {
    en: "Enter tab name",
    de: "Reitername eingeben",
  },
  MENU_EDIT_TABS: {
    en: "Tab Details",
    de: "Details",
  },
  MASTER_DATA_PANELS_TYPE: {
    en: JSON.stringify([
      { id: 1, name: "Single Panels" },
      { id: 2, name: "Two Split Panels" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "Einspaltig" },
      { id: 2, name: "Zweispaltig" },
    ]),
  },
  MASTER_DATA_SECTIONS_TYPE: {
    en: JSON.stringify([
      { id: 1, name: "Single Sections" },
      { id: 2, name: "Right Section" },
      { id: 3, name: "Left Section" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "Einzelne Abschnitte" },
      { id: 2, name: "Rechter Abschnitt" },
      { id: 3, name: "Linker Abschnitt" },
    ]),
  },
  MASTER_DATA_PANELS_TITLE: {
    en: "Select Panel",
    de: "Panel auswählen",
  },
  MASTER_DATA_SECTIONS_TITLE: {
    en: "Select Sections",
    de: "Abschnitte auswählen",
  },
  MASTER_DATA_ADD_PANEL_TITLE: {
    en: "Add Panel",
    de: "Panel hinzufügen",
  },
  MASTER_DATA_FIELDS_TITLE: {
    en: "Select Fields",
    de: "Felder auswählen",
  },
  SELECT_FIELDS_MODAL_TITLE: {
    en: "Select Fields",
    de: "Felder auswählen",
  },
  MASTERDATA_TAB_PANEL_ADD_SUCCESS_MESSAGE: {
    en: "Panel create successfully!",
    de: "Panel erfolgreich erstellt!",
  },
  MASTERDATA_TAB_PANEL_ADD_FAILURE_MESSAGE: {
    en: "Can not create panel, please try again later!",
    de: "Panel kann nicht erstellt werden, bitte versuche es später erneut!",
  },
  MASTERDATA_TAB_PANEL_FIELD_ADD_SUCCESS_MESSAGE: {
    en: "Field added successfully!",
    de: "Feld erfolgreich hinzugefügt!",
  },
  MASTERDATA_TAB_PANEL_FIELD_ADD_FAILURE_MESSAGE: {
    en: "Can not add field, please try again later!",
    de: "Feld kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  MASTERDATA_TAB_PANEL_DELETE_SUCCESS_MESSAGE: {
    en: "Panel Deleted successfully!",
    de: "Panel erfolgreich gelöscht!",
  },
  MASTERDATA_TAB_PANEL_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete panel, please try again!",
    de: "Panel kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_TAB_PANEL_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure want to delete the panel?",
    de: "Bist du sicher, dass du das Panel löschen möchtest?",
  },
  PROJECTS_PIPELINE_ADD_NEW_BUTTON: {
    en: "Add new Pipeline",
    de: "Neue Pipeline hinzufügen",
  },
  PROJECTS_PIPELINE_SEARCH_PLACEHOLDER: {
    en: "Search pipeline",
    de: "Pipeline durchsuchen",
  },
  PROJECTS_PIPELINE_ADD_SUCCESS_MESSAGE: {
    en: "Pipeline added successfully!",
    de: "Pipeline erfolgreich hinzugefügt!",
  },
  PROJECTS_PIPELINE_ADD_FAILURE_MESSAGE: {
    en: "Can not add pipeline, please try again later!",
    de: "Pipeline kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  PROJECTS_PIPELINE_UPDATE_SUCCESS_MESSAGE: {
    en: "Pipeline updated successfully!",
    de: "Pipeline erfolgreich aktualisiert!",
  },
  PROJECTS_PIPELINE_UPDATE_FAILURE_MESSAGE: {
    en: "Can not update pipeline, please try again later!",
    de: "Pipeline kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  PROJECTS_PIPELINE_EDIT_TITLE: {
    en: "Edit Pipeline",
    de: "Pipeline bearbeiten",
  },
  PROJECTS_PIPELINE_NAME_FIELD: {
    en: "Pipeline name",
    de: "Pipelinename",
  },
  PROJECTS_PIPELINE_NAME_FIELD_PLACEHOLDER: {
    en: "Enter pipeline name",
    de: "Pipelinename eingeben",
  },
  PROJECTS_PIPELINE_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this Phase phase?",
    de: "Bist du sicher, dass du diese Phase löschen möchtest?",
  },
  PROJECTS_PIPELINE_NAME_REQUIRED: {
    en: "Name is required",
    de: "Name erforderlich",
  },
  PROJECTS_PIPELINE_TRANSITION_TYPE_REQUIRED: {
    en: "Transition type is required",
    de: "Übergangstyp erforderlich",
  },
  PROEJCTS_PIPELINE_DELETE_SUCCESS_MESSAGE: {
    en: "Pipeline(s) deleted successfully!",
    de: "Pipeline(s) erfolgreich gelöscht!",
  },
  PROEJCTS_PIPELINE_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete pipeline(s), please try again later!",
    de: "Pipeline(s) können nicht gelöscht werden, bitte versuche es später erneut!",
  },
  PROJECTS_PIPELINES_TITLE: {
    en: "Pipelines",
    de: "Pipelines",
  },
  PROJECTS_EDIT_PIPELINES_TITLE: {
    en: "Edit Pipelines",
    de: "Pipelines bearbeiten",
  },
  PROJECTS_PIPELINES_MULTI_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete selected pipeline(s)?",
    de: "Bist du sicher, dass du die ausgewählten Pipeline(s) löschen möchtest?",
  },
  PROJECTS_PIPELINE_PHASES_ADD_SUCCESS_MESSAGE: {
    en: "Phase added successfully!",
    de: "Phase erfolgreich hinzugefügt!",
  },
  PROJECTS_PIPELINE_PHASES_ADD_FAILURE_MESSAGE: {
    en: "Can not add phase, please try again later!",
    de: "Phase kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  PROJECTS_PHASE_ADD_MODAL_TITLE: {
    en: "Add phase",
    de: "Phase hinzufügen",
  },
  PROJECTS_PIPELINE_PHASE_NAME_FIELD: {
    en: "Name",
    de: "Name",
  },
  PROJECTS_PIPELINE_PHASE_NAME_FIELD_PLACEHOLDER: {
    en: "Enter phase name",
    de: "Phasenname eingeben",
  },
  PROJECTS_PIPELINE_PHASE_IS_REQUIRED_FIELD: {
    en: "Is phase required?",
    de: "Ist Phase erforderlich?",
  },
  PROJECTS_PIPELINE_PHASE_DELETE_SUCCESS_MESSAGE: {
    en: "Phase deleted successfully!",
    de: "Phase erfolgreich gelöscht!",
  },
  PROJECTS_PIPELINE_PHASE_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete phase, please try again later!",
    de: "Phase kann nicht gelöscht werden, bitte versuche es später erneut!",
  },
  PROJECTS_PIPELINE_PHASE_TAB_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this phase tab?",
    de: "Bist du sicher, dass du diesen Reiter aus der Phase löschen möchtest?",
  },
  PROJECTS_PIPELINE_PHASE_TAB_DELETE_SUCCESS_MESSAGE: {
    en: "Phase tab deleted successfully!",
    de: "Reiter erfolgreich gelöscht!",
  },
  PROJECTS_PIPELINE_PHASE_TAB_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete phase tab, please try again later!",
    de: "Reiter kann nicht gelöscht werden, bitte versuche es später erneut!",
  },
  PROJECTS_PIPELINE_ROLE_UPDATE_SUCCESS_MESSAGE: {
    en: "Roles updated successfully!",
    de: "Rollen erfolgreich aktualisiert!",
  },
  PROJECTS_PIPELINE_ROLE_UPDATE_FAILURE_MESSAGE: {
    en: "Can not update Roles, please try again later!",
    de: "Rollen können nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  PROJECTS_PIPELINE_PHASE_SUCCESS_MESSAGE: {
    en: "Phase updated successfully!",
    de: "Phase erfolgreich aktualisiert!",
  },
  PROJECTS_PIPELINE_PHASE_FAILURE_MESSAGE: {
    en: "Can not update Phase, please try again later!",
    de: "Phase kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  PROJECTS_PIPELINE_NAME_SUCCESS_MESSAGE: {
    en: "Pipeline name updated successfully!",
    de: "Pipelinename erfolgreich aktualisiert!",
  },
  PROJECTS_PIPELINE_NAME_FAILURE_MESSAGE: {
    en: "Can not update pipeline name, please try again later!",
    de: "Pipelinename kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  PROJECTS_PIPELINE_TAB_ADD_SUCCESS_MESSAGE: {
    en: "Tab added successfully!",
    de: "Reiter erfolgreich hinzugefügt!",
  },
  PROJECTS_PIPELINE_TAB_ADD_FAILURE_MESSAGE: {
    en: "Can not add Tab, please try again later!",
    de: "Reiter kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  PROJECTS_PHASES_TITLE: {
    en: "Phases",
    de: "Phasen",
  },
  PROJECTS_PHASE_ADD_PHASE_TITLE: {
    en: "Add Phase",
    de: "Phase hinzufügen",
  },
  PROJECTS_PIPELINE_TAB_INFO: {
    en: "click to see tab details",
    de: "Klicke um die Abschnitte und Felder in diesem Reiter zu sehen",
  },
  PROJECTS_PIPELINE_TAB_INFO1: {
    en: "click here.",
    de: "hier klicken.",
  },
  PROJECTS_PIPELINE_TABS_TITLE: {
    en: "Tabs",
    de: "Reiter",
  },
  GO_BACK_BUTTON: {
    en: "Go Back",
    de: "Zurück",
  },
  PREVIEW_BUTTON: {
    en: "Preview",
    de: "Vorschau",
  },
  MASTERDATA_TABS_PANELS_DRAG_INFO: {
    en: "Drag and drop the Panels",
    de: "Panels ziehen und ablegen",
  },
  MASTERDATA_TABS_PANELS_TITLE: {
    en: "Panels",
    de: "Panels",
  },
  MASTERDATA_TABS_PANELS_FIELDS_TITLE: {
    en: "Fields",
    de: "Felder",
  },
  MASTERDATA_TABS_PANEL_HEADING_UPDATE_SUCCESS_MESSAGE: {
    en: "Panel heading updated successfully!",
    de: "Panelüberschrift erfolgreich aktualisiert!",
  },
  MASTERDATA_TABS_PANEL_HEADING_UPDATE_FAILURE_MESSAGE: {
    en: "Can not update panel heading, please try again later!",
    de: "Panelüberschrift kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  DEALS_SEARCH_PLACEHOLDER: {
    en: "Search deals",
    de: "Deals durchsuchen",
  },
  DEALS_ADD_NEW_BUTTON: {
    en: "Add new Deal",
    de: "Deal hinzufügen",
  },
  DEAL_VIEW_TYPES: {
    en: JSON.stringify([
      { id: 1, name: "List" },
      { id: 2, name: "Grid" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "Liste" },
      { id: 2, name: "Kaban" },
    ]),
  },
  DEAL_ADD_NEW_TITLE: {
    en: "Add new Deal",
    de: "Deal hinzufügen",
  },
  DEAL_NAME_FIELD: {
    en: "Deal name",
    de: "Dealname",
  },
  DEAL_NAME_FIELD_PLACEHOLDER: {
    en: "Enter deal name",
    de: "Dealname eingeben",
  },
  DEAL_STARTED_DATE_FIELD: {
    en: "Start Date",
    de: "Startdatum",
  },
  DEAL_ADD_SUCCESS_MESSAGE: {
    en: "Deal added successfully!",
    de: "Deal erfolgreich hinzugefügt!",
  },
  DEAL_ADD_FAILURE_MESSAGE: {
    en: "Can not add deal, please try again later!",
    de: "Deal kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  DEAL_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete the Deal?",
    de: "Bist du sicher, dass du den Deal löschen möchtest?",
  },
  DEAL_EDIT_TITLE: {
    en: "Edit deal",
    de: "Deal bearbeiten",
  },
  DEAL_DETAILS_TAB1: {
    en: "Content",
    de: "Inhalt",
  },
  DEAL_DETAILS_TAB2: {
    en: "Logs",
    de: "Protokolle",
  },
  DEAL_DETAILS_TITLE: {
    en: "Deal Details",
    de: "Dealdetails",
  },
  DEAL_ERROR_MODAL_TITLE: {
    en: "Errors and Warnings",
    de: "Fehler und Warnungen",
  },
  PROJECTS_PIPELINE_PHASE_IS_ENDPHASE_FIELD: {
    en: "Is this EndPhase?",
    de: "Ist das eine Endphase?",
  },
  DEAL_STATUS_TYPES: {
    en: JSON.stringify([
      { id: 1, name: "inProgress", value: "InProgress" },
      { id: 2, name: "finished", value: "Finished" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "inProgress", value: "In Bearbeitung" },
      { id: 2, name: "finished", value: "Abgeschlossen" },
    ]),
  },
  TAGS_COLOR_NAME: {
    en: "Colour",
    de: "Farbe",
  },
  EDIT_PIPELINE_ROLE_INFO_MESSAGE1: {
    en: "This Phase is accessed by",
    de: "Diese Phase wird von",
  },
  EDIT_PIPELINE_ROLE_INFO_MESSAGE2: {
    en: "This Phase can be accessed by all the users.",
    de: "Auf diese Phase kann von allen Benutzern zugegriffen werden.",
  },
  EDIT_PIPELINE_USERS_INFO: {
    en: "users",
    de: "Benutzer",
  },
  DEALS_MOVE_SUCCESS_MESSAGE: {
    en: "Deal moved successfully!",
    de: "Deal erfolgreich verschoben!",
  },
  DEAL_MOVE_ANOTHER_PIPELINE_SUCCESS_MESSAGE: {
    en: "Deal completed successfully and moved to selected pipeline.",
    de: "Deal erfolgreich abgeschlossen und in die ausgewählte Pipeline verschoben.",
  },
  DEAL_MOVE_ANOTHER_PIPELINE_FAILURE_MESSAGE: {
    en: "Can not move the deal to selected pipeline, please try again later!",
    de: "Deal kann nicht in die ausgewählte Pipeline verschoben werden, bitte versuche es später erneut!",
  },
  DEAL_LIST_VIEW_COLUMN1: {
    en: "Deal Name",
    de: "Dealname",
  },
  DEAL_LIST_VIEW_COLUMN2: {
    en: "Status",
    de: "Status",
  },
  DEAL_LIST_VIEW_COLUMN3: {
    en: "Progress",
    de: "Fortschritt",
  },
  DEAL_LIST_VIEW_COLUMN4: {
    en: "Days",
    de: "Tage",
  },
  DEAL_LIST_VIEW_COLUMN5: {
    en: "Responsible Persons",
    de: "Verantwortliche Personen",
  },
  DEAL_LIST_VIEW_COLUMN6: {
    en: "Organisation",
    de: "Partner",
  },
  DEAL_LIST_VIEW_COLUMN7: {
    en: "Contact",
    de: "Kontakt",
  },
  DEAL_LIST_VIEW_COMPLETED_MESSAGE: {
    en: "Deal completed",
    de: "Angebot abgeschlossen",
  },
  DEAL_LIST_VIEW_PHASES_PENDING_MESSAGE: {
    en: "Phases Pending",
    de: "Ausstehende Phasen",
  },
  DEAL_FILTER_MODAL_TITLE: {
    en: "Select Filters",
    de: "Filter auswählen",
  },
  DEAL_FILTER_MODAL_RESPONSIBLE_PERSONS_TITLE: {
    en: "Responsible Persons",
    de: "Verantwortliche Personen",
  },
  DEAL_FILTER_MODAL_STATUS_TITLE: {
    en: "Status",
    de: "Status",
  },
  DEAL_RESPONSIBLE_USER_ADD_SUCCESS_MESSAGE: {
    en: "Responsible user added successfully!",
    de: "Verantwortlicher Benutzer erfolgreich hinzugefügt!",
  },
  DEAL_RESPONSIBLE_USER_ADD_FAILURE_MESSAGE: {
    en: "Can not add responsible person, please try again later!",
    de: "Verantwortliche Person kann nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  DEAL_RESPONSIBLE_USER_DELETE_SUCCESS_MESSAGE: {
    en: "Responsible user deleted successfully!",
    de: "Verantwortlicher Benutzer erfolgreich gelöscht!",
  },
  DEAL_RESPONSIBLE_USER_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete responsible person, please try again later!",
    de: "Verantwortliche Person kann nicht gelöscht werden, bitte versuche es später erneut!",
  },
  DEAL_NAME_UPDATE_SUCCESS_MESSAGE: {
    en: "Deal name updated successfully!",
    de: "Dealname erfolgreich aktualisiert!",
  },
  DEAL_NAME_UPDATE_FAILURE_MESSAGE: {
    en: "Can not update deal, please try again later!",
    de: "Deal kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  DEAL_MOVE_DEAL_BUTTON: {
    en: "Move Deal",
    de: "Deal verschieben",
  },
  DEAL_MISSING_FIELDS_LOADING_MESSAGE: {
    en: "Getting the updated required fields, please wait...",
    de: "Die aktualisierten erforderlichen Felder werden geladen, bitte warten...",
  },
  DEAL_NO_FIELDS_MESSAGE: {
    en: "Please add tabs view fields.",
    de: "Bitte mind. einen Reiter hinzufügen.",
  },
  DEAL_FIELD_UPDATE_SUCCESS_MESSAGE: {
    en: "Field value saved successfully!",
    de: "Feldwert erfolgreich gespeichert!",
  },
  DEAL_FIELD_UPDATE_FAILURE_MESSAGE: {
    en: "Can not save value, please try again later!",
    de: "Wert kann nicht gespeichert werden, bitte versuche es später erneut!",
  },
  DEAL_FIELD_UPLOAD_FILE_SUCCESS_MESSAGE: {
    en: "File(s) uploaded successfully!",
    de: "Datei(en) erfolgreich hochgeladen!",
  },
  DEAL_FIELD_UPLOAD_FILE_FAILURE_MESSAGE: {
    en: "Can not upload file(s), please try again later!",
    de: "Datei(en) können nicht hochgeladen werden, bitte versuche es später erneut!",
  },
  DEAL_INFO_BOX_TITLE: {
    en: "Info Box",
    de: "Info-Box",
  },
  DEAL_RESPONSIBLE_PERSON_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete the Responsible Person?",
    de: "Bist du sicher, dass du die verantwortliche Person löschen möchtest?",
  },
  DEAL_END_PHASE_TRANSITION_TITLE: {
    en: "Select End Phase Transition",
    de: "Endphasen-Übergang auswählen",
  },
  DEAL_END_PHASE_TRANSITION_INFO: {
    en: "Are you sure you want to move this deal to the next pipeline? Please select one pipeline from the below dropdown.",
    de: "Bist du sicher, dass du diesen Deal in die nächste Pipeline verschieben möchtest? Bitte wähle eine Pipeline aus dem Dropdown-Menü aus.",
  },
  DEAL_PIPELINE_TITLE: {
    en: "Pipeline",
    de: "Pipeline",
  },
  DEAL_ERROR_MODAL_INFO: {
    en: "Please resolve the issues shown below before moving to another phase.",
    de: "Bitte behebe alle unten aufgeführten Probleme, bevor du in eine andere Phase übergehst.",
  },
  DEAL_ERROR_MODAL_INFO1: {
    en: "Required field values are missing, please complete all the required fields in each tab before moving to another phase.",
    de: "Erforderliche Feldwerte fehlen, bitte fülle alle erforderlichen Felder in jedem Tab aus, bevor du in eine andere Phase übergehst.",
  },
  DEAL_ERROR_MODAL_GO_TO_EDIT_PAGE: {
    en: "Go to Edit Page",
    de: "Zur Bearbeitungsseite",
  },
  DEAL_SELECT_RESPONSIBLE_TITLE: {
    en: "Select Responsible Persons",
    de: "Verantwortliche Personen auswählen",
  },
  DEAL_SELECT_RESPONSIBLE_INFO: {
    en: "In addition to appointing a user as a Responsible person, you will have to select at least one method to select users.",
    de: "Zusätzlich zur Ernennung eines Benutzers als verantwortliche Person, muss mindestens eine Methode zur Auswahl gewählt werden.",
  },
  DEAL_SELECT_RESPONSIBLE_SINGLE_USER_TITLE: {
    en: "Single User Selection",
    de: "Einzelne Benutzerauswahl",
  },
  DEAL_SELECT_RESPONSIBLE_SINGLE_USER_INFO: {
    en: "By selecting a single user, the user will be appointed as responsible persons for this particular deal.",
    de: "Durch die Auswahl eines einzelnen Benutzers wird dieser Benutzer als verantwortliche Person für dieses spezielle Angebot ernannt.",
  },
  DEAL_SELECT_RESPONSIBLE_TEAM_USER_TITLE: {
    en: "Team Selection",
    de: "Teamauswahl",
  },
  DEAL_SELECT_RESPONSIBLE_TEAM_USER_INFO: {
    en: "By selecting a single team, all the users of the team will be appointed as responsible persons for this particular deal.",
    de: "Durch die Auswahl eines einzelnen Teams werden alle Benutzer des Teams als verantwortliche Personen für dieses spezielle Angebot ernannt.",
  },
  DEAL_SELECT_TEAMS_TITLE: {
    en: "Select Teams",
    de: "Teams auswählen",
  },
  MENU_DEALS_ARCHIVED: {
    en: "ARCHIVED",
    de: "ARCHIVIERT",
  },
  DEALS_ARCHIVED_TITLE: {
    en: "ARCHIVED DEALS",
    de: "Archivierte Deals",
  },
  ARCHIVED_DEALS_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  ARCHIVED_DEALS_LIST_SELECTED: {
    en: "Selected",
    de: "Ausgewählt",
  },
  ARCHIVED_DEALS_RESTORE_SELECTED: {
    en: "Restore selected",
    de: "Ausgewählte wiederherstellen",
  },
  ARCHIVED_DEAL_NAME: {
    en: "Deal Name",
    de: "Dealname",
  },
  ARCHIVED_DEAL_CREATED_AT: {
    en: "Created at",
    de: "Erstellt am",
  },
  ARCHIVED_DEAL_DELETED_AT: {
    en: "Deleted at",
    de: "Gelöscht am",
  },
  ARCHIVED_DEAL_RESTORE: {
    en: "Restore",
    de: "Wiederherstellen",
  },
  ARCHIVED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this permanently? Your files will be lost and cannot be recovered.",
    de: "Bist du sicher, dass du diese dauerhaft löschen möchtest?",
  },
  ARCHIVED_DELETE_SUCCESS_MESSAGE: {
    en: "Deal deleted successfully!",
    de: "Deal erfolgreich gelöscht!",
  },
  ARCHIVED_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete deal, please try again later.",
    de: "Deal kann nicht gelöscht werden, bitte versuche es später erneut.",
  },
  ARCHIVED_RESTORE_SUCCESS_MESSAGE: {
    en: "Deal(s) restored successfully!",
    de: "Deal(s) erfolgreich wiederhergestellt!",
  },
  ARCHIVED_RESTORE_FAILURE_MESSAGE: {
    en: "Can not restore deal(s), please try again later.",
    de: "Deal(s) kann nicht wiederhergestellt werden, bitte versuche es später erneut.",
  },
  TAB_FIELD_LOGS: {
    en: "Field Logs",
    de: "Phasen Protokoll",
  },
  MENU_GOOGLE: {
    en: "GOOGLE",
    de: "GOOGLE",
  },
  GOOGLE_ACCOUNT_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Durchsuchen",
  },
  GOOGLE_ACCOUNT_DELETE_SUCCESS: {
    en: "Account deleted successfully!",
    de: "Konto erfolgreich gelöscht!",
  },
  GOOGLE_ACCOUNT_DELETE_FAILURE: {
    en: "There was an issue deleting your account, please try again!",
    de: "Beim Löschen deines Kontos ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  GOOGLE_HEADER_PROJECTS: {
    en: "Accounts",
    de: "Konten",
  },
  GOOGLE_NAME: {
    en: "Name",
    de: "Name",
  },
  GOOGLE_CLIENT_ID: {
    en: "Client ID",
    de: "Client ID",
  },
  GOOGLE_ADD_ACCOUNT: {
    en: "+ Add Google account",
    de: "+ Google-Konto hinzufügen",
  },
  GOOGLE_APP_NAME_VALIDATION: {
    en: "App name is required",
    de: "App-Name ist erforderlich",
  },
  GOOGLE_CLIENT_ID_VALIDATION: {
    en: "Client ID is required",
    de: "Client ID ist erforderlich",
  },
  GOOGLE_URL_VALIDATION: {
    en: "URL is required",
    de: "URL ist erforderlich",
  },
  GOOGLE_PROJECT_ID_VALIDATION: {
    en: "Project ID is required",
    de: "Projekt-ID ist erforderlich",
  },
  GOOGLE_ADD_ACCOUNT_SUCCESS: {
    en: "Account added successfully!",
    de: "Konto erfolgreich hinzugefügt!",
  },
  GOOGLE_ADD_ACCOUNT_FAILURE: {
    en: "There was an issue adding your account!",
    de: "Beim Hinzufügen deines Kontos ist ein Fehler aufgetreten!",
  },
  GOOGLE_DELETE_ACCOUNT_CONFIRM: {
    en: "Are you sure you want to delete this account?",
    de: "Bist du sicher, dass du dieses Konto löschen möchtest?",
  },
  GOOGLE_ACCOUNT_UPDATE_SUCCESS: {
    en: "Google account updated successfully!",
    de: "Google-Konto erfolgreich aktualisiert!",
  },
  GOOGLE_ACCOUNT_UPDATE_FAILURE: {
    en: "There was an issue updating your account, please try again!",
    de: "Beim Aktualisieren deines Kontos ist ein Fehler aufgetreten, bitte versuche es erneut!",
  },
  GOOGLE_NEW_ACCOUNT: {
    en: "Add Google account",
    de: "Google-Konto hinzufügen",
  },
  GOOGLE_EDIT_ACCOUNT: {
    en: "Edit Google account",
    de: "Google-Konto bearbeiten",
  },
  GOOGLE_APP_NAME: {
    en: "App name",
    de: "App-Name",
  },
  GOOGLE_SCOPE: {
    en: "Scope",
    de: "Scope",
  },
  GOOGLE_CLIENT_SECRET: {
    en: "Client secret",
    de: "Client secret",
  },
  GOOGLE_CONNECT_FAILURE_MESSAGE: {
    en: "Can not connect account, please try later!",
    de: "Konto kann nicht verbunden werden, bitte versuche es später erneut!",
  },
  RESET_BUTTON: {
    en: "Reset",
    de: "Zurücksetzen",
  },
  CONNECT_BUTTON: {
    en: "Connect",
    de: "Verbinden",
  },
  DISCONNECT_BUTTON: {
    en: "Disconnect",
    de: "Trennen",
  },
  SYNC_BUTTON: {
    en: "Sync",
    de: "Synchronisieren",
  },
  SELECT_ACCOUNTS_TITLE: {
    en: "Select account",
    de: "Konto auswählen",
  },
  CONNECTED_LIST_ACCOUNT_NAME: {
    en: "Account",
    de: "Konto",
  },
  GOOGLE_AUTH_CHECK_CONNECTING: {
    en: "Connecting to Google...",
    de: "Verbindung zu Google wird hergestellt...",
  },
  GOOGLE_AUTH_CHECK_CONNECTING_MESSAGE: {
    en: "Please wait until the window is closed. If the window is not closed, please close this window and reload the application",
    de: "Bitte warte, bis das Fenster geschlossen ist. Wenn das Fenster nicht geschlossen wird, schließe bitte dieses Fenster und lade die Anwendung neu",
  },
  TEAMS_EDIT_MODAL_TITLE: {
    en: "Edit teams",
    de: "Teams bearbeiten",
  },
  TEAMS_NEW_MODAL_TITLE: {
    en: "Create new Team",
    de: "Neues Team erstellen",
  },
  GOOGLE_CALLBACK_URL: {
    en: "Callback URL",
    de: "Callback-URL",
  },
  CONNECTED_ACCOUNTS_GOOGLE_TITLE: {
    en: "Interface",
    de: "Schnittstellen",
  },
  MENU_CALENDAR: {
    en: "CALENDAR",
    de: "KALENDER",
  },
  GOOGLE_DISCONNECT_CONFIRM_MESSAGE: {
    en: "Are you sure you want to disconnect the account?",
    de: "Bist du sicher, dass das Konto getrennt werden soll?",
  },
  GOOGLE_DISCONNECT_SUCCESS_MESSAGE: {
    en: "Google account disconnected successfully!",
    de: "Google-Konto erfolgreich getrennt!",
  },
  GOOGLE_DISCONNECT_FAILURE_MESSAGE: {
    en: "Can not disconnect account, please try again later!",
    de: "Konto kann nicht getrennt werden, bitte versuche es später erneut!",
  },
  GOOGLE_ACCOUNT_AUTHORIZE_SUCCESS_MESSAGE: {
    en: "Google Account connected successfully!",
    de: "Google-Konto erfolgreich verbunden!",
  },
  GOOGLE_EVENT_SUMMARY_TITLE: {
    en: "Summary",
    de: "Zusammenfassung",
  },
  GOOGLE_EVENT_DESCRIPTION_TITLE: {
    en: "Description",
    de: "Beschreibung",
  },
  GOOGLE_EVENT_LOCATION_TITLE: {
    en: "Location",
    de: "Standort",
  },
  GOOGLE_EVENT_START_DATE_TITLE: {
    en: "Start Date Time",
    de: "Startdatum und -zeit",
  },
  GOOGLE_EVENT_END_DATE_TITLE: {
    en: "End Date Time",
    de: "Enddatum und -zeit",
  },
  GOOGLE_ADD_EVENT_TITLE: {
    en: "Create Event",
    de: "Veranstaltung erstellen",
  },
  DEAL_RESPONSIBLE_TABLE_NAME: {
    en: "Responsible Persons",
    de: "Verantwortliche Personen",
  },
  PARTNER_NO_ADDRESS_MESSAGE: {
    en: "No Address added to the partner, please add",
    de: "Keine Adresse zum Partner hinzugefügt, bitte hinzufügen",
  },
  ADD_NEW_DEAL_NOTE: {
    en: "Please select at least one of the below organisation (or) contact",
    de: "Bitte wähle mindestens einen der unten aufgeführten Organisation (oder) Kontakt aus",
  },
  ADD_NEW_DEAL_PARTNER_TITLE: {
    en: "Select Organisation",
    de: "Partner auswählen",
  },
  ADD_NEW_DEAL_CONTACT_TITLE: {
    en: "Select Contact",
    de: "Kontakt auswählen",
  },
  ADD_DEAL_PARTNER_PLACEHOLDER: {
    en: "Please select organisation",
    de: "Bitte Partner auswählen",
  },
  ADD_DEAL_CONTACT_PLACEHOLDER: {
    en: "Please select contact",
    de: "Bitte Kontakt auswählen",
  },
  DEAL_SELECT_PRODUCT_CATEGORY_MODAL_TITLE: {
    en: "Select Category",
    de: "Kategorie auswählen",
  },
  DEAL_SELECT_PRODUCT_MODAL_TITLE: {
    en: "Select Product",
    de: "Produkt auswählen",
  },
  CALENDAR_TITLE: {
    en: "Calendar",
    de: "Kalender",
  },
  CALENDAR_ADD_EVENT_TITLE: {
    en: "+ Add Event",
    de: "+ Event hinzufügen",
  },
  PIPELINE_TRANSITION_TYPE_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "free-flow", title: "Free Flow" },
      { id: 2, name: "step-by-step", title: "Step By Step" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "free-flow", title: "Free Flow" },
      { id: 2, name: "step-by-step", title: "Schritt für Schritt" },
    ]),
  },
  PIPELINE_ADD_TRANSITION_TITLE: {
    en: "Transition type",
    de: "Übergangstyp",
  },
  CALENDAR_EVENT_EDIT_TITLE: {
    en: "Event Details",
    de: "Eventdetails",
  },
  CALENDAR_EVENT_DELETE_SUCCESS_MESSAGE: {
    en: "Event deleted successfully!",
    de: "Event erfolgreich gelöscht!",
  },
  CALENDAR_EVENT_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete event, please try again later!",
    de: "Eintrag kann nicht gelöscht werden, bitte versuche es später erneut!",
  },
  CALENDAR_EVENT_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete this event?",
    de: "Bist du sicher, dass du den Eintrag löschen möchtest?",
  },
  ADD_NEW_DEAL_PARTNER_CONTACT_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "Organisation" },
      { id: 2, name: "Contact" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "Partner" },
      { id: 2, name: "Kontakt" },
    ]),
  },
  EDIT_DEAL_TRANSITION_CONFIRM_MESSAGE: {
    en: "Are you sure you want to move the deal to this phase?",
    de: "Bist du sicher, dass du den Deal in diese Phase verschieben möchtest?",
  },
  EDIT_DEAL_MOVE_BUTTON_INFO_MESSAGE: {
    en: "You can move this deal to next phase.",
    de: "Du kannst den Deal in die nächste Phase verschieben.",
  },
  EDIT_DEAL_ISSUES_INFO: {
    en: "Click to see issues",
    de: "Klicke hier um die Felder zu sehen",
  },

  EDIT_DEAL_STEP_BY_STEP_ERROR_MESSAGE: {
    en: "You can not skip phases as this is a Step by Step pipeline, Please complete all the phases.",
    de: "Du kannst keine Phasen überspringen, da dies eine Schritt-für-Schritt-Pipeline ist. Bitte vervollständige alle Phasen.",
  },
  SIGN_IN_TITLE: {
    en: "Sign In",
    de: "Anmeldung",
  },
  SIGN_IN_EMAIL: {
    en: "Email",
    de: "E-Mail",
  },
  SIGN_IN_PASSWORD: {
    en: "Password",
    de: "Passwort",
  },
  AUTH_LOGIN_FORGOT_PASSWORD: {
    en: "Forgot Password ?",
    de: "Passwort vergessen?",
  },
  AUTH_LOGIN_CONTINUE: {
    en: "Continue",
    de: "Anmelden",
  },
  WRONG_EMAIL_FORMAT: {
    en: "Wrong email format",
    de: "Falsches E-Mail format",
  },
  EMAIL_IS_REQUIRED: {
    en: "Email is required",
    de: "E-Mail wird benötigt",
  },
  SIGN_IN_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  ACCOUNT_ACCOUNT_SETTINGS: {
    en: "Account Settings",
    de: "Account Einstellungen",
  },
  ACCOUNT_SIGN_OUT: {
    en: "Sign Out",
    de: "Ausloggen",
  },

  AUTH_NEW_PASSWORD_CONTINUE: {
    en: "Continue",
    de: "Fortsetzen",
  },

  AUTH_REGISTRATION_PASSWORD: {
    en: "Password",
    de: "Passwort",
  },

  AUTH_EMAIL_SUCCESS_SUB_TITLE: {
    en: "An email has been sent to registered address. Now check your inbox to reset your password.",
    de: "Eine E-Mail wurde an die registrierte Adresse gesendet. Überprüfe jetzt deinen Posteingang, um dein Passwort zurückzusetzen.",
  },

  AUTH_REGISTRATION_PASSWORD_MESSAGE: {
    en: "Use 8 or more characters with a mix of letters, numbers & symbols.",
    de: "Verwende 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen.",
  },

  AUTH_REGISTRATION_CONFIRM_PASSWORD: {
    en: "Confirm Password",
    de: "Passwort wiederholen",
  },

  FORM_VALIDATION_FIELD_CONDITION_REQUIRED: {
    en: "Condition is required!",
    de: "Bedingung ist erforderlich!",
  },

  AUTH_REGISTRATION_LAST_NAME: {
    en: "Last name",
    de: "Nachname",
  },

  FORM_VALIDATION_WRONG_ZIPCODE_FORMAT: {
    en: "Zipcode should contain only numbers",
    de: "Postleitzahl sollte nur Zahlen enthalten",
  },

  AUTH_REGISTRATION_CANCEL: {
    en: "Cancel",
    de: "Abbrechen",
  },

  AUTH_REGISTRATION_TITLES_DR: {
    en: "Dr.",
    de: "Dr.",
  },

  FORM_VALIDATION_CURRENT_ORDER_NUMBER_FORMAT: {
    en: "Current order number",
    de: "Aktuelle Bestellnummer",
  },

  AUTH_FORGOT_PASSWORD_EMAIL_MESSAGE: {
    en: "Enter your email to reset your password!",
    de: "Bitte gib deine E-Mail-Adresse ein, um das Passwort zurückzusetzen!",
  },

  PRODUCT_PRODUCTTYPE_DELETE__SUCCESS_MESSAGE: {
    en: "Product type delete successfully!",
    de: "Artikelmerkmal erfolgreich gelöscht!",
  },

  AUTH_REGISTRATION_TITLES_PROF_DR: {
    en: "Prof. Dr.",
    de: "Prof. Dr.",
  },

  AUTH_CODE_VERIFICATION_CODE: {
    en: "Code",
    de: "Code",
  },

  AUTH_REGISTRATION_TITLE: {
    en: "Create an Account",
    de: "Benutzerkonto erstellen",
  },

  AUTH_NEW_PASSWORD_NEW_PASSWORD: {
    en: "New Password",
    de: "Neues Passwort",
  },

  AUTH_REGISTRATION_TITLES_PROF: {
    en: "Prof.",
    de: "Prof.",
  },

  MASERDATA_FIELD_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete this field?",
    de: "Bist du sicher, dass du dieses Feld löschen möchtest?",
  },

  AUTH_REGISTRATION_SALUTATION_MR: {
    en: "Mr",
    de: "Herr",
  },
  AUTH_REGISTRATION_SALUTATION_MRS: {
    en: "Mrs.",
    de: "Frau",
  },

  AUTH_REGISTRATION_EMAIL: {
    en: "Email",
    de: "E-Mail",
  },

  CONTACTS_FORMS_LIST_TITLE: {
    en: "Contacts Forms",
    de: "Kontaktformulare",
  },

  DEAL_FIELD_UPDATE_SUCCESS_MESSAFE: {
    en: "Field Value saved successfully!",
    de: "Feldwert erfolgreich gespeichert!",
  },

  AUTH_REGISTRATION_ALREADY_HAVE: {
    en: "Already have an account?",
    de: "Hast du bereits ein Benutzerkonto?",
  },

  FORM_VALIDATION_FIELD_ZIPCODE_REQUIRED: {
    en: "Zipcode is required!",
    de: "Postleitzahl ist erforderlich!",
  },
  AUTH_REGISTRATION_SUBMIT: {
    en: "Submit",
    de: "Absenden",
  },
  FORM_VALIDATION_FIELD_ORDER_NUMBER_REQUIRED: {
    en: "Order number required!",
    de: "Bestellnummer erforderlich!",
  },
  FORM_VALIDATION_FIELD_CITY_REQUIRED: {
    en: "City is required!",
    de: "Stadt ist erforderlich!",
  },
  FORM_VALIDATION_WRONG_PHONE_FORMAT: {
    en: "Phone number should contain only numbers",
    de: "Telefonnummer sollte nur Zahlen enthalten",
  },
  AUTH_CODE_VERIFICATION_CODE_MESSAGE: {
    en: "Enter your code sent to email.",
    de: "Gib deinen per E-Mail gesendeten Code ein.",
  },
  AUTH_CODE_VERIFICATION_ERROR_MESSAGE: {
    en: "Sorry, looks like the code is incorrect, please try again.",
    de: "Entschuldigung, der Code scheint falsch zu sein. Bitte versuche es erneut.",
  },
  AUTH_CODE_VERIFICATION_TITLE: {
    en: "Code Verification",
    de: "Code-Verifizierung",
  },

  AUTH_EMAIL_SUCCESS_LOGIN: {
    en: "Login",
    de: "Anmelden",
  },

  AUTH_EMAIL_SUCCESS_NO_EMAIL: {
    en: "No email? Be sure to check your spam folder.",
    de: "Keine E-Mail? Überprüfen Sie Ihren Spam-Ordner.",
  },

  AUTH_EMAIL_SUCCESS_TITLE: {
    en: "Reset Account Password",
    de: "Account-Passwort zurücksetzen",
  },

  AUTH_FORGOT_PASSWORD_ERROR_MESSAGE: {
    en: "Sorry, looks like the email is not registered, please try again.",
    de: "Entschuldigung, die E-Mail scheint nicht registriert zu sein. Bitte versuche es erneut.",
  },

  AUTH_FORGOT_PASSWORD_SUCCESS_MESSAGE: {
    en: "Sent password reset. Please check your email",
    de: "Passwort zurücksetzen gesendet. Bitte überprüfe deine Mailbox",
  },

  AUTH_FORGOT_PASSWORD_TITLE: {
    en: "Forgot Password?",
    de: "Passwort vergessen?",
  },

  AUTH_NEW_PASSWORD_CONFIRM_PASSWORD: {
    en: "Confirm Password",
    de: "Passwort wiederholen",
  },

  AUTH_REGISTRATION_TITLES: {
    en: "Titles",
    de: "Titel",
  },

  DEAL_PIPLINE_TITLE: {
    en: "Pipeline",
    de: "Pipeline",
  },

  FORM_VALIDATION_FIELD_ADDRESS_LINE_1_REQUIRED: {
    en: "Address line 1 is required!",
    de: "Adresszeile 1 ist erforderlich!",
  },

  FORM_VALIDATION_FIELD_AND_CONDITION_TASK_MESSAGE: {
    en: "Please select at least one task!",
    de: "Bitte wähle mindestens eine Aufgabe aus!",
  },

  FORM_VALIDATION_FIELD_DEPENDENT_TYPE_REQUIRED: {
    en: "Dependent type is required!",
    de: "Abhängiger Typ ist erforderlich!",
  },

  FORM_VALIDATION_FIELD_EXPECTED_COUNT_REQUIRED: {
    en: "Expected count is required!",
    de: "Erwartete Anzahl ist erforderlich!",
  },

  FORM_VALIDATION_FIELD_EXPECTED_DURATION_REQUIRED: {
    en: "Expected duration is required!",
    de: "Erwartete Dauer ist erforderlich!",
  },

  FORM_VALIDATION_FIELD_OR_CONDITION_TASK_MESSAGE: {
    en: "Please select at least two tasks!",
    de: "Bitte wähle mindestens zwei Aufgaben aus!",
  },

  FORM_VALIDATION_FIELD_STREET_REQUIRED: {
    en: "Street is required!",
    de: "Straße ist erforderlich!",
  },

  FORM_VALIDATION_FIELD_TARGET_COUNT_REQUIRED: {
    en: "Target count is required!",
    de: "Zielanzahl ist erforderlich!",
  },

  FORM_VALIDATION_FIELD_TARGET_DURATION_REQUIRED: {
    en: "Target duration is required!",
    de: "Zieldauer ist erforderlich!",
  },

  FORM_VALIDATION_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },

  FORM_VALIDATION_MAX_ORDER_NUMBER_FORMAT: {
    en: "Order number should not be more than",
    de: "Bestellnummer darf nicht größer sein als",
  },

  FORM_VALIDATION_MIN_ORDER_NUMBER_FORMAT: {
    en: "Order number should not be less than 1",
    de: "Bestellnummer darf nicht kleiner sein als 1",
  },

  FORM_VALIDATION_SEARCH_TEXT_CHARACTER_INFO: {
    en: "Search text should be at least 3 characters",
    de: "Suchtext sollte mindestens 3 Zeichen lang sein",
  },

  MASERDATA_LABEL_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure , you want to delete this label?",
    de: "Bist du sicher, dass du dieses Label löschen möchtest?",
  },

  PRODUCT_PRODUCTTYPE_DELETE__FAILURE_MESSAGE: {
    en: "Product attribute could not be deleted!",
    de: "Artikelmerkmal konnte nicht gelöscht werden!",
  },

  PROJECTS_PIPELINE_NAME_SUCCES_MESSAGE: {
    en: "Pipeline saved successfully!",
    de: "Pipeline erfolgreich gespeichert!",
  },

  PROJECTS_PIPELINE_PHASE_SUCCES_MESSAGE: {
    en: "Pipeline phase saved successfully!",
    de: "Pipelinephase erfolgreich gespeichert!",
  },

  PROJECTS_PIPELINE_ROLE_UPDATE_SUCCES_MESSAGE: {
    en: "Roles updated successfully!",
    de: "Rollen erfolgreich aktualisiert!",
  },

  PROJECTS_PIPELINE_TAB_ADD_SUCCES_MESSAGE: {
    en: "Tab added successfully!",
    de: "Reiter erfolgreich hinzugefügt!",
  },
  AUTH_FORGOT_PASSWORD_EMAIL: {
    en: "Email",
    de: "E-Mail",
  },
  AUTH_NEW_PASSWORD_TITLE: {
    en: "Password Recovery",
    de: "Passwort wiederherstellen",
  },

  AUTH_REGISTRATION_LOGIN: {
    en: "Login",
    de: "Anmeldung",
  },

  AUTH_REGISTRATION_SALUTATION: {
    en: "Salutation",
    de: "Anrede",
  },
  AUTH_REGISTRATION_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  DEAL_FIELD_UPDATE_FAILURE_MESSAFE: {
    en: "Can not save the value, please try again later!",
    de: "Der Wert konnte nicht gespeichert werden, bitte versuche es später erneut!",
  },
  PIPELINE_DETAILS_EDIT_TITLE: {
    en: "Details",
    de: "Details",
  },
  PIPELINE_DETAILS_GENERAL_DETAILS_TTILE: {
    en: "General Details",
    de: "Allgemeine Details",
  },
  PIPELINE_DETAILS_PIPELINE_NAME: {
    en: "Pipeline name",
    de: "Pipelinename",
  },
  PIPELINE_DETAILS_TRANSITION_TYPE: {
    en: "Transition type",
    de: "Übergangstyp",
  },
  DRAG_AND_DROP_MESSAGE: {
    en: "drag and drop the tabs here",
    de: "Ziehe die Reiter hierher",
  },
  PIPELINE_PROJECTS_QUESTION1: {
    en: " This phase is End phase?",
    de: " Ist dies die Endphase?",
  },
  PIPELINE_PROJECTS_QUESTION2: {
    en: " This phase is accessible in mobile app ?",
    de: " Ist diese Phase in der App zugänglich?",
  },
  LOGS_NOT_CREATED_MESSAGE: {
    en: "Logs not created",
    de: "Protokolle nicht erstellt",
  },
  SEE_MORE_MESSAGE: {
    en: "...see more",
    de: "...mehr",
  },
  DEAL_PARTNER_DETAILS: {
    en: "Partner Details",
    de: "Partnerdetails",
  },
  DEAL_CONTACT_DETAILS: {
    en: "Contact Details",
    de: "Kontaktdetails",
  },
  DEAL_TAG_ADD_SUCCESS_MESSAGE: {
    en: "Tag added successfully!",
    de: "Tag erfolgreich hinzugefügt!",
  },
  DEAL_TAG_ADD_FAILURE_MESSAGE: {
    en: "Can not add tag, please try again later!",
    de: "Tag konnte nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  NO_SECTION_MESSAGE: {
    en: "No sections to display.",
    de: "Keine Abschnitte zum Anzeigen.",
  },
  NO_FIELDS_MESSAGE: {
    en: "No fields to display.",
    de: "Keine Felder zum Anzeigen.",
  },
  DEALS_FILTER_TAGS_TITLE: {
    en: "Tags",
    de: "Tags",
  },
  PIPELINE_INFO: {
    en: "Click to see pipeline details",
    de: "Klicke hier um die Pipelinedetails anzuzeigen",
  },
  PIPELINE_MODAL_STEP_BY_STEP_INFO: {
    en: "In a step-by-step transition type pipeline, the process is linear and sequential, meaning you must complete each phase in order before moving to the next one. For each phase, outline the specific requirements and deliverables that need to be completed before moving to the next phase. In this process is set up so that skipping phases is not possible.Can not move back to previous phases once you’ve moved forward.",
    de: "Beim Übergangstyp Schritt-für-Schritt ist der Prozess linear und sequenziell, was bedeutet, dass du die Phasen der Reihe nach abschließen musst. Für jede Phase kannst du Pflichtfelder festlegen, welche ausgefüllt sein müssen, bevor es zur nächsten Phase geht. In diesem Prozess ist das Überspringen von Phasen nicht möglich. Ein Zurückgehen zu vorherigen Phasen ist nicht möglich, wenn diese abgeschlossen ist.",
  },
  PIPELINE_MODAL_FREE_FLOW_INFO: {
    en: "In a free-flow transition type pipeline, the process is free, meaning you can move from any phase to any other phase. For each phase, outline the specific requirements and deliverables that need to be completed before moving to the next phase. In this process is set up so that skipping phases is not possible.Can able to move back to previous phases once you’ve moved forward.",
    de: "Beim Übergangstyp Free-Flow ist der Prozess frei, was bedeutet, dass du von einer Phase zur anderen wechseln kannst. Für jede Phase kannst du Pflichtfelder festlegen, die ausgefüllt sein müssen, bevor zur nächsten Phase gegangen werden kann. In diesem Prozess ist das Überspringen von Phasen nicht möglich. Ein Zurückgehen zu vorherigen Phasen ist möglich.",
  },
  USER_CONNECTED_ACCCOUNTS: {
    en: "Connected Accounts",
    de: "Verbundene Accounts",
  },
  DEALS_EDIT_ADD_TAG_TITLE: {
    en: " + Add Tag",
    de: " + Tag hinzufügen",
  },
  YES_MOVE_MESSAGE: {
    en: "Yes, move",
    de: "Ja, verschieben",
  },
  PIPELINE_PROJECTS_QUESTION3: {
    en: "Need approvals to move this phase ?",
    de: "Sind Genehmigungen erforderlich, um diese Phase zu verschieben?",
  },
  ADD_PRODUCT_CATEGORY_CREATE_CUSTOM_MESSAGE: {
    en: "Click Enter to create the new Category.",
    de: "Drücke Enter, um die neue Kategorie zu erstellen.",
  },
  PAGINATION_ITEMS_PER_PAGE: {
    en: "Items per page",
    de: "Elemente pro Seite",
  },
  FIELD_REQUIRED_FIELD_CONFIRM_MESSAGE: {
    en: "Choose if this field is to be required field?",
    de: "Wähle, ob dieses Feld ein Pflichtfeld sein soll?",
  },
  ROLES_AND_PERMISSIONS_ALL_CONTROLS_MESSAGE: {
    en: "All controls",
    de: "Alle Einstellungen",
  },
  ROLES_AND_PERMISSIONS_VIEW_MESSAGE: {
    en: "View",
    de: "Ansicht",
  },
  ROLES_AND_PERMISSIONS_TO_MESSAGE: {
    en: "to",
    de: "zu",
  },
  ROLES_AND_PERMISSIONS_AND_MESSAGE: {
    en: "and",
    de: "und",
  },
  ROLES_AND_PERMISSIONS_CREATE_MESSAGE: {
    en: "create",
    de: "erstellen",
  },
  ROLES_AND_PERMISSIONS_EDIT_MESSAGE: {
    en: "edit",
    de: "bearbeiten",
  },
  ROLES_AND_PERMISSIONS_DELETE_MESSAGE: {
    en: "delete",
    de: "löschen",
  },
  ROLE_RESOURCE_PERMISSIONS_EMPTY_TITLE: {
    en: "No resources for this role",
    de: "Keine Ressourcen für diese Rolle",
  },
  ADD_NEW_ADDRESS_MESSAGE: {
    en: "Add New Address",
    de: "Neue Adresse hinzufügen",
  },
  ALERT_SUCCESS_MESSAGE: {
    en: "Success",
    de: "Erfolgreich",
  },
  TAB_SECTION_DEELTE_CONFORM_MESSAGE: {
    en: "Are you sure want to delete the panel, it will delete all the sections and fields data?",
    de: "Bist du sicher, dass du das Panel löschen möchtest? Es werden alle Abschnitte und Felddaten gelöscht.",
  },
  TAB_SECTION_FIELDS_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure want to delete the section? It will delete all the fields in the section.",
    de: "Bist du sicher, dass du den Abschnitt löschen möchtest? Es werden alle Felder im Abschnitt gelöscht.",
  },
  TAB_FIELD_DEELTE_CONFIRM_MESSAGE: {
    en: "Are you sure want to delete the field?",
    de: "Bist du sicher, dass du das Feld löschen möchtest?",
  },
  TAB_DETAILS_ADD_FIELD_TEXT: {
    en: "Add Field",
    de: "Feld hinzufügen",
  },
  MASTERDATA_SYSTEM_MESSAGES_TITLE: {
    en: "SYSTEM MESSAGES",
    de: "SYSTEMNACHRICHTEN",
  },
  SYSTEM_MESSAGES_ADD_NEW_BUTTON: {
    en: "Add new Message",
    de: "Neue Nachricht hinzufügen",
  },
  MASTERDATA_SYSTEM_MESSAGES_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "end_phase", title: "End Phase" },
      { id: 2, name: "phase_approvals", title: "Phase Approvals" },
      { id: 3, name: "phase_rejections", title: "Phase Rejections" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "end_phase", title: "Endphase" },
      { id: 2, name: "phase_approvals", title: "Phase abgeschlossen" },
      { id: 3, name: "phase_rejections", title: "Phase abgebrochen" },
    ]),
  },
  MASTERDATA_SYSTEM_MESSAGE_WORK_FLOW_REQUIRED_MESSAGE: {
    en: "Workflow is required",
    de: "Workflow ist erforderlich",
  },
  MASTERDATA_SYSTEM_MESSAGE_MESSAGE_REQUIRED_MESSAGE: {
    en: "Message is required",
    de: "Nachricht ist erforderlich",
  },
  MASTERDATA_SYSTEM_MESSAGE_ADD_WORKFLOW_TITLE: {
    en: "Workflow",
    de: "Workflow",
  },
  MASTERDATA_SYSTEM_MESSAGE_ADD_MESSAGE_TITLE: {
    en: "Message",
    de: "Nachricht",
  },
  MASTERDATA_SYSTEM_MESSAGE_ADD_SUCCESS_MESSAGE: {
    en: "System message created successfully!!",
    de: "Systemnachricht erfolgreich erstellt!!",
  },
  MASTERDATA_SYSTEM_MESSAGE_ADD_FAILURE_MESSAGE: {
    en: "Can not create system message, Please try again later!",
    de: "Systemnachricht kann nicht erstellt werden, bitte versuche es später erneut!",
  },
  MASTERDATA_SYSTEM_MESSAGE_UPDATE_SUCCESS_MESSAGE: {
    en: "System message updated successfully!!",
    de: "Systemnachricht erfolgreich aktualisiert!!",
  },
  MASTERDATA_SYSTEM_MESSAGE_UPDATE_FAILURE_MESSAGE: {
    en: "Can not update system message, Please try again later!",
    de: "Systemnachricht kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  MASTERDATA_SYSTEM_MESSAGE_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure want to delete the message(S)?",
    de: "Bist du sicher, dass du die Nachricht(en) löschen möchtest?",
  },
  MASTERDATA_SYSTEM_MESSAGE_DELETE_SUCCESS_MESSAGE: {
    en: "Message(s) deleted successfully!",
    de: "Nachricht(en) erfolgreich gelöscht!",
  },
  MASTERDATA_SYSTEM_MESSAGE_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete message(s), Please try again later!",
    de: "Nachricht(en) können nicht gelöscht werden, bitte versuche es später erneut!",
  },
  DEALS_END_PHASE_REASON_TITLE: {
    en: "End Phase Reason",
    de: "Grund für die Endphase",
  },
  DEALS_ENDPHASE_OTHER_REASON: {
    en: JSON.stringify([
      { id: 1, name: "end_phase", title: "End Phase" },
      { id: 2, name: "phase_approvals", title: "Phase Approvals" },
      { id: 3, name: "phase_rejections", title: "Phase Rejections" },
      { id: 4, name: "others", title: "Others" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "end_phase", title: "Endphase" },
      { id: 2, name: "phase_approvals", title: "Phase abgeschlossen" },
      { id: 3, name: "phase_rejections", title: "Phase abgebrochen" },
      { id: 4, name: "others", title: "Sonstiges" },
    ]),
  },
  DEALS_ENDPHASE_REASON_NOTE: {
    en: "Please select a reason message to move the deal to end phase",
    de: "Bitte wähle, warum der Deal in die Endphase verschieben wird",
  },
  LINE_APPROVAL_SUCCESS_MESSAGE: {
    en: "Approval added to the phase succcessfully!",
    de: "Deal erfordert eine Genehmigung wurde zur Phase hinzugefügt!",
  },
  PIPELINE_APPROVAL_FAILURE_MESSAGE: {
    en: "Can not add approval to the phase, Please try again later!",
    de: "Deal erfordert eine Genehmigung kann nicht zur Phase hinzugefügt werden, bitte versuche es später erneut!",
  },
  DEAL_APPROVAL_REQUEST_ACCESS_MESSAGE: {
    en: "Request Approval",
    de: "Überprüfung anfordern",
  },
  DEAL_APPROVAL_REQUESTED_ACCESS_MESSAGE: {
    en: "Requested Approval",
    de: "Überprüfung angefordert",
  },
  DEALS_MOVE_REQUEST_SUCCESS_MESSAGE: {
    en: "Deal move approval requested successfully!",
    de: "Deal Überprüfung erfolgreich angefordert!",
  },
  DEALS_MOVE_FAILURE_MESSAGE: {
    en: "Can not request approval to move deal, please try again!",
    de: "Deal kann kann nicht zur Überprüfung angefordert werden, bitte versuche es erneut!",
  },
  DEALS_ADD_LABEL_TITLE: {
    en: "Add label",
    de: "Label hinzufügen",
  },
  DEALS_ADD_LABEL_MODAL_TITLE: {
    en: "Select label",
    de: "Label auswählen",
  },
  MENU_CONTACTTITLES: {
    en: "CONTACT TITLES",
    de: "KONTAKTTITEL",
  },
  MASTERDATA_CONTACT_TITLES_CREATE_SUCCESS_MESSAGE: {
    en: "Contact Title created successfully!",
    de: "Kontakttitel erfolgreich erstellt!",
  },
  MASTERDATA_CONTACT_TITLE_CREATE_FAILURE_MESSAGE: {
    en: "Cannot create ContactTitle, Please try again!",
    de: "Kontakttitel kann nicht erstellt werden, bitte versuche es erneut!",
  },
  MASTERDATA_CONTACT_TITLE_EDIT_SUCCESS_MESSAGE: {
    en: "Contact Title name edited successfully!",
    de: "Kontakttitel erfolgreich bearbeitet!",
  },
  MASTERDATA_CONTACT_TITLE_EDIT_FAILURE_MESSAGE: {
    en: "Cannot edit Contact Title, Please try again!",
    de: "Kontakttitel kann nicht bearbeitet werden, bitte versuche es erneut!",
  },
  MASTERDATA_CONTACT_TITLE_EDIT_TITLE: {
    en: "Edit Contact Title",
    de: "Kontakttitel bearbeiten",
  },
  MASTERDATA_CONTACT_TITLE_EDIT_NEW_TITLE: {
    en: "Create new Contact Title",
    de: "Neuen Kontakttitel erstellen",
  },
  MASTERDATA_CONTACT_TITLE_CONTACT_NAME_FIELD_NAME: {
    en: "Name",
    de: "Name",
  },
  MASTERDATA_CONTACT_TITLE_CONTACT_NAME_PLACEHOLDER: {
    en: "Enter Contact Title name",
    de: "Gib den Namen des Kontakttitels ein",
  },
  MASTERDATA_CONTACT_TITLE_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  MASTERDATA_CONTACT_TITLE_DELETE_SUCCESS_MESSAGE: {
    en: "Contact Title deleted successfully!",
    de: "Kontakttitel erfolgreich gelöscht!",
  },
  MASTERDATA_CONTACT_TITLE_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete Contact Title, Please try again!",
    de: "Kontakttitel kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_CONTACT_TITLE_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Contact Title(s) deleted successfully!",
    de: "Kontakttitel erfolgreich gelöscht!",
  },
  MASTERDATA_CONTACT_TITLE_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete Contact Title(s), Please try again!",
    de: "Kontakttitel können nicht gelöscht werden, bitte versuche es erneut!",
  },
  MASTERDATA_CONTACT_TITLE_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure, you want to delete this Contact Title?",
    de: "Bist du sicher, dass du diesen Kontakttitel löschen möchtest?",
  },
  MASTERDATA_CONTACT_TITLE_SEARCH_PLACEHOLDER: {
    en: "Search",
    de: "Suche",
  },
  MASTERDATA_CONTACT_TITLE_SELECTED_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure, you want to delete selected Contact Titles?",
    de: "Bist du sicher, dass du die ausgewählten Kontakttitel löschen möchtest?",
  },
  MASTERDATA_CONTACT_TITLE_CREATE_NEW_BUTTON: {
    en: "Create New Contact Title",
    de: "Neuen Kontakttitel erstellen",
  },
  MASTERDATA_CONTACT_TITLE_CONTACT_NAME_REQUIRED: {
    en: "Name is required!",
    de: "Name ist erforderlich!",
  },
  MASTERDATA_CONTACT_TITLES_TITLE: {
    en: "CONTACT TITLES",
    de: "Kontakttitel",
  },
  APPROVERS_ADDED_SUCCESS_MESSAGE: {
    en: "Approvers added successfully!",
    de: "Prüfer erfolgreich hinzugefügt!",
  },
  APPROVERS_ADDED_FAILURE_MESSAGE: {
    en: "Can not add approvers, please try again later!",
    de: "Prüfer können nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  APPROVERS_REMOVED_SUCCESS_MESSAGE: {
    en: "Approvers removed successfully!",
    de: "Prüfer erfolgreich entfernt!",
  },
  APPROVERS_REMOVED_FAILURE_MESSAGE: {
    en: "Can not remove approvers, please try again later!",
    de: "Prüfer kann nicht entfernt werden, bitte versuche es später erneut!",
  },
  APPROVER_PRIMARY_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure want to remove the user as Primary approver?",
    de: "Bist du sicher, dass du den Benutzer als Hauptprüfer entfernen möchtest?",
  },
  APPROVER_SECONDARY_DELETE_CONFIRM_MESSAGE: {
    en: "Are you sure want to remove the user as Secondary approver?",
    de: "Bist du sicher, dass du den Benutzer als zweiten Prüfer entfernen möchtest?",
  },
  ACCEPT_APPROVAL_NOTES_REQUIRED_MESSAGE: {
    en: "Approval message is required",
    de: "Eine Genehmigungsnachricht ist erforderlich",
  },
  ACCEPT_APPROVAL_MODAL_TITLE: {
    en: "Approval/Reject",
    de: "Genehmigungs-/Ablehnungs Funktion",
  },
  ACCEPT_APPROVAL_NOTES_LABEL: {
    en: "Approval/Reject Message",
    de: "Genehmigungs-/Ablehnungsnachricht",
  },
  APPROVE_BUTTON: {
    en: "Approve",
    de: "Genehmigen",
  },
  REJECT_BUTTON: {
    en: "Reject",
    de: "Ablehnen",
  },
  ACCEPT_APPROVAL_SUCCESS_MESSAGE: {
    en: "Approval submitted successfully!",
    de: "Genehmigung erfolgreich eingereicht!",
  },
  ACCEPT_APPROVAL_FAILURE_MESSAGE: {
    en: "Can not submit the approval, please try again later!",
    de: "Die Genehmigung kann nicht eingereicht werden, bitte versuche es später erneut!",
  },
  PIPELINE_APPROVAL_PRIMARY_USERS: {
    en: "Primary Users:",
    de: "Hauptprüfer:",
  },
  PIPELINE_APPROVAL_SECONDARY_USERS: {
    en: "Secondary Users:",
    de: "Stellvertretender Prüfer:",
  },
  MENU_SETTINGS: {
    en: "SETTINGS",
    de: "EINSTELLUNGEN",
  },
  DEAL_ACTIVITY_TAB1: {
    en: "All",
    de: "Alle",
  },
  DEAL_ACTIVITY_TAB2: {
    en: "Notes",
    de: "Notizen",
  },
  DEAL_ACTIVITY_TAB3: {
    en: "Tasks",
    de: "Aufgaben",
  },
  DEAL_ACTIVITY_TAB: {
    en: "Activity",
    de: "Aktivität",
  },
  DEAL_PROJECT_FILES_TAB: {
    en: "Project Files",
    de: "Projektdateien",
  },
  ROUTES_PARTNERMANAGEMENT_EDITPARTNER: {
    en: "Edit organisation",
    de: "Organisation bearbeiten",
  },
  CONTACT_DETAILS_NOTES_TAB: {
    en: "Notes",
    de: "Notizen",
  },
  CONTACT_DETAILS_EDIT_TAB: {
    en: "Contact Details",
    de: "Kontaktdetails",
  },
  CONTACT_DETAILS_ACTIVITY_TAB: {
    en: "Activity",
    de: "Aktivität",
  },
  CONTACTS_NOTICE_TITLE: {
    en: "Important Notice",
    de: "Kommentare",
  },
  CONTACTS_NOTICE_EDIT: {
    en: "Edit",
    de: "Bearbeiten",
  },
  CONTACTS_NOTICE_EDITING__SUBMIT: {
    en: "Save",
    de: "Speichern",
  },
  CONTACTS_NOTICE_EDITING_DISMISS: {
    en: "Dismiss",
    de: "Abbrechen",
  },
  CONTACTS_NOTICE_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  CUSTOMER_MANAGEMENT_PROFILE_EMAIL: {
    en: "Email",
    de: "E-Mail",
  },
  CUSTOMER_MANAGEMENT_PROFILE_PHONE: {
    en: "Telephone",
    de: "Telefon",
  },
  CUSTOMER_MANAGEMENT_PROFILE_ADD_NEW_NOTE: {
    en: "Add new note",
    de: "Neue Notiz hinzufügen",
  },
  CUSTOMER_MANAGEMENT_PROFILE_ADD_NEW_NOTE_BTN: {
    en: "Add Note",
    de: "Notiz hinzufügen",
  },
  CUSTOMER_MANAGEMENT_PROFILE_SAVE_NEW_NOTE_BTN: {
    en: "Save",
    de: "Speichern",
  },
  CUSTOMER_MANAGEMENT_PROFILE_CANCEL_NEW_NOTE_BTN: {
    en: "Dismiss",
    de: "Abbrechen",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTE_VALIDATION_MESSAGE: {
    en: "Note is required",
    de: "Notiz ist erforderlich",
  },
  CUSTOMER_MANAGEMENT_PROFILE_ADD_NOTE_SUCCESS_MESSAGE: {
    en: "Note saved successfully!",
    de: "Notiz erfolgreich gespeichert!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_ADD_NOTE_FAILURE_MESSAGE: {
    en: "Cannot save the note, Please try again!",
    de: "Notiz kann nicht gespeichert werden, bitte versuche es erneut!",
  },
  CUSTOMER_MANAGEMENT_NOTE_ACTION_DELETE_MESSAGE: {
    en: "Are you sure, you want to permanently delete this note?",
    de: "Bist du sicher, dass du diese Notiz dauerhaft löschen möchtest?",
  },
  CUSTOMER_MANAGEMENT_DELETE_NOTE_SUCCESS: {
    en: "Note deleted successfully",
    de: "Notiz erfolgreich gelöscht",
  },
  CUSTOMER_MANAGEMENT_DELETE_NOTE_FAILED: {
    en: "Cannot delete note, please try again!",
    de: "Notiz kann nicht gelöscht werden, bitte versuche es erneut!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTE_EDIT_MODAL_TITLE: {
    en: "Edit Note",
    de: "Notiz bearbeiten",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTE_ADD_MODAL_TITLE: {
    en: "Add new Note",
    de: "Neue Notiz hinzufügen",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTE_UPDATED_SUCCESS_MESSAGE: {
    en: "Note updated successfully!",
    de: "Notiz erfolgreich aktualisiert!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTE_UPDATED_FAILURE_MESSAGE: {
    en: "Cannot update Note, Please try again later!",
    de: "Notiz kann nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTE_EDIT_MODAL_FIELD_NAME: {
    en: "Notes",
    de: "Notizen",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTE_EDIT_MODAL_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  DEAL_FINISH_BUTTON: {
    en: "Finish",
    de: "Abschliessen",
  },
  DEAL_APPROVE_REJECT_REQUEST: {
    en: "Approve/Reject Request",
    de: "Anfrage genehmigen/ablehnen",
  },
  MASTERDATA_PLACEHOLDER_FIELD: {
    en: "Placeholder",
    de: "Platzhalter",
  },
  MASTERDATA_TOOLTIP_FIELD: {
    en: "Enable Tooltip",
    de: "Tooltip aktivieren",
  },
  MASTERDATA_TEXTAREA_FIELD: {
    en: "Enter Tooltip Information",
    de: "Tooltip-Informationen eingeben",
  },
  MASTERDATA_TEXTAREA_FIELD_REQUIRED: {
    en: "Field information is required",
    de: "Feldinformation ist erforderlich",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTES_LAST_UPDATED_DATETIME: {
    en: "Last updated at",
    de: "Zuletzt aktualisiert am",
  },
  CUSTOMER_PROFILE_TELEPHONE_VALIDATION_MESSAGE: {
    en: "Invalid telephone number format. Please enter a valid telephone number.",
    de: "Ungültiges Telefonnummernformat. Bitte gib eine gültige Telefonnummer ein.",
  },
  CUSTOMER_MANAGEMENT_PROFILE_DETAILS_TITLE: {
    en: "Customer details",
    de: "Kundendetails",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_NO_DATA: {
    en: "Please add important notice here",
    de: "Hier kannst du eine wichtige Notiz hinzufügen",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_BTN: {
    en: "Add Notice",
    de: "Notiz hinzufügen",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_EDIT_BTN: {
    en: "Edit",
    de: "Bearbeiten",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_MODAL_TITLE: {
    en: "Add Notice",
    de: "Notiz hinzufügen",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_EDIT_MODAL_TITLE: {
    en: "Edit Notice",
    de: "Notiz bearbeiten",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_MODAL_FIELD_NAME: {
    en: "Notice",
    de: "Notiz",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_MODAL_FILED_PLACEHOLDER: {
    en: "Enter notice here",
    de: "Notiz hier eingeben",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_SUCCESS_MESSAGE: {
    en: "Notice saved successfully!",
    de: "Notiz erfolgreich gespeichert!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTICE_ADD_FAILURE_MESSAGE: {
    en: "Cannot save the notice, Please try again!",
    de: "Notiz kann nicht gespeichert werden, bitte versuche es erneut!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_PIN_NOTE_MESSAGE: {
    en: "Note pinned!",
    de: "Notiz angeheftet!",
  },
  CUSTOMER_MANAGEMENT_PROFILE_UNPIN_NOTE_MESSAGE: {
    en: "Notiz unpinned!",
    de: "Notiz gelöst!",
  },
  DEALS_ADD_NEW_LABEL_TITLE: {
    en: "Add Tag",
    de: "Tag hinzufügen",
  },
  DEALS_ADD_NEW_LABEL_MODAL_TITLE: {
    en: "Select Tag",
    de: "Wähle einen Tag aus",
  },
  TAGS_SEARCH_PLACEHOLDER: {
    en: "Search tags",
    de: "Suchbegriff eingeben",
  },
  CUSTOMER_MANAGEMENT_PROFILE_NOTES_ID_VALIDATION_MESSAGE: {
    en: "Either users_id (Contact) or deals_id (Deal) is needed, both cannot be null simultaneously.",
    de: "Entweder wird die users_id (Kontakt) oder die deals_id (Deal) benötigt, beide dürfen nicht gleichzeitig leer sein.",
  },
  MASTERDATA_TOOLTIP_INFO: {
    en: "By enabling this button, you will no longer be able to delete this field. Please proceed with caution.",
    de: "Wenn Du diese Schaltfläche aktivierst, kannst Du dieses Feld nicht mehr löschen. Bitte gehe vorsichtig vor.",
  },
  TASKS_ADD_NEW_TASK: {
    en: "New Task",
    de: "Neue Aufgabe",
  },
  TASKS_COLUMN_DUE_DATE: {
    en: "DUE DATE",
    de: "FÄLLIGKEITSDATUM",
  },
  TASKS_COLUMN_TASK_NAME: {
    en: "TASK NAME",
    de: "AUFGABENNAME",
  },
  TASKS_COLUMN_DEAL: {
    en: "DEAL",
    de: "DEAL",
  },
  TASKS_COLUMN_CONTACT: {
    en: "CONTACT",
    de: "KONTAKT",
  },
  TASKS_COLUMN_PHONE: {
    en: "PHONE",
    de: "TELEFON",
  },
  TASKS_COLUMN_RESPONSIBLE: {
    en: "RESPONSIBLE",
    de: "VERANTWORTLICH",
  },
  TASKS_COLUMN_ACTIONS: {
    en: "ACTIONS",
    de: "AKTIONEN",
  },
  TASKS_ADD_TASK_TITLE_FIELD_PLACEHOLDER: {
    en: "Task Title",
    de: "Aufgabentitel",
  },
  TASKS_ADD_TASK_DESCRIPTION_FIELD_NAME: {
    en: "Description",
    de: "Beschreibung",
  },
  TASKS_ADD_TASK_DUE_DATE_FIELD_PLACEHOLDER: {
    en: "Due Date",
    de: "Fälligkeitsdatum",
  },
  TASKS_ADD_TASK_PRIORITY_FIELD_PLACEHOLDER: {
    en: "Task Priority",
    de: "Aufgabenpriorität",
  },
  TASKS_ADD_TASK_DEAL_FIELD_PLACEHOLDER: {
    en: "Select Deal",
    de: "Deal auswählen",
  },
  TASKS_ADD_TASK_DEAL_FIELD_SEARCH_PLACEHOLDER: {
    en: "Search Deal",
    de: "Nach Deal suchen",
  },
  TASKS_ADD_TASK_TYPE_FIELD_NAME: {
    en: "Task Type",
    de: "Aufgabentyp",
  },
  TASKS_FILTER_RESPONSIBLE_PERSONS_FIELD_NAME: {
    en: "Responsible Persons",
    de: "Verantwortliche Personen",
  },
  TASKS_ADD_TASK_STATUS_FIELD_NAME: {
    en: "Status",
    de: "Status",
  },
  TASKS_TITLE_FIELD_VALIDATION_MESSAGE: {
    en: "Title is required",
    de: "Ein Titel wird benötigt",
  },
  TASKS_DUE_DATE_FIELD_VALIDATION_MESSAGE: {
    en: "Due date is required",
    de: "Ein Fälligkeitsdatum wird benötigt",
  },
  TASKS_PRIORITY_FIELD_VALIDATION_MESSAGE: {
    en: "Priority is required",
    de: "Eine Priorität wird benötigt",
  },
  TASKS_TYPE_FIELD_VALIDATION_MESSAGE: {
    en: "Task Type is required",
    de: "Ein Aufgabentyp wird benötigt",
  },
  TASKS_STATUS_FIELD_VALIDATION_MESSAGE: {
    en: "Status is required",
    de: "Ein Status wird benötigt",
  },
  TASKS_DESCRIPTION_FIELD_VALIDATION_MESSAGE: {
    en: "Description is required",
    de: "Eine Beschreibung wird benötigt",
  },
  TASKS_RESPONSIBLE_PERSON_VALIDATION_MESSAGE: {
    en: "At least one responsible person should be selected",
    de: "Mindestens eine verantwortliche Person muss ausgewählt werden",
  },
  TASKS_LIST_NOITEMS_FOUND: {
    en: "No tasks found.",
    de: "Keine Aufgaben gefunden.",
  },
  TASKS_ADD_NEW_MODAL_TITLE: {
    en: "Add New Task",
    de: "Neue Aufgabe hinzufügen",
  },
  TASKS_EDIT_NEW_MODAL_TITLE: {
    en: "Edit Task",
    de: "Aufgabe bearbeiten",
  },
  TASKS_ADD_NEW_TASK_SUCCESS_MESSAGE: {
    en: "Task added successfully!",
    de: "Aufgabe erfolgreich hinzugefügt!",
  },
  TASKS_ADD_NEW_TASK_ERROR_MESSAGE: {
    en: "Cannot add task, please try again later!",
    de: "Aufgabe konnte nicht hinzugefügt werden, bitte versuche es später erneut!",
  },
  TASKS_EDIT_TASK_SUCCESS_MESSAGE: {
    en: "Task updated successfully!",
    de: "Aufgabe erfolgreich aktualisiert!",
  },
  TASKS_EDIT_TASK_ERROR_MESSAGE: {
    en: "Cannot update task, please try again later!",
    de: "Aufgabe konnte nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  TASKS_DELETE_POST_CONFIRM_MESSAGE: {
    en: "Are you sure you want to delete the task?",
    de: "Bist Du sicher, dass Du die Aufgabe löschen möchtest?",
  },
  TASKS_DELETE_POST_MESSAGE: {
    en: "Task deleted successfully!",
    de: "Aufgabe erfolgreich gelöscht!",
  },
  TASKS_DELETE_POST_FAILURE_MESSAGE: {
    en: "Cannot delete the task, please try again later!",
    de: "Aufgabe konnte nicht gelöscht werden, bitte versuche es später erneut!",
  },
  TASKS_LIST_COMPLETE_STATUS_SELECTED: {
    en: "Complete Task",
    de: "Aufgabe abschließen",
  },
  TASKS_LIST_REVERT_STATUS_SELECTED: {
    en: "Revert Status",
    de: "Status zurücksetzen",
  },
  TASKS_UPDATE_STATUS_POST_CONFIRM_MESSAGE: {
    en: "Are you sure you want to update status of this task?",
    de: "Bist Du sicher, dass Du den Status dieser Aufgabe aktualisieren möchtest?",
  },
  TASKS_UPDATE_STATUS_SUCCESS_MESSAGE: {
    en: "Task status updated successfully!",
    de: "Aufgabenstatus erfolgreich aktualisiert!",
  },
  TASKS_UPDATE_STATUS_FAILURE_MESSAGE: {
    en: "Cannot update task status, please try again later!",
    de: "Aufgabenstatus konnte nicht aktualisiert werden, bitte versuche es später erneut!",
  },
  TASKS_LIST_RESPONSIBLE_PERSONS_MORE_BTN: {
    en: "See All",
    de: "Alle anzeigen",
  },
  TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_TITLE: {
    en: "Responsible Persons",
    de: "Verantwortliche Personen",
  },
  TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_NAME_COLUMN: {
    en: "Name",
    de: "Name",
  },
  TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_EMAIL_COLUMN: {
    en: "Email",
    de: "E-Mail",
  },
  TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_PHONE_COLUMN: {
    en: "Phone",
    de: "Telefon",
  },
  TASKS_LIST_RESPONSIBLE_PERSONS_MODAL_ACTION_COLUMN: {
    en: "Action",
    de: "Aktion",
  },
  TASKS_LIST_FILTER_DATE_RANGE_APPLY: {
    en: "Apply",
    de: "Anwenden",
  },
  TASKS_LIST_FILTER_DEALS_FIELD_NAME: {
    en: "Select Deal",
    de: "Deal auswählen",
  },
  TASKS_LIST_FILTER_BADGE_DEAL: {
    en: "Deal",
    de: "Deal",
  },
  TASKS_FILTER_TABS: {
    en: JSON.stringify([
      { id: 0, name: "All" },
      { id: 1, name: "My Tasks" },
      { id: 2, name: "To Do" },
      { id: 3, name: "Overdue" },
      { id: 4, name: "Today" },
      { id: 5, name: "Tomorrow" },
      { id: 6, name: "This week" },
      { id: 7, name: "Select date" },
    ]),
    de: JSON.stringify([
      { id: 0, name: "Alle" },
      { id: 1, name: "Meine Aufgaben" },
      { id: 2, name: "To Do" },
      { id: 3, name: "Überfällig" },
      { id: 4, name: "Heute" },
      { id: 5, name: "Morgen" },
      { id: 6, name: "Diese Woche" },
      { id: 7, name: "Datum auswählen" },
    ]),
  },
  TASKS_ADD_TASK_PRIORITY_TYPES: {
    en: JSON.stringify([
      { id: 0, name: "high", title: "High" },
      { id: 1, name: "medium", title: "Medium" },
      { id: 2, name: "low", title: "Low" },
    ]),
    de: JSON.stringify([
      { id: 0, name: "high", title: "Hoch" },
      { id: 1, name: "medium", title: "Mittel" },
      { id: 2, name: "low", title: "Niedrig" },
    ]),
  },
  TASKS_ADD_TASK_TYPE_TYPES: {
    en: JSON.stringify([
      { id: 0, name: "call", title: "Call" },
      { id: 1, name: "email", title: "Email" },
      { id: 2, name: "other", title: "Other" },
    ]),
    de: JSON.stringify([
      { id: 0, name: "call", title: "Anruf" },
      { id: 1, name: "email", title: "E-Mail" },
      { id: 2, name: "other", title: "Sonstiges" },
    ]),
  },
  TASKS_ADD_TASK_STATUS_TYPES: {
    en: JSON.stringify([
      { id: 0, name: "to-do", title: "To Do" },
      { id: 1, name: "done", title: "Done" },
    ]),
    de: JSON.stringify([
      { id: 0, name: "to-do", title: "To Do" },
      { id: 1, name: "done", title: "Erledigt" },
    ]),
  },
  TASKS_NUMBER_ITEMS_PER_PAGE_OPTIONS: {
    en: JSON.stringify([
      { id: 1, value: 50 },
      { id: 2, value: 100 },
      { id: 3, value: 150 },
    ]),
    de: JSON.stringify([
      { id: 1, value: 50 },
      { id: 2, value: 100 },
      { id: 3, value: 150 },
    ]),
  },
  DEAL_LOCATION: {
    en: "Address",
    de: "Adresse",
  },
  DEFAULT_MESSAGE: {
    en: "Set as Default",
    de: "Als Standard festlegen",
  },
  DEFAULT_TAG: {
    en: "Default",
    de: "Standard",
  },
  ADDRESS_NOT_AVAILABLE: {
    en: "Address not available",
    de: "Adresse nicht verfügbar",
  },
  TABS_EMPTY_MESSAGE: {
    en: "To get started, please create your first panel. Drag and drop a panel here to begin.",
    de: "Lege als erstes die gewünschte Seitenaufteilung für den Reiter fest. Ziehe dafür eines der Panel hierher.",
  },
  MENU_TASKS: {
    en: "TASKS",
    de: "AUFGABEN",
  },
  DUPLICATE_BUTTON: {
    en: "Duplicate",
    de: "Duplizieren",
  },
  PROJECTS_PIPELINE_DUPLICATE_CONFIRM_MESSAGE: {
    en: "Are you sure you want to duplicate this phase",
    de: "Bist Du sicher, dass Du diese Phase duplizieren möchtest?",
  },
  PROEJCTS_PIPELINE_DUPLICATE_SUCCESS_MESSAGE: {
    en: "Pipeline duplicated successfully!",
    de: "Pipeline erfolgreich dupliziert!",
  },
  PROEJCTS_PIPELINE_DUPLICATE_FAILURE_MESSAGE: {
    en: "Can not duplicate pipeline, please try again later!",
    de: "Pipeline kann nicht dupliziert werden, bitte versuche es später erneut!",
  },
  YES_DUPLICATE_BUTTON: {
    en: "Yes, duplicate",
    de: "Ja, duplizieren",
  },
  DEALS_LIST_NOITEMS_FOUND: {
    en: "No deals found.",
    de: "Keine Deals gefunden.",
  },
  MENU_PARTNERMANAGEMENT_OVERVIEW: {
    en: "Organisation overview",
    de: "Organisationsübersicht",
  },
  MENU_PRODUCTS_OVERVIEW: {
    en: "Product overview",
    de: "Produktübersicht",
  },
  MENU_USERMANAGEMENT_OVERVIEW: {
    en: "User overview",
    de: "Benutzerübersicht",
  },
  COMMON_VIEW_LOGS: {
    en: "Logs",
    de: "Protokolle",
  },
  PIPELINE_IMPORT_BUTTON: {
    en: "Import",
    de: "Importieren",
  },
  PIPELINE_TOOLTIP_INFO: {
    en: "Easily import tabs from other phases to streamline your workflow.",
    de: "Importiere ganz einfach Reiter aus anderen Phasen, um deinen Workflow zu optimieren.",
  },
  PIPELINE_IMPORT_TABS: {
    en: "Import tabs",
    de: "Reiter importieren",
  },
  PIPELINE_PHASES: {
    en: "Phases",
    de: "Phasen",
  },
  IMPORT_TABS_SUCCESS: {
    en: "Tab(s) imported successfully!",
    de: "Tab(s) erfolgreich importiert!",
  },
  IMPORT_TABS_ERROR: {
    en: "Tab(s) import unsuccessful.",
    de: "Tab(s) Import war nicht erfolgreich.",
  },
  USER_MANAGEMENT_FILTER_ROLE_FIELD_LABEL: {
    en: "Role",
    de: "Rolle",
  },
  COMMON_LOGS_AT: {
    en: "at",
    de: "um",
  },
  COMMON_LOGS_ADDED: {
    en: "added",
    de: "hinzugefügt",
  },
  COMMON_LOGS_UPDATED: {
    en: "updated",
    de: "aktualisiert",
  },
  COMMON_LOGS_CREATED_TASK: {
    en: "created this task",
    de: "diese Aufgabe erstellt",
  },
  COMMON_LOGS_UPDATED_TASK: {
    en: "updated this task",
    de: "diese Aufgabe aktualisiert",
  },
  COMMON_LOGS_ASSIGNEE_TASK: {
    en: "as responsible person for this task",
    de: "als verantwortliche Person für diese Aufgabe zugewiesen",
  },
  COMMON_LOGS_STATUS_FROM: {
    en: "changed status from",
    de: "Status geändert von",
  },
  COMMON_LOGS_TO: {
    en: "to",
    de: "zu",
  },
  COMMON_LOGS_STATUS_TODO: {
    en: "to-do",
    de: "zu erledigen",
  },
  COMMON_LOGS_STATUS_DONE: {
    en: "done",
    de: "erledigt",
  },
  COMMON_LOGS_CREATED_NOTE: {
    en: "created this note",
    de: "diese Notiz erstellt",
  },
  COMMON_LOGS_UPDATED_NOTE: {
    en: "updated this note",
    de: "diese Notiz aktualisiert",
  },
  TABS_IMPORTED_SUCCESS_MESSAGE: {
    en: "Tab(s) imported successfully!",
    de: "Reiter erfolgreich importiert!",
  },
  TABS_IMPORTED_FAILURE_MESSAGE: {
    en: "Can not import tab(s), please try again later!",
    de: "Reiter können nicht importiert werden, bitte versuche es später erneut!",
  },
  ARCHIVES_LIST_NOITEMS_FOUND: {
    en: "No archived deals found.",
    de: "Keine archivierten Deals gefunden.",
  },
  PRODUCT_ACTIVITY_LIST_NOITEMS_FOUND: {
    en: "No product activity found.",
    de: "Keine Änderungen am Produkt gefunden.",
  },
  PRODUCT_DOCUMENTS_LIST_NOITEMS_FOUND: {
    en: "No product documents found.",
    de: "Keine Dokumente zum Produkt gefunden.",
  },
  PRODUCTS_LIST_NOITEMS_FOUND: {
    en: "No products found.",
    de: "Keine Produkte gefunden.",
  },
  ORGANISATIONS_MASS_DELETE_SUCCESS_MESSAGE: {
    en: "Organisations deleted successfully!",
    de: "Organisationen erfolgreich gelöscht!",
  },
  ORGANISATIONS_MASS_DELETE_FAILURE_MESSAGE: {
    en: "Cannot delete Organisations, Please try again!",
    de: "Organisationen können nicht gelöscht werden, bitte versuche es erneut!",
  },
  DEAL_LOGS_DEAL_MOVED_FROM: {
    en: "Deal moved from",
    de: "Deal verschoben von",
  },
  DEAL_LOGS_TITLE: {
    en: "Below are the logs for",
    de: "Die nachfolgenden Protokolle sind für",
  },
  DEAL_LOGS_TITLE_PIPELINE: {
    en: "pipeline",
    de: "Pipeline",
  },
  COMMON_NO_LOGS: {
    en: "Logs not created",
    de: "Keine Protokolle erstellt",
  },
  COMMON_LOGS_MOVED_AT: {
    en: "moved at",
    de: "verschoben um",
  },
  COMMON_LOGS_BY: {
    en: "by",
    de: "von",
  },
  COMMON_LOGS_ON: {
    en: "on",
    de: "am",
  },
  DEAL_LOGS_FIELD_ADDED: {
    en: "field is added as",
    de: "Feld hinzugefügt als",
  },
  DEAL_LOGS_FIELD_UPDATED: {
    en: "field is updated from",
    de: "Feld aktualisiert von",
  },
  DEAL_LOGS_FILE_UPLOADED: {
    en: "file is uploaded in",
    de: "Datei hochgeladen in",
  },
  DEAL_LOGS_FILES_UPLOADED: {
    en: "files are uploaded in this",
    de: "Dateien hochgeladen",
  },
  PANEL_EMPTY_MESSAGE:{
    en: "Currently, there are no panels for this tab. To add one, ",
    de: ""
  },
  PANEL_CLICK_HERE_MESSAGE:{
    en: "Click here.",
    de: ""
  },
  COMMON_APPROVE : {
    en: "Approve",
    de: "",
  },
  COMMON_REJECT: {
    en: "Reject",
    de: "",
  },
  PHASE_APPROVALS_MODAL_FIELD_STATUS: {
    en: "Select Status",
    de: "",
  },
  PHASE_APPROVALS_MODAL_FIELD_APPROVE: {
    en: "Approval Message",
    de: "",
  },
  PHASE_APPROVALS_MODAL_FIELD_REJECT: {
    en: "Rejection Message",
    de: "",
  },
  PHASE_APPROVALS_STATUS_REQUIRED_MESSAGE: {
    en: "Status is required",
    de: "",
  },
  EDIT_DEAL_APPROVAL_TRANSITION_CONFIRM_MESSAGE: {
    en: "Are you sure you want to request for approval to move the deal to this phase?",
    de: "",
  },
  TASKS_UPDATE_STATUS_SUCCESS_MESSAGE_DONE: {
    en: "Task completed!",
    de: "",
  },
  TASKS_UPDATE_STATUS_FAILURE_MESSAGE_TODO: {
    en: "Task status reverted!",
    de: "",
  },
  DEAL_APPROVALS_REQUESTED_REJECTED_BUTTON: {
    en: "Request Rejected",
    de: "",
  },
  MASTERDATA_TAGS_TAG_COLOR_REQUIRED: {
    en: "Tag color is required",
    de: "",
  },
  MASTERDATA_TAGS_TAG_COLOR_INVALID: {
    en: "Invalid hex color code",
    de: "",
  },
  MASTERDATA_SYSTEM_MESSAGE_ADD_MODAL_TITLE : {
    en: "Create new system message",
    de: "",
  },
  MASTERDATA_SYSTEM_MESSAGE_EDIT_MODAL_TITLE: {
    en: "Edit system message",
    de: "",
  },
  PIPELINE_STATUS_TYPE_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "publish", title: "Publish" },
      { id: 2, name: "draft", title: "Draft" },
      { id: 3, name: "deactivate", title: "Deactivate" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "publish", title: "" },
      { id: 2, name: "draft", title: "" },
      { id: 3, name: "deactivate", title: "" },
    ]),
  },
  PROJECTS_PIPELINE_STATUS_SUCCES_MESSAGE: {
    en: "Pipeline status changed successfully!",
    de: "",
  },
  PROJECTS_PIPELINE_STATUS_FAILURE_MESSAGE: {
    en: "Can not update pipeline status, please try again later!",
    de: "",
  },
  PROJECTS_PIPELINE_STATUS_REQUIRED: {
    en: "Pipeline status is required",
    de: "",
  },
  PIPELINE_ADD_STATUS_TITLE: {
    en: "Pipeline Status",
    de: "",
  },
  MASTERDATA_CONTACT_TYPES_TITLE: {
    en: "Contact Types",
    de: "",
  },
  ACCEPT_APPROVAL_SECONDARY_APPROVER_MESSAGE: {
    en: "You are secondary approver for this request.",
    de: "",
  },
};

export default languages;
