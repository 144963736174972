import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
// @ts-ignore
import DragSortableList from "react-drag-sortable";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import Constants from "../../../config/Constants";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { actions } from "../redux";
import {
  createNewContactTitle,
  deleteContactTitle,
  getContactTitlesList,
  massDeleteContactTitles,
  sortContactTitles,
  updateContactTitle,
} from "../redux/ContactTitlesAPI";
import { GroupType } from "../types/GetGroupsListResponseType";
import { sortGroupsParams } from "../types/request";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";

import {
  ModelComponentProps,
  ContactTitleModalProps,
  FormValuesType,
  SortedObject,
} from "../types/ContactTitlesPropsTypes";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

const ContactTitlesModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  loading,
  onFormSubmit,
}) => {
  const intl = useIntl();
  const { show, type, contactTitle } = ModalProps;
  const initialValues = {
    contactTitleName: type === "edit" ? contactTitle?.name : "",
  };
  const AddNewContactTitleSchema = Yup.object().shape({
    contactTitleName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({
          id: "MASTERDATA_CONTACT_TITLE_CONTACT_NAME_REQUIRED",
        })
      ),
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_CONTACT_TITLE_EDIT_TITLE" })
            : intl.formatMessage({
                id: "MASTERDATA_CONTACT_TITLE_EDIT_NEW_TITLE",
              })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={AddNewContactTitleSchema}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          // @ts-ignore
          onFormSubmit(type, contactTitle, values, setStatus);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          status,
        }) => (
          <>
            <div className="overflow-auto">
              <Modal.Body className="overflow-inherit">
                <div className="p-4">
                  {status && (
                    <div className="mb-10 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        {status}
                      </div>
                    </div>
                  )}
                  <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                    <span className="required">
                      {intl.formatMessage({
                        id: "MASTERDATA_CONTACT_TITLE_CONTACT_NAME_FIELD_NAME",
                      })}
                    </span>
                  </label>

                  <input
                    placeholder={intl.formatMessage({
                      id: "MASTERDATA_CONTACT_TITLE_CONTACT_NAME_PLACEHOLDER",
                    })}
                    // {...formik.getFieldProps('contactTitleName')}
                    className={clsx(
                      "form-control form-control-lg form-control-solid"
                    )}
                    name="contactTitleName"
                    autoComplete="off"
                    onChange={handleChange("contactTitleName")}
                    value={values.contactTitleName}
                  />
                  {touched.contactTitleName && errors.contactTitleName && (
                    <div className="text-danger">
                      <span role="alert">{errors.contactTitleName}</span>
                    </div>
                  )}
                </div>
              </Modal.Body>
            </div>

            <Modal.Footer className="justify-content-center">
              <Button variant="secondary" onClick={closeModal}>
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "MASTERDATA_CONTACT_TITLE_LOADING_MESSAGE",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

const ContactTitles: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const contactTitlesList = useSelector<RootState>(
    ({ masterdata }) => masterdata.contactTitlesList
  ) as GroupType[];

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "contacts_titles"))

  // state
  const [loading, setLoading] = useState(false);
  const [displayContactTitlesList, setDisplayContactTitlesList] =
    useState(contactTitlesList);
  const [showContactTitlesModal, setShowContactTitlesModal] =
    useState<ContactTitleModalProps>({
      show: false,
    });
  const [checkedContactTitles, setCheckedContactTitles] = useState<GroupType[]>(
    []
  );
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllContactTitles, setCheckAllContactTitles] =
    useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const getContactTitlesListAPI = () => {
    getContactTitlesList()
      .then(({ data: { data } }) => {
        dispatch(actions.setContactTitlesList(data));
      })
      .catch((e) => {
        setLoading(false);
        // console.log(`ERROR: getContactTitlesListAPI`, e)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateDisplayContactTitlesList = () => {
    const updatedContactTitlesList = contactTitlesList.filter(
      (contactTitle) => {
        if (
          (contactTitle.name || "")
            .toLocaleLowerCase()
            .includes((searchText || "").toLocaleLowerCase())
        ) {
          return true;
        }
        return false;
      }
    );
    // save updatedlist to display
    setDisplayContactTitlesList(updatedContactTitlesList);
  };

  useEffect(() => {
    setLoading(true);
    // get contactTitles API call
    getContactTitlesListAPI();
  }, []);

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  useEffect(() => {
    updateDisplayContactTitlesList();

    return () => {
      setDisplayContactTitlesList(contactTitlesList);
    };
  }, [contactTitlesList, searchText]);

  useEffect(() => {
    if (
      checkAllContactTitles &&
      checkedContactTitles.length !== contactTitlesList.length
    ) {
      setCheckAllContactTitles(false);
    }
    if (
      checkedContactTitles.length > 0 &&
      checkedContactTitles.length === contactTitlesList.length
    ) {
      setCheckAllContactTitles(true);
    }
  }, [checkedContactTitles]);

  const closeContactTitlesModal = () => {
    setShowContactTitlesModal({
      show: false,
      type: "",
      contactTitle: undefined,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const onFormSubmit = (
    type: string,
    contactTitle: GroupType,
    values: FormValuesType,
    setStatus: (msg: any) => void
  ) => {
    if (type === "new") {
      if (values.contactTitleName) {
        setLoading(true);
        createNewContactTitle(values.contactTitleName)
          .then(() => {
            closeContactTitlesModal();
            SuccessAlert(
              intl.formatMessage({
                id: "MASTERDATA_CONTACT_TITLES_CREATE_SUCCESS_MESSAGE",
              })
            );
          })
          .catch((err) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "MASTERDATA_CONTACT_TITLE_CREATE_FAILURE_MESSAGE",
                  })
                );
          })
          // .catch((e) => {
          //   closeContactTitlesModal();
          //   errorAlert(
          //     intl.formatMessage({
          //       id: "MASTERDATA_CONTACT_TITLE_CREATE_FAILURE_MESSAGE",
          //     })
          //   );
          // })
          .finally(() => {
            // recall  get contactTitles list API
            getContactTitlesListAPI();
            setLoading(false);
          });
      }
    } else {
      if (values.contactTitleName) {
        setLoading(true);
        updateContactTitle(contactTitle.id, values.contactTitleName)
          .then(() => {
            closeContactTitlesModal();
            SuccessAlert(
              intl.formatMessage({
                id: "MASTERDATA_CONTACT_TITLE_EDIT_SUCCESS_MESSAGE",
              }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((e) => {
            closeContactTitlesModal();
            errorAlert(
              intl.formatMessage({
                id: "MASTERDATA_CONTACT_TITLE_EDIT_FAILURE_MESSAGE",
              })
            );
          })
          .finally(() => {
            // recall  get contactTitles list API
            getContactTitlesListAPI();
            setLoading(false);
          });
      }
    }
  };

  const deleteExistingContactTitle = (id: number) => {
    setLoading(true);
    setDeleteModalLoading(true);
    deleteContactTitle(id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_CONTACT_TITLE_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_CONTACT_TITLE_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeContactTitlesModal();
        setCheckAllContactTitles(false);
        setCheckedContactTitles([]);
        // recall  get contactTitles list API
        getContactTitlesListAPI();
        setLoading(false);
      });
  };

  const onContactTitlesSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedContactTitlesArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedContactTitlesArr.push({
        id: sortedListItem.contactTitle.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing contactTitles API Call
    sortContactTitles(newSortedContactTitlesArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getContactTitlesListAPI();
      });
  };

  //
  const onContactTitleCheckChange = (contactTitle: GroupType) => {
    let checkedContactTitlesCopy = _.clone(checkedContactTitles);
    // check if already exists in the checked list
    const index = checkedContactTitlesCopy.findIndex(
      (checkedContactTitle) => checkedContactTitle.id === contactTitle.id
    );
    // if exists remove
    if (index > -1) {
      checkedContactTitlesCopy.splice(index, 1);
    }
    // if doesnt exist push to checked contactTitles
    else {
      checkedContactTitlesCopy.push(contactTitle);
    }
    setCheckedContactTitles(checkedContactTitlesCopy);
  };

  const isContactTitleChecked = (contactTitle: GroupType) => {
    const index = checkedContactTitles.findIndex(
      (checkedContactTitle) => checkedContactTitle.id === contactTitle.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMultipleContactTitles = () => {
    setDeleteModalLoading(true);
    const ids = checkedContactTitles.map((checkedContactTitle) => {
      return checkedContactTitle.id;
    });
    massDeleteContactTitles(ids)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_CONTACT_TITLE_MASS_DELETE_SUCCESS_MESSAGE",
          })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_CONTACT_TITLE_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getContactTitlesListAPI();
        setCheckedContactTitles([]);
      });
  };

  const onAllContactTitlesCheckChange = () => {
    const updatedCheckAllContactTitles = !checkAllContactTitles;
    setCheckAllContactTitles(updatedCheckAllContactTitles);
    if (updatedCheckAllContactTitles) {
      setCheckedContactTitles(contactTitlesList);
    } else {
      setCheckedContactTitles([]);
    }
  };

  const ContactTitleItem = (contactTitle: GroupType, index: number) => {
    const {
      showDeleteConfirmModal,
      hideDeleteConfirmModal,
      setDeleteModalLoading,
    } = useGlobalModalContext();
    return (
      <div
        className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
        key={index}
      >
        <div className="d-flex flex-row align-items-center">
          {crudPermission?.delete && <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={(e) => {
                onContactTitleCheckChange(contactTitle);
              }}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={isContactTitleChecked(contactTitle)}
            />
          </div>}
          <div className="mx-5">
            {/* <button className='btn btn-icon btn-light  btn-sm'> */}
            <SVGICON src={DragIcon} className="svg-icon-2 svg-hover-primary" />
            {/* </button> */}
          </div>

          <div
            className={`fw-bold mx-5 ${!crudPermission?.edit && "pe-none"}`}
            role="button"
            onClick={() => {
              setShowContactTitlesModal({
                show: true,
                type: "edit",
                contactTitle: contactTitle,
              });
            }}
          >
            {contactTitle.name}
          </div>
        </div>

        <div>
         {crudPermission?.edit &&  <button
            className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
            onClick={() => {
              setShowContactTitlesModal({
                show: true,
                type: "edit",
                contactTitle: contactTitle,
              });
            }}
          >
            <SVGICON src={EditIcon} className="svg-icon-3" />
          </button>}
         {crudPermission?.delete &&  <button
            className="btn btn-icon btn-light btn-active-light-primary btn-sm"
            onClick={() => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "MASTERDATA_CONTACT_TITLE_DELETE_CONFIRM_MESSAGE",
                }),
                () => {
                  deleteExistingContactTitle(contactTitle.id);
                }
              );
            }}
          >
            <SVGICON src={DeleteIcon} className="svg-icon-3" />
          </button>}
        </div>
      </div>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedContactTitles =
    displayContactTitlesList && displayContactTitlesList.length > 10
      ? displayContactTitlesList.slice(startIndex, startIndex + itemsPerPage)
      : displayContactTitlesList;

  useEffect(() => {
    if (
      paginatedContactTitles.length === 0 &&
      displayContactTitlesList.length > 0
    ) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedContactTitles]);

  // draggable contactTitles list
  const list = paginatedContactTitles
    ? paginatedContactTitles.map((contactTitle, i) => {
        return {
          content: (
            <div className={searchText ? "no-drag" : ""}>
              {ContactTitleItem(contactTitle, i)}
            </div>
          ),
          contactTitle: contactTitle,
        };
      })
    : [];

  return (
    <div>
      {/* @ts-ignore */}
      <ContactTitlesModal
        ModalProps={showContactTitlesModal}
        closeModal={closeContactTitlesModal}
        loading={loading}
        onFormSubmit={onFormSubmit}
      />

      {/* search contactTitles */}
      <div className="card p-4 d-flex flex-row justify-content-between mt-3">
        <div className="d-flex flex-row search-container mt-2">
         {crudPermission?.delete &&  <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={onAllContactTitlesCheckChange}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={checkAllContactTitles}
            />
          </div>}
          <div className="d-flex align-items-center">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15 mx-4"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_CONTACT_TITLE_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>

        {/* add new contactTitle button */}
        <div className="d-flex align-items-center mt-2">
          {checkedContactTitles.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedContactTitles.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "MASTERDATA_CONTACT_TITLE_SELECTED_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      deleteMultipleContactTitles();
                    }
                  );
                }}
              >
                <i className={"bi bi-trash3-fill fs-6"}></i>
                {intl.formatMessage({ id: "DELETE_BUTTON" })}
              </button>
            </>
          ) : (
            <>
            {crudPermission?.create && <button
              className="btn  btn-primary"
              onClick={() => {
                setShowContactTitlesModal({
                  show: true,
                  type: "new",
                });
              }}
            >
              <SVGICON src={PlusIcon} className="svg-icon-2" />
              {intl.formatMessage({
                id: "MASTERDATA_CONTACT_TITLE_CREATE_NEW_BUTTON",
              })}
            </button>}
            </>
          )}
        </div>
      </div>

      <DragSortableList
        items={list}
        // @ts-ignore
        onSort={(sortedList, dropEvent) => {
          onContactTitlesSortChange(sortedList);
        }}
        type="vertical"
      />
      {/* no data */}
      {!loading && displayContactTitlesList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <tr>
              <td valign="top" colSpan={7} className="dataTables_empty">
                <div className="d-flex flex-column flex-center">
                  <img
                    src={notFound}
                    className="mh-400px"
                    alt=""
                    style={{ color: "inherit" }}
                  />
                  <div className="fs-1 fw-bolder text-dark mb-4">
                    {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {displayContactTitlesList.length > 0 && (
        <div className="card mt-5 pe-3">
          <Pagination
            totalPages={Math.ceil(
              displayContactTitlesList.length / itemsPerPage
            )}
            activePage={
              Math.ceil(displayContactTitlesList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ContactTitles;
