import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// images
// import EditIcon from '../../../../_metronic/assets/icons/edit.svg'
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";

// custom
import { SVGICON } from "../../../../_metronic/helpers";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import { actions, deleteRole, getRolesList } from "../redux";
import { RoleType } from "../types/getRolesResponseType";
import EditRoleModal from "./EditRoleModal";
import AddNewRoleModal from "./AddNewRoleModal";
import { RootState } from "../../../../setup";
import { ResourceType } from "../types/getResourcesListResponseType";
import { useIntl } from "react-intl";

interface Props {}

interface EditModalProps {
  show: boolean;
  role: RoleType | undefined;
}

const UserRolesList: FC<Props> = () => {
  // selectors
  const allRoles = useSelector<RootState>(
    // @ts-ignore
    ({ rolesAndPermissionsManagement }) => rolesAndPermissionsManagement?.roles
  ) as RoleType[];

  const dispatch = useDispatch();
  const intl = useIntl();
  let RESOURCES_TYPES = JSON.parse(
    intl.formatMessage({ id: "RESOURCES_TYPES" })
  ) as ResourceType[];

  // state
  const [displayRolesList, setDisplayRolesList] = useState<RoleType[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [showEditUserRoleModal, setShowEditUserRoleModal] =
    useState<EditModalProps>({
      show: false,
      role: undefined,
    });
  const [showAddRoleModal, setShowAddRoleModal] = useState<boolean>(false);

  // context
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const updateDisplayRolesList = () => {
    const updatedRolesList = allRoles?.filter((role) => {
      if (
        (role.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayRolesList(updatedRolesList);
  };

  const getRolesListAPI = () => {
    getRolesList()
      .then(({ data: { rolesDetails = [] } }) => {
        dispatch(actions.setRolesList(rolesDetails));
      })
      .catch((error) => console.error(error?.response?.data?.message));
  };

  useEffect(() => {
    getRolesListAPI();
  }, []);

  useEffect(() => {
    updateDisplayRolesList();
  }, [allRoles, searchText]);

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const deleteExistingRole = (role: RoleType) => {
    setDeleteModalLoading(true);
    deleteRole(role.id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "ROLES_PERMISSIONS_ROLE_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "ROLES_PERMISSIONS_ROLE_DELETE_ERROR_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getRolesListAPI();
      });
  };

  const getFormattedPermissionsForRole = (role: RoleType) => {
    const resourcePermissions: string[] = [];
    if (role.id === 1) {
      RESOURCES_TYPES.forEach((resource) => {
        resourcePermissions.push(
          `${intl.formatMessage({
            id: "ROLES_AND_PERMISSIONS_ALL_CONTROLS_MESSAGE",
          })} ${intl.formatMessage({
            id: "ROLES_AND_PERMISSIONS_TO_MESSAGE",
          })} ${resource.name}`
        );
      });
    }

    const obj: any = {}; // initialize object
    role.resources?.forEach((resource) => {
      const resource_name = RESOURCES_TYPES.find(
        (obj) => obj?.slug === resource?.resource_slug
      )?.name;
      if (resource_name) {
        if (!obj[resource_name]) {
          obj[resource_name] = [];
        }
        obj[resource_name].push(resource.permission_id);
      }
    });

    // generate permission string for each resource
    Object.keys(obj).forEach((key) => {
      let permission_str = "";
      let counter = 0;
      const permissions_ids: number[] = obj[key] || [];
      // read permission
      if (permissions_ids.length === 4) {
        permission_str =
          permission_str +
          intl.formatMessage({
            id: "ROLES_AND_PERMISSIONS_ALL_CONTROLS_MESSAGE",
          });
      } else {
        if (permissions_ids.includes(1)) {
          counter++;
          permission_str =
            permission_str +
            intl.formatMessage({ id: "ROLES_AND_PERMISSIONS_VIEW_MESSAGE" });
        }
        if (permissions_ids.includes(2)) {
          counter++;
          permission_str =
            permission_str +
            `${
              counter === permissions_ids.length
                ? `${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_AND_MESSAGE",
                  })} ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_CREATE_MESSAGE",
                  })}`
                : `, ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_CREATE_MESSAGE",
                  })}`
            }`;
        }
        if (permissions_ids.includes(3)) {
          counter++;
          permission_str =
            permission_str +
            `${
              counter === permissions_ids.length
                ? ` ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_AND_MESSAGE",
                  })} ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_EDIT_MESSAGE",
                  })}`
                : `, ${intl.formatMessage({
                    id: "ROLES_AND_PERMISSIONS_EDIT_MESSAGE",
                  })}`
            }`;
        }
        if (permissions_ids.includes(4)) {
          permission_str =
            permission_str +
            ` ${intl.formatMessage({
              id: "ROLES_AND_PERMISSIONS_AND_MESSAGE",
            })} ${intl.formatMessage({
              id: "ROLES_AND_PERMISSIONS_DELETE_MESSAGE",
            })}`;
        }
      }

      permission_str = permission_str + ` to ${key}`;
      resourcePermissions.push(permission_str);
    });
    return resourcePermissions;
  };

  return (
    <div>
      {showEditUserRoleModal.show && (
        <EditRoleModal
          // @ts-ignore
          modalProps={showEditUserRoleModal}
          closeModal={() => {
            setShowEditUserRoleModal({
              show: false,
              role: undefined,
            });
          }}
          getRolesListAPI={getRolesListAPI}
        />
      )}

      {showAddRoleModal && (
        <AddNewRoleModal
          show={showAddRoleModal}
          closeModal={() => {
            setShowAddRoleModal(false);
          }}
          getRolesListAPI={getRolesListAPI}
        />
      )}

      <div className="row mt-1 mx-0">
        <div className="col-12">
          <div className="card p-4 d-flex flex-row justify-content-between mt-3">
            <div className="d-flex flex-row search-container">
              <div className="d-flex align-items-center">
                <SVGICON
                  src={SearchIcon}
                  className="svg-icon svg-icon-1 position-absolute ms-6"
                />
                <input
                  type="text"
                  className="form-control form-control-solid w-250px ps-15 mx-4"
                  placeholder={"Search role"}
                  onChange={(e) => {
                    onSearchTextChange(e.target.value);
                  }}
                />
              </div>
            </div>

            {/* add new role */}
            <button
              className="btn  btn-primary"
              onClick={() => {
                setShowAddRoleModal(true);
              }}
            >
              <SVGICON src={PlusIcon} className="svg-icon-2" />
              {intl.formatMessage({
                id: "ROLES_PERMISSIONS_ADD_NEW_ROLE_TITLE",
              })}
            </button>
          </div>
        </div>
        {displayRolesList?.map((role, i) => {
          // display only top 4 resources
          const displayResources = (
            getFormattedPermissionsForRole(role) || []
          ).slice(0, 4);
          const remainingResources =
            (getFormattedPermissionsForRole(role) || []).length -
            displayResources.length;
          // display only top 8 resources
          const displayUsers = (role.users || []).slice(0, 10);
          const remainingUsers =
            (role.users || []).length - displayUsers.length;
          return (
            <div className="col-md-6 mt-6" key={i}>
              <div className="card card-flush h-md-100">
                <div className="card-header d-flex">
                  <div className="card-title">
                    <h2>{role.name}</h2>
                  </div>
                  <div className="card-toolbar">
                    {/* super admin(role id :1) cannot be deleted */}
                    {role.id !== 1 && (
                      <button
                        className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
                        onClick={() => {
                          showDeleteConfirmModal(
                            intl.formatMessage({
                              id: "ROLES_PERMISSIONS_ROLE_DELETE_CONFIRMATION_MESSAGE",
                            }),
                            () => {
                              deleteExistingRole(role);
                            }
                          );
                        }}
                      >
                        <SVGICON src={DeleteIcon} className="svg-icon-3" />
                      </button>
                    )}
                  </div>
                </div>

                <div className="card-body pt-0">
                  <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">
                    {intl.formatMessage({
                      id: "ROLE_RESOURCE_PERMISSIONS_TITLE",
                    })}
                  </span>
                  {role.id !== 1 &&
                    (!role.resources || role.resources.length === 0) && (
                      <div className="fw-bold text-gray-600 mb-5">
                        {intl.formatMessage({
                          id: "ROLE_RESOURCE_PERMISSIONS_EMPTY_TITLE",
                        })}
                      </div>
                    )}
                  {displayResources.length > 0 && (
                    <div className="d-flex flex-column text-gray-600">
                      {/* @ts-ignore */}
                      {displayResources?.map((permission, j) => {
                        return (
                          <div
                            className="d-flex align-items-center py-3"
                            key={j}
                          >
                            <span className="bullet bg-primary me-3"></span>
                            <span>{permission}</span>
                          </div>
                        );
                      })}

                      {remainingResources > 0 && (
                        <div className="d-flex align-items-center mt-3">
                          <span className="bullet bg-primary me-3"></span>
                          <em>{`and ${remainingResources} more...`}</em>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="card-footer flex-wrap pt-0">
                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div>
                      {displayUsers && displayUsers.length > 0 && (
                        <div className="">
                          <div className="symbol-group symbol-hover flex-nowrap">
                            {displayUsers?.map((user, i) => {
                              return (
                                <div
                                  key={i}
                                  className="symbol symbol-35px symbol-circle"
                                  // data-bs-toggle='tooltip'
                                  // data-kt-initialized='1'
                                  // title={user.name}
                                  style={{ position: "relative" }}
                                  onMouseOver={() => {
                                    const tooltipEle = document.getElementById(
                                      `role-user-tooltip-${user.email}`
                                    );
                                    if (tooltipEle) {
                                      tooltipEle.style.display = "flex";
                                    }
                                  }}
                                  onMouseOut={() => {
                                    const tooltipEle = document.getElementById(
                                      `role-user-tooltip-${user.email}`
                                    );
                                    if (tooltipEle) {
                                      tooltipEle.style.display = "none";
                                    }
                                  }}
                                >
                                  <div
                                    id={`role-user-tooltip-${user.email}`}
                                    className="position-absolute bg-light px-3 py-2 fs-8"
                                    style={{
                                      display: "none",
                                      backgroundColor: "white",
                                      top: -60,
                                      borderRadius: 5,
                                    }}
                                  >
                                    {user.name}
                                  </div>
                                  {user.profile_picture_url ? (
                                    <img
                                      alt="Pic"
                                      src={user.profile_picture_url}
                                      style={{ objectFit: "cover" }}
                                    />
                                  ) : (
                                    <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                      {(user.name || " ")[0].toUpperCase()}
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                            {remainingUsers > 0 && (
                              <div className="symbol symbol-35px symbol-circle">
                                <span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
                                  +{remainingUsers}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <button
                      className="btn btn-light btn-active-light-primary my-2"
                      onClick={() => {
                        setShowEditUserRoleModal({
                          show: true,
                          role,
                        });
                      }}
                    >
                      {intl.formatMessage({
                        id: "ROLE_RESOURCE_PERMISSIONS_EDIT_ROLE",
                      })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* no data */}
        {displayRolesList?.length === 0 && (
          <table className="d-flex justify-content-center align-items-center mt-2">
            <tbody>
              <tr>
                <td valign="top" colSpan={7} className="dataTables_empty">
                  <div className="d-flex flex-column flex-center">
                    <img
                      src={notFound}
                      className="mh-400px"
                      alt=""
                      style={{ color: "inherit" }}
                    />
                    <div className="fs-1 fw-bolder text-dark mb-4">
                      {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserRolesList;
