import clsx from "clsx";
import { useFormik } from "formik";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import Constants from "../../../config/Constants";
// @ts-ignore
import DragSortableList from "react-drag-sortable";
import notFound from "../../../../_metronic/assets/icons/notFound.png";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { actions } from "../redux";
import {
  createNewLabel,
  deleteLabel,
  getLabelsList,
  massDeleteLabels,
  sortLabels,
  updateLabel,
} from "../redux/LabelsAPI";
import { GroupType } from "../types/GetGroupsListResponseType";
import { sortGroupsParams } from "../types/request";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface ModelComponentProps {
  ModalProps: LabelModalProps;
  closeModal: () => void;
  getLabelsListAPI: () => void;
}

interface LabelModalProps {
  show: boolean;
  type?: string;
  label?: GroupType;
}

interface SortedObject {
  label: GroupType;
}

const LabelsModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  getLabelsListAPI,
}) => {
  const intl = useIntl();
  const { show, type, label } = ModalProps;

  // state
  const [loading, setLoading] = useState(false);

  // formik
  const initialValues = {
    labelName: type === "edit" ? label?.name : "",
  };
  const AddNewLabelSchema = Yup.object().shape({
    labelName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({ id: "MASTERDATA_LABELS_FORM_NAME_REQUIRED" })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewLabelSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        if (values.labelName) {
          setLoading(true);
          createNewLabel(values.labelName)
            .then(() => {
              closeModal();
              SuccessAlert(
                intl.formatMessage({
                  id: "MASTERDATA_LABEL_CREATE_SUCCESS_MESSAGE",
                }),
                () => {},
                intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_LABEL_CREATE_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get labels list API
              getLabelsListAPI();
              setLoading(false);
            });
        }
      } else {
        if (values.labelName && label) {
          setLoading(true);
          updateLabel(label.id, values.labelName)
            .then(() => {
              closeModal();
              SuccessAlert(
                intl.formatMessage({
                  id: "MASTERDATA_LABEL_EDIT_SUCCESS_MESSAGE",
                }),
                () => {},
                intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_LABEL_EDIT_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get labels list API
              getLabelsListAPI();
              setLoading(false);
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      centered
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_EDIT_LABEL_TITLE" })
            : intl.formatMessage({ id: "MASTERDATA_CREATE_NEW_LABEL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_label_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_LABEL_NAME_FIELD_NAME",
                  })}
                </span>
              </label>
              <input
                {...formik.getFieldProps("labelName")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                name="labelName"
                autoComplete="off"
              />
              {formik.touched.labelName && formik.errors.labelName && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.labelName}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "MASTERDATA_LABEL_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const Labels: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const labelsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.labelsList
  ) as GroupType[];
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, "labels"))

  // state
  const [displayLabelsList, setDisplayLabelsList] = useState(labelsList);
  const [showLabelsModal, setShowLabelsModal] = useState<LabelModalProps>({
    show: false,
  });
  const [checkedLabels, setCheckedLabels] = useState<GroupType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllLabels, setCheckAllLabels] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const getLabelsListAPI = () => {
    getLabelsList()
      .then(({ data: { data } }) => {
        dispatch(actions.setLabelsList(data));
      })
      .catch((e) => {
        // console.log(`ERROR: getLabelsListAPI`, e)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateDisplayLabelsList = () => {
    const updatedLabelsList = labelsList.filter((label) => {
      if (
        (label.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayLabelsList(updatedLabelsList);
  };

  useEffect(() => {
    setIsLoading(true);
    // get labels API call
    getLabelsListAPI();
  }, []);

  useEffect(() => {
    updateDisplayLabelsList();

    return () => {
      setDisplayLabelsList(labelsList);
    };
  }, [labelsList, searchText]);

  useEffect(() => {
    if (checkAllLabels && checkedLabels.length !== labelsList.length) {
      setCheckAllLabels(false);
    }
    if (
      checkedLabels.length > 0 &&
      checkedLabels.length === labelsList.length
    ) {
      setCheckAllLabels(true);
    }
  }, [checkedLabels]);

  const closeLabelsModal = () => {
    setShowLabelsModal({
      show: false,
      type: "",
      label: undefined,
    });
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };
  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const deleteExistingLabel = (id: number) => {
    setDeleteModalLoading(true);
    deleteLabel(id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "MASTERDATA_LABEL_DELETE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "MASTERDATA_LABEL_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        closeLabelsModal();
        setCheckAllLabels(false);
        setCheckedLabels([]);
        // recall  get labels list API
        getLabelsListAPI();
      });
  };

  const onLabelsSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedLabelsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedLabelsArr.push({
        id: sortedListItem.label.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing labels API Call
    sortLabels(newSortedLabelsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getLabelsListAPI();
      });
  };

  //
  const onLabelCheckChange = (label: GroupType) => {
    let checkedLabelsCopy = _.clone(checkedLabels);
    // check if already exists in the checked list
    const index = checkedLabelsCopy.findIndex(
      (checkedLabel) => checkedLabel.id === label.id
    );
    // if exists remove
    if (index > -1) {
      checkedLabelsCopy.splice(index, 1);
    }
    // if doesnt exist push to checked labels
    else {
      checkedLabelsCopy.push(label);
    }
    setCheckedLabels(checkedLabelsCopy);
  };

  const isLabelChecked = (label: GroupType) => {
    const index = checkedLabels.findIndex(
      (checkedLabel) => checkedLabel.id === label.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMultipleLabels = () => {
    setDeleteModalLoading(true);
    const ids = checkedLabels.map((checkedLabel) => {
      return checkedLabel.id;
    });
    massDeleteLabels(ids)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_LABEL_MASS_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_LABEL_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getLabelsListAPI();
        setCheckedLabels([]);
      });
  };

  const onAllLabelsCheckChange = () => {
    const updatedCheckAllLabels = !checkAllLabels;
    setCheckAllLabels(updatedCheckAllLabels);
    if (updatedCheckAllLabels) {
      setCheckedLabels(labelsList);
    } else {
      setCheckedLabels([]);
    }
  };

  const LabelItem = (label: GroupType, index: number) => {
    const {
      showDeleteConfirmModal,
      hideDeleteConfirmModal,
      setDeleteModalLoading,
    } = useGlobalModalContext();
    return (
      <div
        className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
        key={index}
      >
        <div className="d-flex flex-row align-items-center">
          {crudPermission?.delete && <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={(e) => {
                onLabelCheckChange(label);
              }}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={isLabelChecked(label)}
            />
          </div>}
          <div className="mx-5">
            <SVGICON
              src={DragIcon}
              className="svg-icon-2 svg-icon-hover-primary"
            />
          </div>

          <div
            className={`fw-bold mx-5 ${!crudPermission?.edit && 'pe-none'}`}
            role="button"
            onClick={() => {
              setShowLabelsModal({
                show: true,
                type: "edit",
                label: label,
              });
            }}
          >
            {label.name}
          </div>
        </div>

        <div>
          {crudPermission?.edit && <button
            className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
            onClick={() => {
              setShowLabelsModal({
                show: true,
                type: "edit",
                label: label,
              });
            }}
          >
            <SVGICON src={EditIcon} className="svg-icon-3" />
          </button>}
          {crudPermission?.delete && <button
            className="btn btn-icon btn-light btn-active-light-primary btn-sm"
            onClick={() => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "MASERDATA_LABEL_DELETE_CONFIRM_MESSAGE",
                }),
                () => {
                  deleteExistingLabel(label.id);
                }
              );
            }}
          >
            <SVGICON src={DeleteIcon} className="svg-icon-3" />
          </button>}
        </div>
      </div>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedLabels =
    displayLabelsList && displayLabelsList.length > 10
      ? displayLabelsList.slice(startIndex, startIndex + itemsPerPage)
      : displayLabelsList;

  useEffect(() => {
    if (paginatedLabels.length === 0 && displayLabelsList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedLabels]);

  // draggable labels list
  const list = paginatedLabels
    ? paginatedLabels.map((label, i) => {
        return {
          content: (
            <div className={searchText ? "no-drag" : ""}>
              {LabelItem(label, i)}
            </div>
          ),
          label: label,
        };
      })
    : [];

  return (
    <div>
      {/* @ts-ignore */}
      {showLabelsModal.show && (
        <LabelsModal
          ModalProps={showLabelsModal}
          closeModal={closeLabelsModal}
          getLabelsListAPI={getLabelsListAPI}
        />
      )}

      {/* search labels */}
      <div className="card p-4 d-flex flex-row justify-content-between mt-3">
        <div className="d-flex flex-row search-container mt-2">
         {crudPermission?.delete &&  <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={onAllLabelsCheckChange}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={checkAllLabels}
            />
          </div>}
          <div className="d-flex align-items-center">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15 mx-4"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_LABEL_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>

        {/* add new label button */}
        <div className="d-flex align-items-center mt-2">
          {checkedLabels.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedLabels.length} {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "MASTERDATA_LABEL_SELECTED_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      deleteMultipleLabels();
                    }
                  );
                }}
              >
                <i className={"bi bi-trash3-fill fs-6"}></i>
                {intl.formatMessage({ id: "DELETE_BUTTON" })}
              </button>
            </>
          ) : (
            <>
            {crudPermission?.create && <button
              className="btn  btn-primary"
              onClick={() => {
                setShowLabelsModal({
                  show: true,
                  type: "new",
                });
              }}
            >
              <SVGICON src={PlusIcon} className="svg-icon-2" />
              {intl.formatMessage({ id: "MASTERDATA_CREATE_NEW_LABEL_BUTTON" })}
            </button>}
            </>
          )}
        </div>
      </div>

      <DragSortableList
        items={list}
        // @ts-ignore
        onSort={(sortedList, dropEvent) => {
          onLabelsSortChange(sortedList);
        }}
        type="vertical"
      />
      {/* no data */}
      {!isLoading && displayLabelsList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <tr>
              <td valign="top" colSpan={7} className="dataTables_empty">
                <div className="d-flex flex-column flex-center">
                  <img
                    src={notFound}
                    className="mh-400px"
                    alt=""
                    style={{ color: "inherit" }}
                  />
                  <div className="fs-1 fw-bolder text-dark mb-4">
                    {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {displayLabelsList.length > 0 && (
        <div className="card mt-5 pe-3">
          <Pagination
            totalPages={Math.ceil(displayLabelsList.length / itemsPerPage)}
            activePage={
              Math.ceil(displayLabelsList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Labels;
