import clsx from "clsx";
import { useFormik } from "formik";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
// @ts-ignore
import DragSortableList from "react-drag-sortable";
import notFound from "../../../../_metronic/assets/icons/notFound.png";

// images
import DeleteIcon from "../../../../_metronic/assets/icons/delete.svg";
import DragIcon from "../../../../_metronic/assets/icons/drag.svg";
import EditIcon from "../../../../_metronic/assets/icons/edit.svg";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
import Constants from "../../../config/Constants";
import { errorAlert, SuccessAlert } from "../../../sharedComponents/Alert";
// import Pagination from '../../../sharedComponents/pagination/Pagination'
import { FileType } from "../../filemanager/types/getFilemanagerDataResponseType";
import { actions } from "../redux";
import {
  createNewTag,
  deleteTag,
  getTagsList,
  massDeleteTags,
  sortTags,
  updateTag,
} from "../redux/TagsAPI";
import { GroupType } from "../types/GetGroupsListResponseType";
import { sortGroupsParams } from "../types/request";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { useGlobalModalContext } from "../../../sharedComponents/modals/ModalContext";
import ColorPickerInput from "../../../sharedComponents/colorPickerInput/ColorPickerInput";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

interface ModelComponentProps {
  ModalProps: TagModalProps;
  closeModal: () => void;
  getTagsListAPI: () => void;
}

export interface TagModalProps {
  show: boolean;
  type?: string;
  tag?: GroupType;
}

interface SortedObject {
  tag: GroupType;
}

export const TagsModal: FC<ModelComponentProps> = ({
  ModalProps,
  closeModal,
  getTagsListAPI,
}) => {
  const { show, type, tag } = ModalProps;
  const intl = useIntl();
  const [seoPictureFile, setSeoPictureFile] = useState<FileType | null>();
  const [showFileSelectModal, setShowFileSelectModal] =
    useState<boolean>(false);
  //@ts-ignore
  const [currPage, setCurrPage] = useState<GroupType | undefined>(tag);
  const AddNewTagSchema = Yup.object().shape({
    tagName: Yup.string()
      .max(60, intl.formatMessage({ id: "MASTERDATA_60SYMBOLS_REQUIRED" }))
      .required(
        intl.formatMessage({ id: "MASTERDATA_TAGS_TAG_NAME_REQUIRED" })
      ),
    color: Yup.string()
      .matches(
        /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        intl.formatMessage({ id: "MASTERDATA_TAGS_TAG_COLOR_INVALID" })
      )
      .required(
        intl.formatMessage({ id: "MASTERDATA_TAGS_TAG_COLOR_REQUIRED" })
      ),
  });
  // state
  const [loading, setLoading] = useState(false);

  const initialValues = {
    tagName: type === "edit" ? currPage?.name : "",
    color: type === "edit" ? currPage?.colour : "",
    // seo_title: type === "edit" ? tag?.seo_title : "",
    // seo_description: type === "edit" ? tag?.seo_description : "",
    // is_visible: type === "edit" ? tag?.is_visibility : "",
  };

  useEffect(() => {
    if (type === "new") {
      setCurrPage(undefined);
    }
  }, [type, tag]);

  useEffect(() => {
    setCurrPage(tag);
  }, [tag]);

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewTagSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new") {
        if (values.tagName) {
          setLoading(true);
          createNewTag(
            values.tagName,
            values.color ? values.color : ""
            //@ts-ignore
            // values.seo_title,
            // values.seo_description,
            // updatedPictureId,
            // values.is_visible ? 1 : 0
          )
            .then(() => {
              closeModal();
              SuccessAlert(
                intl.formatMessage({
                  id: "MASTERDATA_TAGS_CREATE_SUCCESS_MESSAGE",
                }),
                () => {},
                intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_TAGS_CREATE_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get tags list API
              getTagsListAPI();
              setSeoPictureFile(null);
              setLoading(false);
            });
        }
      } else {
        if (values.tagName && tag) {
          setLoading(true);
          const updatedPictureId = seoPictureFile?.id
            ? seoPictureFile?.id
            : currPage?.seo_picture_id
              ? currPage?.seo_picture_id
              : null;
          updateTag(
            tag.id,
            values.tagName,
            values.color || ""
            //@ts-ignore
            // values.seo_title,
            // values.seo_description,
            // updatedPictureId,
            // values.is_visible ? 1 : 0
          )
            .then(() => {
              closeModal();
              SuccessAlert(
                intl.formatMessage({
                  id: "MASTERDATA_TAGS_EDIT_SUCCESS_MESSAGE",
                }),
                () => {},
                intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
              );
            })
            .catch((err) => {
              err.response?.data?.errors?.name
                ? setStatus(err.response.data?.errors?.name)
                : setStatus(
                    intl.formatMessage({
                      id: "MASTERDATA_TAGS_EDIT_FAILURE_MESSAGE",
                    })
                  );
            })
            .finally(() => {
              // recall  get tags list API
              getTagsListAPI();
              setSeoPictureFile(null);
              setLoading(false);
            });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      centered
      contentClassName={loading ? "pe-none" : ""}
      dialogClassName={"medium-size-modal"}
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_TAGS_EDIT_TITLE" })
            : intl.formatMessage({ id: "MASTERDATA_TAGS_EDIT_NEW_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      {/* {showFileSelectModal && (
        <SelectFileModal
          show={showFileSelectModal}
          closeModal={closeMainFileModal}
          onSelectFile={onFileSelect}
          allowedMimetypes={Constants.mimeTypes.image}
          isSelectionModal={true}
        />
      )} */}
      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="p-4">
            {formik.status && (
              <div className="mb-10 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_TAGS_TAG_NAME_FIELD_NAME",
                  })}
                </span>
              </label>

              <input
                placeholder={intl.formatMessage({
                  id: "MASTERDATA_TAGS_TAG_NAME_PLACEHOLDER",
                })}
                {...formik.getFieldProps("tagName")}
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                name="tagName"
                autoComplete="off"
              />
              {formik.touched.tagName && formik.errors.tagName && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.tagName}</span>
                </div>
              )}
            </div>
            <div className="">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2 mt-2">
                <span className="required">
                  {intl.formatMessage({ id: "TAGS_COLOR_NAME" })}
                </span>
              </label>
              <ColorPickerInput
                formikProps={formik.getFieldProps("color")}
                onColorChange={(color) => {
                  formik.setFieldValue("color", color);
                }}
              />
              {formik.touched.color && formik.errors.color && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.color}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              setSeoPictureFile(null);
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "MASTERDATA_TAGS_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const Tags: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const tagsList = useSelector<RootState>(
    ({ masterdata }) => masterdata.tagsList
  ) as GroupType[];
  const {
    showDeleteConfirmModal,
    hideDeleteConfirmModal,
    setDeleteModalLoading,
  } = useGlobalModalContext();

  const [displayTagsList, setDisplayTagsList] = useState(tagsList);
  const [showTagsModal, setShowTagsModal] = useState<TagModalProps>({
    show: false,
  });
  const [checkedTags, setCheckedTags] = useState<GroupType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [checkAllTags, setCheckAllTags] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );

  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, 'tags'))

  const getTagsListAPI = () => {
    getTagsList()
      .then(({ data: { data } }) => {
        dispatch(actions.setTagsList(data));
      })
      .catch((e) => {
        // console.log(`ERROR: getTagsListAPI`, e)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateDisplayTagsList = () => {
    const updatedTagsList = tagsList.filter((tag) => {
      if (
        (tag.name || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      ) {
        return true;
      }
      return false;
    });
    // save updatedlist to display
    setDisplayTagsList(updatedTagsList);
  };

  useEffect(() => {
    setIsLoading(true);
    // get tags API call
    getTagsListAPI();
  }, []);

  useEffect(() => {
    updateDisplayTagsList();

    return () => {
      setDisplayTagsList(tagsList);
    };
  }, [tagsList, searchText]);

  useEffect(() => {
    if (checkAllTags && checkedTags.length !== tagsList.length) {
      setCheckAllTags(false);
    }
    if (checkedTags.length > 0 && checkedTags.length === tagsList.length) {
      setCheckAllTags(true);
    }
  }, [checkedTags]);

  const closeTagsModal = () => {
    setShowTagsModal({
      show: false,
      type: "new",
      tag: undefined,
    });
  };

  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  const onSearchTextChange = (text: string) => {
    setSearchText((text || "").trim());
  };

  const deleteExistingTag = (id: number) => {
    setDeleteModalLoading(true);
    deleteTag(id)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({ id: "MASTERDATA_TAGS_DELETE_SUCCESS_MESSAGE" }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({ id: "MASTERDATA_TAGS_DELETE_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        // recall  get tags list API
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        setCheckAllTags(false);
        setCheckedTags([]);
        getTagsListAPI();
      });
  };

  const onTagsSortChange = (sortedList: Array<SortedObject>) => {
    const newSortedTagsArr: sortGroupsParams = [];
    sortedList.forEach((sortedListItem, i) => {
      newSortedTagsArr.push({
        id: sortedListItem.tag.id,
        newposition: startIndex + i + 1,
      });
    });
    // sort existing tags API Call
    sortTags(newSortedTagsArr)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        getTagsListAPI();
      });
  };

  //
  const onTagCheckChange = (tag: GroupType) => {
    let checkedTagsCopy = _.clone(checkedTags);
    // check if already exists in the checked list
    const index = checkedTagsCopy.findIndex(
      (checkedTag) => checkedTag.id === tag.id
    );
    // if exists remove
    if (index > -1) {
      checkedTagsCopy.splice(index, 1);
    }
    // if doesnt exist push to checked tags
    else {
      checkedTagsCopy.push(tag);
    }
    setCheckedTags(checkedTagsCopy);
  };

  const isTagChecked = (tag: GroupType) => {
    const index = checkedTags.findIndex(
      (checkedTag) => checkedTag.id === tag.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  const deleteMultipleTags = () => {
    setDeleteModalLoading(true);
    const ids = checkedTags.map((checkedTag) => {
      return checkedTag.id;
    });
    massDeleteTags(ids)
      .then(() => {
        SuccessAlert(
          intl.formatMessage({
            id: "MASTERDATA_TAGS_MASS_DELETE_SUCCESS_MESSAGE",
          }),
          () => {},
          intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
        );
      })
      .catch(() => {
        errorAlert(
          intl.formatMessage({
            id: "MASTERDATA_TAGS_MASS_DELETE_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setDeleteModalLoading(false);
        hideDeleteConfirmModal();
        getTagsListAPI();
        setCheckedTags([]);
      });
  };

  const onAllTagsCheckChange = () => {
    const updatedCheckAllTags = !checkAllTags;
    setCheckAllTags(updatedCheckAllTags);
    if (updatedCheckAllTags) {
      setCheckedTags(tagsList);
    } else {
      setCheckedTags([]);
    }
  };

  const TagItem = (tag: GroupType, index: number) => {
    const {
      showDeleteConfirmModal,
      hideDeleteConfirmModal,
      setDeleteModalLoading,
    } = useGlobalModalContext();
    return (
      <div
        className="card mt-5 p-4 d-flex flex-row align-items-center justify-content-between"
        key={index}
      >
        <div className="d-flex flex-row align-items-center">
          {crudPermission?.delete && <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={(e) => {
                onTagCheckChange(tag);
              }}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={isTagChecked(tag)}
            />
          </div>}
          <div className="me-6 ms-3">
            <SVGICON src={DragIcon} className="svg-icon-2 svg-hover-primary" />
          </div>
          <div
            className=""
            style={{
              backgroundColor: tag.colour || "transparent",
              height: "16px",
              width: "16px",
              borderRadius: "25px",
            }}
          ></div>

          <div
            className={`fw-bold mx-3 ${!crudPermission?.edit && 'pe-none'}`}
            role="button"
            style={{
              maxWidth: "150px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            onClick={() => {
              setShowTagsModal({
                show: true,
                type: "edit",
                tag: tag,
              });
            }}
          >
            {tag.name}
          </div>
        </div>

        <div>
          {crudPermission?.edit && <button
            className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1"
            onClick={() => {
              setShowTagsModal({
                show: true,
                type: "edit",
                tag: tag,
              });
            }}
          >
            <SVGICON src={EditIcon} className="svg-icon-3" />
          </button>}
          {crudPermission?.delete && <button
            className="btn btn-icon btn-light btn-active-light-primary btn-sm"
            onClick={() => {
              showDeleteConfirmModal(
                intl.formatMessage({
                  id: "MASTERDATA_TAGS_DELETE_CONFIRM_MESSAGE",
                }),
                () => {
                  deleteExistingTag(tag.id);
                }
              );
            }}
          >
            <SVGICON src={DeleteIcon} className="svg-icon-3" />
          </button>}
        </div>
      </div>
    );
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedTags =
    displayTagsList && displayTagsList.length > 10
      ? displayTagsList.slice(startIndex, startIndex + itemsPerPage)
      : displayTagsList;

  useEffect(() => {
    if (paginatedTags.length === 0 && displayTagsList.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedTags]);

  // draggable tags list
  const list = paginatedTags
    ? paginatedTags.map((tag, i) => {
        return {
          content: (
            <div className={searchText ? "no-drag" : ""}>{TagItem(tag, i)}</div>
          ),
          tag: tag,
        };
      })
    : [];

  return (
    <div>
      {showTagsModal.show && (
        <TagsModal
          ModalProps={showTagsModal}
          closeModal={closeTagsModal}
          getTagsListAPI={getTagsListAPI}
        />
      )}

      {/* search tags */}
      <div className="card p-4 d-flex flex-row justify-content-between mt-3">
        <div className="d-flex flex-row mt-2">
         {crudPermission?.delete &&  <div className="form-check form-check-sm form-check-custom form-check-solid ">
            <input
              onChange={onAllTagsCheckChange}
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={checkAllTags}
            />
          </div>}
          <div className="d-flex align-items-center">
            <SVGICON
              src={SearchIcon}
              className="svg-icon svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              className="form-control form-control-solid w-250px ps-15 mx-4"
              placeholder={intl.formatMessage({
                id: "MASTERDATA_TAGS_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        </div>

        {/* add new tag button */}
        <div className="d-flex align-items-center mt-2">
          {checkedTags.length > 0 ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedTags.length} {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => {
                  showDeleteConfirmModal(
                    intl.formatMessage({
                      id: "MASTERDATA_TAGS_SELECTED_DELETE_CONFIRM_MESSAGE",
                    }),
                    () => {
                      deleteMultipleTags();
                    }
                  );
                }}
              >
                <i className={"bi bi-trash3-fill fs-6"}></i>
                {intl.formatMessage({ id: "DELETE_BUTTON" })}
              </button>
            </>
          ) : (
           <>
           {crudPermission?.create &&  <button
              className="btn  btn-primary"
              onClick={() => {
                setShowTagsModal({
                  show: true,
                  type: "new",
                });
              }}
            >
              <SVGICON src={PlusIcon} className="svg-icon-2" />
              {intl.formatMessage({ id: "MASTERDATA_TAGS_CREATE_NEW_BUTTON" })}
            </button>}
           </>
          )}
        </div>
      </div>

      <DragSortableList
        items={list}
        // @ts-ignore
        onSort={(sortedList, dropEvent) => {
          onTagsSortChange(sortedList);
        }}
        type="vertical"
      />
      {/* no data */}
      {!isLoading && displayTagsList.length === 0 && (
        <table className="d-flex justify-content-center align-items-center mt-2">
          <tbody>
            <tr>
              <td valign="top" colSpan={7} className="dataTables_empty">
                <div className="d-flex flex-column flex-center">
                  <img
                    src={notFound}
                    className="mh-400px"
                    alt=""
                    style={{ color: "inherit" }}
                  />
                  <div className="fs-1 fw-bolder text-dark mb-4">
                    {intl.formatMessage({ id: "NO_ITEMS_FOUND" })}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {displayTagsList.length > 0 && (
        <div className="card mt-5 pe-3">
          <Pagination
            totalPages={Math.ceil(displayTagsList.length / itemsPerPage)}
            activePage={
              Math.ceil(displayTagsList.length / itemsPerPage) === 1
                ? 1
                : activePage
            }
            onPageClick={onPageClick}
            noCard
            itemsPerPage={itemsPerPage}
            showItemsPerPage
            setItemsPerPage={(count) => {
              setItemsPerPage(count);
              setActivePage(1);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Tags;
