/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import clsx from "clsx";

import { useLang } from "../../../../_metronic/i18n/Metronici18n";

// css
import "../Tasks.scss";

import { SVGICON } from "../../../../_metronic/helpers";
import CallIcon from "../../../../_metronic/assets/icons/telephone.svg";
import EmailIcon from "../../../../_metronic/assets/icons/envelope.svg";
import OthersIcon from "../../../../_metronic/assets/icons/clipboard.svg";

import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import {
  successToast,
  warningToast,
} from "../../../sharedComponents/toasts/Toasts";

import TasksListActionsDropdown from "./TasksListActionDropDown";

import { updateTaskStatus } from "../redux";
import { Assignee, TaskType } from "../types/TasksResponseType";
import { UserSymbol, MoreSymbol } from "./UserSymbol";
import TasksLogsModal from "./TasksLogsModal";
import { RootState } from "../../../../setup";
import { useSelector } from "react-redux";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";

interface AllUsersModalType {
  show: boolean;
  users: undefined | Assignee[];
}

interface Props {
  task: TaskType;
  setShowAllUsersModal: (props: AllUsersModalType) => void;
  getTasksListAPI: () => void;
  setShowAddTaskModal: (props: any) => void;
}

// Task Row Component
const TasksListItem: FC<Props> = ({
  task,
  setShowAllUsersModal,
  getTasksListAPI,
  setShowAddTaskModal,
}) => {
  const local = useLang();
  const intl = useIntl();

  //STATE
  const [showTaskLogsModal, setShowTaskLogsModal] = useState<boolean>(false);

  const isChecked = (task: TaskType) => (task.status === "done" ? true : false);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;

  const onCheckedChange = (task: TaskType) => {
    const newStatus = task?.status === "to-do" ? "done" : "to-do";
    updateTaskStatus(task.id, newStatus)
      .then(() => {
        newStatus === "done"
          ? successToast(
              intl.formatMessage({
                id: "TASKS_UPDATE_STATUS_SUCCESS_MESSAGE_DONE",
              })
            )
          : warningToast(
              intl.formatMessage({
                id: "TASKS_UPDATE_STATUS_FAILURE_MESSAGE_TODO",
              })
            );
      })
      .catch(() => {
        warningToast(
          intl.formatMessage({ id: "TASKS_UPDATE_STATUS_FAILURE_MESSAGE" })
        );
      })
      .finally(() => {
        getTasksListAPI();
      });
  };

  const isDateOverdue = (dateTimeString: string) => {
    return new Date(dateTimeString) < new Date();
  };

  const getTextColor = (status: string) => {
    if (status === "done") {
      return "text-muted text-decoration-line-through";
    } else if (isDateOverdue(task.due_date)) {
      return "text-danger";
    } else if (status === "to-do") {
      return "text-dark";
    }
  };

  const isTaskResponsible = task?.assignees.map((assignee:any)=> assignee?.id === userRoleDetails?.id)
  const isTaskCreator = task?.created_user_details?.createdUserid === userRoleDetails?.id

  const displayAssignees = (task?.assignees || []).slice(0, 4);
  const remainingAssignees =
    (task?.assignees || []).length - displayAssignees.length;
  return (
    <>
      {showTaskLogsModal && (
        <TasksLogsModal
          task={task}
          show={showTaskLogsModal}
          closeModal={() => {
            setShowTaskLogsModal(false);
          }}
        />
      )}
      <tr>
        {/* checkbox */}
        {(isTaskCreator || isTaskResponsible) ? <td className="py-0">
          <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
            <Checkbox
              onChange={() => {
                onCheckedChange(task);
              }}
              checked={isChecked(task)}
              disabled={false}
            />
          </div>
        </td>: <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
            {/* <Checkbox
              onChange={() => {
                onCheckedChange(task);
              }}
              checked={isChecked(task)}
              disabled={false}
            /> */}
          </div> }
        {/* Due Date */}
        <td className="py-0">
          {task?.due_date && (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {local === "de"
                ? moment(task?.due_date).format("DD.MM.YYYY HH:mm")
                : moment(task?.due_date).format("YYYY-MM-DD h:mm A")}
            </span>
          )}
        </td>
        {/* Task Name */}
        <td className="py-0">
          {task?.title && (
            <div className="d-flex align-items-center">
              <SVGICON
                src={
                  task?.type === "call"
                    ? CallIcon
                    : task?.type === "email"
                      ? EmailIcon
                      : task?.type === "other"
                        ? OthersIcon
                        : null
                }
                className="svg-icon-5 me-2"
              />
              <span
                className={clsx("fs-6 fw-bold", getTextColor(task?.status))}
              >
                {task?.title}
              </span>
            </div>
          )}
        </td>
        {/* Deal Name */}
        <td className="py-0">
          <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
            {task?.deal?.name || "-"}
          </span>
        </td>
        {/* Deal Contact/Organisation Name  */}
        <td className="py-0">
          {task?.deal?.contacts ? (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal?.contacts?.firstname ||
              task?.deal?.contacts?.lastname ? (
                <>
                  {task?.deal?.contacts?.firstname}{" "}
                  {task?.deal?.contacts?.lastname}
                </>
              ) : (
                "-"
              )}
            </span>
          ) : task?.deal?.partners ? (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal?.partners?.name || "-"}
            </span>
          ) : (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              -
            </span>
          )}
        </td>
        {/* Deal Contact/Organisation Phone */}
        <td className="py-0">
          {task?.deal?.contacts ? (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal?.contacts?.telephone || "-"}
            </span>
          ) : task?.deal?.partners ? (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              {task?.deal?.partners?.telephone || "-"}
            </span>
          ) : (
            <span className={clsx("fs-6 fw-bold", getTextColor(task?.status))}>
              -
            </span>
          )}
        </td>
        {/* Responsible Persons */}
        <td className="py-0">
          <div className="symbol-group symbol-hover flex-nowrap">
            {task?.assignees &&
              task?.assignees?.length > 0 &&
              task?.assignees?.slice(0, 4).map((data, i) => (
                <React.Fragment key={i}>
                  <UserSymbol data={data} />
                </React.Fragment>
              ))}
            {remainingAssignees > 0 && (
              <MoreSymbol
                remainingUsers={remainingAssignees}
                onClick={() => {
                  setShowAllUsersModal({
                    show: true,
                    users: task.assignees,
                  });
                }}
              />
            )}
          </div>
        </td>
        {/* actions */}
        <td className="py-2">
          <TasksListActionsDropdown
            taskDetails={task}
            getTasksListAPI={getTasksListAPI}
            setShowAddTaskModal={() => {
              setShowAddTaskModal((prev: any) => ({
                ...prev,
                show: true,
                action: "edit",
                taskDetails: task,
              }));
            }}
            setShowTaskLogsModal={() => setShowTaskLogsModal(true)}
          />
        </td>
      </tr>
    </>
  );
};

export default TasksListItem;
