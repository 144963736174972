import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG } from "../../../helpers";
import { useLayout } from "../../core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../setup";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  index?: number;
  className?: string;
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  index,
  className,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;
  const id = localStorage.getItem("pId");

  return (
    <div className={clsx("menu-item", className)} key={index}>
      <Link
        className={clsx("menu-link without-sub", {
          active: index ? isActive && Number(id) === index : isActive,
        })}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === "svg" && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2 " />
          </span>
        )}
        {fontIcon && aside.menuIcon === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span
          className="menu-title d-inline-block text-truncate"
          style={{ maxWidth: "165px" }}
        >
          {title}
        </span>
      </Link>
      {children}
    </div>
  );
};

export { AsideMenuItem };
