import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// custom
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import config from "../../config";
import Fields from "./components/Fields";
import ProjectTabs from "./components/ProjectTabs";
import EditTabs from "./components/EditTabs";

const ProjectsMasterData: React.FC = () => {
  const intl = useIntl();

  const masterdataBreadCrumbs: Array<PageLink> = [
    // {
    //   title: intl.formatMessage({ id: "MENU_DASHBOARD" }),
    //   path: "/dashboard",
    //   isSeparator: false,
    //   isActive: false,
    // },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: true,
    //   isActive: false,
    // },
    {
      title: intl.formatMessage({ id: "MENU_MASTERDATA" }),
      path: "/projects/master-data",
      isSeparator: false,
      isActive: false,
    },
    // {
    //   title: "",
    //   path: "",
    //   isSeparator: false,
    //   isActive: false,
    // },
  ];

  return (
    <div>
      <Helmet>
        <title>
          {config.APP_NAME}-{intl.formatMessage({ id: "MENU_MASTERDATA" })}
        </title>
      </Helmet>
      {/* <AccountHeader /> */}
      <Switch>
        <Route path="/projects/master-data/fields">
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MENU_FIELDS" })}
          </PageTitle>
          <Fields />
        </Route>

        <Route path="/projects/master-data/tabs" exact>
          <PageTitle breadcrumbs={masterdataBreadCrumbs}>
            {intl.formatMessage({ id: "MENU_TABS" })}
          </PageTitle>
          <ProjectTabs />
        </Route>

        <Route path="/projects/master-data/tabs/editTab" exact>
          <PageTitle
            breadcrumbs={[
              ...masterdataBreadCrumbs,
              {
                title: "",
                path: "",
                isSeparator: true,
                isActive: false,
              },
              {
                title: intl.formatMessage({
                  id: "MENU_TABS",
                }),
                path: "/projects/master-data/tabs",
                isSeparator: false,
                isActive: false,
              },
              {
                title: "",
                path: "",
                isSeparator: true,
                isActive: false,
              },
              {
                title: intl.formatMessage({
                  id: "MENU_EDIT_TABS",
                }),
                path: "/projects/master-data/tabs/editTab",
                isSeparator: false,
                isActive: true,
              },
            ]}
          >
            {intl.formatMessage({ id: "MENU_EDIT_TABS" })}
          </PageTitle>
          <EditTabs />
        </Route>

        <Redirect
          from="/master-data"
          exact={true}
          to="/projects/master-data/fields"
        />
        <Redirect to="/projects/master-data/fields" />
      </Switch>
    </div>
  );
};

export default ProjectsMasterData;
